import React, { useEffect, useState } from "react";
import { getUserDataAPI } from "../../service/UserService";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { APP_NAME, PERMISSION_MAP, PLATFORM } from "../../utils/constants";

export default function Sidebar() {
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const userData = store.getState().account.userData;
  const _isMounted = React.useRef(true);
  const getAccountDetails = async () => {
    const { response } = await getUserDataAPI(
      store.getState().account.userData.id
    );
    dispatch({
      type: "accountData/setUserProfile",
      payload: response,
    });
  };
  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (_isMounted.current) {
      setUserName(store.getState().account.userData.username);
      const interval = setInterval(() => {
        getAccountDetails();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
  useEffect(() => {
    function handleResize() {
      setIsMobileUI(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [currentActive, setCurrentActive] = useState(null);
  const [navs, setNavs] = useState([
    {
      name: "ADMIN DETAILS",
      isGroupTitle: true,
    },
    {
      url: "/admin",
      name: "Admin",
      icon: "nav-icon fas fa-address-card",
      enum: "ADMIN",
      isPermissionEnabled: true,
    },
    {
      url: "/sub_admin",
      name: "Sub Admin",
      icon: "nav-icon fas fa-address-card",
      enum: "SUB_ADMIN",
      isPermissionEnabled: true,
    },
    {
      url: "/master",
      name: "MasterAgent Master",
      icon: "nav-icon fas fa-address-card",
      enum: "MASTER",
      isPermissionEnabled: true,
    },
    {
      url: "/super_agent",
      name: "SuperAgent Master",
      icon: "nav-icon fas fa-address-card",
      enum: "SUPER_AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/agent",
      name: "Agent Master",
      icon: "nav-icon fas fa-address-card",
      enum: "AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/client",
      name: "Client Master",
      icon: "nav-icon fas fa-address-card",
      enum: "CLIENT",
      isPermissionEnabled: true,
    },

    {
      name: "GAMES",
      isGroupTitle: true,
    },

    {
      url: "/game/inPlay",
      name: "InPlay Game",
      icon: "nav-icon fas fa-play",
    },
    {
      url: "/game/completeGame",
      name: "Complete Game",
      icon: "nav-icon far fa-stop-circle",
    },

    {
      name: "CASINO",
      isGroupTitle: true,
    },

    {
      name: "Live Casino Position",
      url: "#",
      icon: "nav-icon fas fa-chart-line",
      subNavs: [
        {
          url: "/casino/casino-position/TEEN_20",
          name: " Live Teen20 Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/DRAGON_TIGER_20",
          name: "Live DragonTiger Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/LUCKY7",
          name: " Live Lucky7 Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/ABJ",
          name: " Live Andar Bahar Position",
          icon: "nav-icon fas fa-chart-bar",
        },
      ],
    },

    {
      url: "/casino/casino-details",
      name: "Casino Details",
      icon: "nav-icon fas fa-wallet",
    },
    {
      name: "MATKA",
      isGroupTitle: true,
      platformSpecific: "2exch",
    },

    {
      name: "Matka Inplay",
      url: "/matka/inplay",
      icon: "nav-icon fa fa-play",
      platformSpecific: "2exch",
    },
    {
      name: "Matka Complete",
      url: "/matka/complete",
      icon: "nav-icon fa fa-stop-circle",
      platformSpecific: "2exch",
    },
    {
      name: "LEDGER",
      isGroupTitle: true,
    },
    {
      url: "/ledger",
      name: "My Ledger",
      icon: "nav-icon fas fa-angle-right",
    },
    {
      url: `/ledger/${userData?.username}`,
      name: "Lena Dena",
      icon: "nav-icon fas fa-angle-right",
      state: { isLenaDena: true },
      isPermissionEnabled: false,
    },
    userData?.userType == "AGENT" && {
      url: `/agent/commission_lena_dena_report`,
      name: "Comm Lena Dena",
      icon: "nav-icon fas fa-angle-right",
    },

    {
      name: "CASH TRANSACTION",
      isGroupTitle: true,
    },

    {
      url: "/ct/client",
      name: "Debit/Credit Entry (C)",
      icon: "nav-icon fas fa-angle-right",
      enum: "CLIENT",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/agent",
      name: "Debit/Credit Entry (A)",
      icon: "nav-icon fas fa-angle-right",
      enum: "AGENT",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/super_agent",
      name: "Debit/Credit Entry (SA)",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUPER_AGENT",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/master",
      name: "Debit/Credit Entry (M)",
      icon: "nav-icon fas fa-angle-right",
      enum: "MASTER",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/sub_admin",
      name: "Debit/Credit Entry (SUB)",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUB_ADMIN",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/admin",
      name: "Debit/Credit Entry (AD)",
      icon: "nav-icon fas fa-angle-right",
      enum: "ADMIN",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      name: "ALL LEDGER",
      isGroupTitle: true,
    },
    {
      url: "/ledger/client",
      name: "All Client Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "CLIENT",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/agent",
      name: "All Agent Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/super_agent",
      name: "All Super Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUPER_AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/master",
      name: "All Master Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "MASTER",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/sub_admin",
      name: "All Sub Admin Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUB_ADMIN",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/admin",
      name: "All Admin Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "ADMIN",
      isPermissionEnabled: true,
    },

    {
      name: "REPORTS",
      isGroupTitle: true,
    },
    {
      name: "Reports",
      url: "#",
      icon: "nav-icon fa fa-book",
      subNavs: [
        {
          name: "Current Bets",
          url: "/report/current_bets",
          icon: "nav-icon fa fa-book",
        },
        {
          name: "Bet History",
          url: "/report/bet_history",
          icon: "nav-icon fa fa-book",
        },
        {
          name: "General Report",
          url: "/report/general_report",
          icon: "nav-icon fa fa-book",
        },
      ],
    },

    {
      name: "Data Reports",
      url: "#",
      icon: "nav-icon fas fa-database",
      isPermissionEnabled: true,
      subNavs: [
        {
          url: "/report/admin",
          name: "Admin Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "ADMIN",
        },
        {
          url: "/report/sub_admin",
          name: "Sub Admin Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "SUB_ADMIN",
        },
        {
          url: "/report/master",
          name: "Master Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "MASTER",
        },
        {
          url: "/report/super_agent",
          name: "Super Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "SUPER_AGENT",
        },
        {
          url: "/report/agent",
          name: "Agent Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "AGENT",
        },
        {
          url: "/report/client",
          name: "Clients Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "CLIENT",
        },
      ],
    },

    {
      name: "Login Reports",
      url: "#",
      icon: "nav-icon fas fa-sign-in-alt",
      isPermissionEnabled: true,
      subNavs: [
        {
          url: "/report/login/admin",
          name: "Admin Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "ADMIN",
        },
        {
          url: "/report/login/sub_admin",
          name: "Sub Admin Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "SUB_ADMIN",
        },
        {
          url: "/report/login/master",
          name: "Master Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "MASTER",
        },
        {
          url: "/report/login/super_master",
          name: "Super Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "SUPER_AGENT",
        },
        {
          url: "/report/login/agent",
          name: "Agent Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "AGENT",
        },
        {
          url: "/report/login/client",
          name: "Clients Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "CLIENT",
        },
      ],
    },
  ]);
  const sidebarRef = React.useRef();
  const closeSideDrawer = () => {
    document.body.classList.remove("sidebar-open");
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: false,
    });
  };

  const handleMenuToggle = () => {
    document.body.classList.toggle("sidebar-open");
  };
  const currentUserType = userData?.userType;
  const isSideBarOpen = useSelector(
    (state) => state.account.userData.isSideBarOpen
  );
  const currentLvl = PERMISSION_MAP[currentUserType];
  useEffect(() => {
    //handle outside click
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSideDrawer();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <div>
      <aside
        ref={sidebarRef}
        id="sidebar"
        className="main-sidebar text-sm sidebar-light-indigo elevation-4"
      >
        {/* Brand Logo */}
        <a href="/" className="brand-link bg-black text-white">
          <img
            src="https://adminlte.io/themes/v3/dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light" id="brandName">
            {APP_NAME}
          </span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {navs?.map((item, index) =>
                item?.platformSpecific &&
                item?.platformSpecific !=
                  PLATFORM ? null : item.isGroupTitle ? (
                  <li key={index} className="nav-header">
                    {item.name}
                  </li>
                ) : item?.isPermissionEnabled &&
                  !PERMISSION_MAP[(userData?.userType)]?.includes(
                    item?.enum
                  ) &&
                  !item?.subNavs ? null : (
                  <li
                    className={item.active ? "active nav-item" : "nav-item"}
                    onClick={() => {
                      setCurrentActive(currentActive ? null : index);
                    }}
                  >
                    <a
                      className={
                        location.pathname === item.url &&
                        location.state?.isOld === item.state?.isOld
                          ? "active nav-link"
                          : "nav-link"
                      }
                      href="javascript:void(0)"
                      onClick={
                        item.url && !item?.subNavs
                          ? () => {
                              navigate(item.url, {
                                state: item.state,
                                replace: true,
                              });
                              closeSideDrawer();
                            }
                          : () => {}
                      }
                      data-method="get"
                    >
                      <i className={item.icon} /> <p>{item.name}</p>{" "}
                      {item?.subNavs ? (
                        <>
                          <i className="right fas fa-angle-left" />
                        </>
                      ) : null}
                    </a>
                    {item?.subNavs && (
                      <ul
                        className={`nav nav-second-level ${
                          index == currentActive ? "" : "collapse"
                        }`}
                        id="user_type"
                      >
                        {item?.isPermissionEnabled
                          ? item?.subNavs?.map(
                              (subnav) =>
                                PERMISSION_MAP[
                                  `${userData?.userType}`
                                ]?.includes(subnav.enum) && (
                                  <li key={subnav.url} className="nav-item">
                                    <a
                                      className="nav-link"
                                      href="javascript:void(0)"
                                      onClick={
                                        subnav.url
                                          ? () => {
                                              navigate(
                                                subnav.url,
                                                subnav?.state && {
                                                  state: subnav.state,
                                                }
                                              );
                                              closeSideDrawer();
                                            }
                                          : () => {}
                                      }
                                      data-method="get"
                                    >
                                      <i className={subnav.icon} />
                                      {subnav.name}
                                    </a>
                                  </li>
                                )
                            )
                          : item?.subNavs?.map((subnav) => (
                              <li key={subnav.url} className="nav-item">
                                <a
                                  className="nav-link"
                                  href="javascript:void(0)"
                                  onClick={
                                    subnav.url
                                      ? () => {
                                          navigate(
                                            subnav.url,
                                            subnav?.state && {
                                              state: subnav.state,
                                            }
                                          );
                                          closeSideDrawer();
                                        }
                                      : () => {}
                                  }
                                  data-method="get"
                                >
                                  <i className={subnav.icon} />
                                  {subnav.name}
                                </a>
                              </li>
                            ))}
                      </ul>
                    )}
                  </li>
                )
              )}
            </ul>

            {/* <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {navs.map((item, index) => (
              <li key={index} className={item.active ? "active nav-item" : "nav-item"}
              onClick={() => {
                setCurrentActive(currentActive ? null : index);
              }}>
                <a
                onClick={
                  item.path && !item?.subNavs
                    ? () => {
                        navigate(item.path);
                        closeSideDrawer();
                      }
                    : () => {}
                }
                >
                  <i className={`nav-icon ${item.icon}`} />
                  <p>{item.label}</p>
                  
                  {item?.subNavs && (
                <ul
                  className={`nav nav-second-level ${
                    index == currentActive ? "" : "collapse"
                  }`}
                  id="user_type"
                >
                  {item?.isPermissionEnabled
                    ? item?.subNavs?.map(
                        (subnav) =>
                          PERMISSION_MAP[`${userData?.userType}`]?.includes(
                            subnav.entity
                          ) && (
                            <li>
                              <a
                                onClick={
                                  subnav.url
                                    ? () => {
                                        navigate(
                                          subnav.url,
                                          subnav?.state && {
                                            state: subnav.state,
                                          }
                                        );
                                        closeSideDrawer();
                                      }
                                    : () => {}
                                }
                                data-method="get"
                              >
                                <i className={subnav.icon} />
                                {subnav.label}
                              </a>
                            </li>
                          )
                      )
                    : item?.subNavs?.map((subnav) => (
                        <li>
                          <a
                            onClick={
                              subnav.url
                                ? () => {
                                    navigate(
                                      subnav.url,
                                      subnav?.state && {
                                        state: subnav.state,
                                      }
                                    );
                                    closeSideDrawer();
                                  }
                                : () => {}
                            }
                            data-method="get"
                          >
                            <i className={subnav.icon} />
                            {subnav.label}
                          </a>
                        </li>
                      ))}
                </ul>
              )}
                </a>
              </li>
            ))}
          </ul> */}
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}
