import React, { useEffect, useState } from "react";
import {
  getCommissionReportAPI,
  resetCommissionReportByUserAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ComissionLenaDena() {
  const [data, setData] = useState();
  const navigate=useNavigate();
  const getCommissionReport = async () => {
    const { response, code } = await getCommissionReportAPI();
    if (code == 200) {
      setData(response.commissionReport);
    }
  };
  const resetComm = async (clientId) => {
    const { response, code } = await resetCommissionReportByUserAPI(clientId);
    if (code == 200) {
      toast.success("Commission Reset Successfully");
      getCommissionReport();
    }
  };
  useEffect(() => {
    getCommissionReport();
  }, []);
  return (
    <div className="content-wrapper wrapper wrapper-content animated fadeInRight">
      <div className="row" style={{ margin: 0 }}>
        <div className="col-lg-12 col-xs-12" style={{ padding: 0 }}>
          <div
            className="col-lg-12 col-xs-12"
            style={{
              border: "1px solid #e7eaec",
              background:
                "linear-gradient(to right,#2f5b69 0,#47889e 100%) !important",
              color: "#fff",
              padding: 7,
              fontSize: 16,
              fontWeight: "bold",
            }}
          />
          <div className="table-responsive">
            <table
              style={{ marginBottom: 0 }}
              className="table table-bordered table-striped dataTables-example dataTable no-footer"
              id="DataTables_Table_0"
              role="grid"
              aria-describedby="DataTables_Table_0_info"
            >
              <thead>
                <tr role="row">
                  <th
                    className="sorting_disabled tablelightblue"
                    colSpan={6}
                    style={{ textAlign: "Center" }}
                  >
                    Mila Hai
                  </th>
                  <th
                    className="sorting_disabled tabledarkblue"
                    colSpan={4}
                    style={{ textAlign: "Center" }}
                  >
                    Dena Hai
                  </th>
                </tr>
              </thead>
              <thead>
                <tr role="row">
                  <th
                    className="sorting_disabled tablelightblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    Name
                  </th>
                  <th
                    className="sorting_disabled tabledarkblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    M.Comm.
                  </th>
                  <th
                    className="sorting_disabled tablelightblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    S.Comm
                  </th>
                  <th
                    className="sorting_disabled tabledarkblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    C.Comm
                  </th>
                  <th
                    className="sorting_disabled tablelightblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    T.Comm.
                  </th>
                  <th
                    className="sorting_disabled tabledarkblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    Action
                  </th>
                  <th
                    className="sorting_disabled tablelightblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    M.Comm.
                  </th>
                  <th
                    className="sorting_disabled tabledarkblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    S.Comm
                  </th>
                  <th
                    className="sorting_disabled tablelightblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    C.Comm
                  </th>
                  <th
                    className="sorting_disabled tabledarkblue"
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    T.Comm
                  </th>
                </tr>
                <tr role="row">
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    style={{}}
                  >
                    Total
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="mmcom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce((a, b) => a + b.agentMatchCommission, 0)}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="mscom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce((a, b) => a + b.agentSessionCommission, 0)}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="mccom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce((a, b) => a + b.agentCasinoCommission, 0)}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="mtcom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce(
                      (a, b) =>
                        a +
                        b.agentMatchCommission +
                        b.agentSessionCommission +
                        b.agentCasinoCommission,
                      0
                    )}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    style={{ color: "green" }}
                  />
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="dmcom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce((a, b) => a + b.clientMatchCommission, 0)}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="dscom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce((a, b) => a + b.clientSessionCommission, 0)}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="dccom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce((a, b) => a + b.clientCasinoCommission, 0)}
                  </th>
                  <th
                    className="sorting_disabled "
                    rowSpan={1}
                    colSpan={1}
                    id="dtcom"
                    style={{ color: "green" }}
                  >
                    {data?.reduce(
                      (a, b) =>
                        a +
                        b.clientMatchCommission +
                        b.clientSessionCommission +
                        b.clientCasinoCommission,
                      0
                    )}
                  </th>
                </tr>
              </thead>
              <tbody id="setresult">
                {data?.map((item) => (
                  <tr className="odd">
                    <td>{item?.clientUsername}</td>
                    <td className="text-success">
                      {item?.agentMatchCommission}
                    </td>
                    <td className="text-success">
                      {item?.agentSessionCommission}
                    </td>
                    <td className="text-success">
                      {item?.agentCasinoCommission}
                    </td>
                    <td className="text-success">
                      {item?.agentMatchCommission +
                        item?.agentSessionCommission +
                        item?.agentCasinoCommission}
                    </td>
                    <td className="">
                      <a
                        className="btn btn-xs btn-primary"
                        style={{ width: 80 }}
                        onClick={() => resetComm(item?.clientId)}
                      >
                        <i aria-hidden="true">Reset</i>
                      </a>
                      <a
                        href={
                          "/agent/commission_lena_dena_user_report/" +
                          item?.clientId
                        }
                        className="btn btn-xs btn-success"
                        title="Settlement"
                        style={{
                          background: "#4083a9",
                          width: 80,
                          color: "#fff !important",
                        }}
                      >
                        Details
                      </a>
                      <a
                        onClick={() =>
                          navigate(
                            "/agent/commission_lena_dena_user_report_history/" +
                              item?.clientId,
                            {
                              state: { user: item },
                            }
                          )
                        }
                        className="btn btn-xs btn-success"
                        style={{ width: 80 }}
                      >
                        History
                      </a>
                    </td>
                    <td className="text-success">
                      {item?.clientMatchCommission}
                    </td>
                    <td className="text-success">
                      {item?.clientSessionCommission}
                    </td>
                    <td className="text-success">
                      {item?.clientCasinoCommission}
                    </td>
                    <td className="text-success">
                      {item?.clientMatchCommission +
                        item?.clientSessionCommission +
                        item?.clientCasinoCommission}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
