import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  addNewClientAPI,
  getGeneratedUsernameAPI,
  getUserDataAPI,
  getChildUserInfoAPI,
} from "../service/UserService";
import { Encrypt, PLATFORM } from "../utils/constants";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function CreateClient() {
  const [clientData, setClientData] = useState({});
  const location = useLocation();
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState(location.state?.userId);
  const userData = useSelector((state) => state.account.userData);
  const userType = useParams()?.usertype;
  const [isCommission, setIsCommission] = useState("no");
  const [isCasinoCommission, setIsCasinoCommission] = useState("no");
  const [isMatkaCommission, setIsMatkaCommission] = useState("no");
  const getDefaultGeneratedUsername = async () => {
    const { response } = await getGeneratedUsernameAPI(userType);
    setPayload({ ...payload, username: response.username });
  };

  const getChildUserInfo = async () => {
    const { response } = await getChildUserInfoAPI(userId);
    setClientData(response?.childInfo);
  };

  useEffect(() => {
    if (userId) {
      getChildUserInfo();
    } else {
      getUserInfo();
    }
  }, [userId]);

  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    setIsAdmin(false);
    setClientData(response);
  };

  const handleSubmit = async () => {
    let filteredPayload = {
      ...payload,
    };
    if (!payload.fullName) {
      toast.error(`Please fill in the Name field.`);
      return;
    }
    if (!payload.password) {
      toast.error(`Please fill in the Password field.`);
      return;
    }
    if (!payload.balance) {
      filteredPayload.balance = 0;
    }
    if (!payload.mobile) {
      toast.error(`Please fill in the Contact No. field.`);
      return;
    }
    if (!payload.myPartnership && !userType?.toUpperCase() == "CLIENT") {
      toast.error(`Please fill in the ${userType} Share field.`);
      return;
    }
    if (
      !payload.myCasinoPartnership &&
      !userType?.toUpperCase() == "CLIENT"
    ) {
      toast.error(`Please fill in the ${userType} Casino Share field.`);
      return;
    }
    if (!payload.commissionOn) {
      toast.error(`Please fill in the Commission Type field.`);
      return;
    }
    if (userType.toUpperCase() == "ADMIN") {
      if (filteredPayload.balance > userData?.balance) {
        toast.error("Limit can not be greater than your partnership");
        return;
      }
    } else {
      if (
        filteredPayload.balance >
        clientData?.partnershipAndCommission?.balance
      ) {
        toast.error("Limit can not be greater than your partnership");
        return;
      }
    }
    if (
      filteredPayload.matchCommission >
      clientData?.partnershipAndCommission?.matchCommission
    ) {
      setPayload({
        ...payload,
        matchCommission: "",
      });
      toast.error(
        "Match Commission can not be greater than your partnership"
      );
      return;
    }

    if (
      filteredPayload.sessionCommission >
      clientData?.partnershipAndCommission?.sessionCommission
    ) {
      setPayload({
        ...payload,
        sessionCommission: "",
      });
      toast.error(
        "Session Commission can not be greater than your partnership"
      );
      return;
    }

    if (
      filteredPayload.casinoCommission >
      clientData?.partnershipAndCommission?.casinoCommission
    ) {
      setPayload({
        ...payload,
        casinoCommission: "",
      });
      toast.error(
        "Casino Commission can not be greater than your partnership"
      );
      return;
    }
    if (
      filteredPayload.myCasinoPartnership >
      clientData?.partnershipAndCommission?.myCasinoPartnership
    ) {
      setPayload({
        ...payload,
        myCasinoPartnership: "",
      });
      toast.error("Casino Share can not be greater than your partnership");
      return;
    }
    delete filteredPayload["confirmPassword"];
    filteredPayload.password = Encrypt(payload.password);
    filteredPayload.userType = userType.toUpperCase();
    filteredPayload.parentUserId = location.state?.userId;
    // filteredPayload.parentUserId = clientData?.uid;
    filteredPayload.parentCasinoPartnership =
      clientData?.partnershipAndCommission?.myCasinoPartnership;
    if (userType?.toUpperCase() == "CLIENT") {
      filteredPayload.parentPartnership = payload.myShare;
    }
    try {
      const { response, code, error, status } = await addNewClientAPI(
        filteredPayload
      );
      if (code === 200) {
        toast.success(response?.status || "User Added Successfully");
        setPayload({});
        setTimeout(() => {
          window.location.href = `/${userType}`;
        }, 500);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      } else {
        toast.error(
          error.description ||
            "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };

  const handleCommissionChange = (e) => {
    const commissionOn = e.target.value == "true";

    setPayload({
      ...payload,
      commissionOn,
    });
    const defaultValues = commissionOn
      ? {
          matchCommission:
            clientData?.partnershipAndCommission.matchCommission || 0,
          sessionCommission:
            clientData?.partnershipAndCommission.sessionCommission || 0,
          casinoCommission:
            clientData?.partnershipAndCommission.casinoCommission || 0,
        }
      : {
          matchCommission: 0,
          sessionCommission: 0,
          casinoCommission: 0,
        };
    setPayload((prevState) => ({
      ...prevState,
      ...defaultValues,
      commissionOn: commissionOn ? commissionOn : "false",
    }));
  };

  useEffect(() => {
    getDefaultGeneratedUsername();
  }, []);

  const generatePassword = () => {
    const passwordLength = 6;
    let newPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      newPassword += Math.floor(Math.random() * 10); // Generate a random number between 0 and 9
    }
    setPayload({
      ...payload,
      password: newPassword,
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create {userType?.toLocaleLowerCase()}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Super</a>
                </li>
                <li className="breadcrumb-item active">New {userType}</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <form action="/master/store" method="POST">
          <input
            type="hidden"
            name="_csrf"
            defaultValue="ba9d3d2b-b2c1-4143-bd84-a7f988415a48"
          />
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="code">Code</label>
                    <input
                      type="text"
                      id="code"
                      placeholder=""
                      readOnly
                      className="form-control"
                      name="code"
                      value={payload?.username}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      min={2}
                      required=""
                      name="name"
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          fullName: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Reference</label>
                    <input
                      type="text"
                      id="reference"
                      className="form-control"
                      placeholder="Reference"
                      required=""
                      name="reference"
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          reference: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Password"
                        min={6}
                        id="password"
                        required=""
                        name="password"
                        value={payload.password}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            password: e.target.value,
                          })
                        }
                      />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-info btn-flat"
                          onClick={generatePassword}
                        >
                          Generate Password
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Contact No</label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobile"
                      placeholder="Mobile No"
                      required=""
                      name="mobile"
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          mobile: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            <div className="col-md-6">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Share and Commission</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      data-toggle="tooltip"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="share"
                        style={{ textTransform: "capitalize" }}
                      >
                        {userType} Limit
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={userData?.balance}
                        placeholder="Client Limit"
                        className="form-control"
                        id="limit"
                        defaultValue={0}
                        step="0.01"
                        required=""
                        name="balance"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            balance: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="mshare">My Limit</label>
                      {(userData?.userType === "SUPER_ADMIN" &&
                        userType.toUpperCase() === "ADMIN") ||
                      (userData?.userType === "ADMIN" &&
                        userType.toUpperCase() === "SUB_ADMIN") ||
                      (userData?.userType === "SUB_ADMIN" &&
                        userType.toUpperCase() === "MASTER") ||
                      (userData?.userType === "MASTER" &&
                        userType.toUpperCase() === "SUPER_AGENT") ||
                      (userData?.userType === "SUPER_AGENT" &&
                        userType.toUpperCase() === "AGENT") ||
                      (userData?.userType === "AGENT" &&
                        userType.toUpperCase() === "CLIENT") ? (
                        <>
                          <input
                            type="number"
                            placeholder="Limit"
                            className="form-control"
                            value={userData?.balance}
                            id="mlimit"
                            readOnly
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="number"
                            placeholder="Limit"
                            className="form-control"
                            value={
                              clientData?.partnershipAndCommission?.balance
                                ? clientData?.partnershipAndCommission
                                    ?.balance
                                : clientData?.balance
                            }
                            id="mlimit"
                            readOnly
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="share"
                        style={{ textTransform: "capitalize" }}
                      >
                        {userType} Share
                      </label>
                      <input
                        type="number"
                        max={
                          clientData?.partnershipAndCommission?.myPartnership
                            ? clientData?.partnershipAndCommission
                                ?.myPartnership
                            : clientData?.myPartnership
                        }
                        min={0.0}
                        name="user[agent_share]"
                        className="form-control"
                        placeholder="My Share"
                        required="required"
                        defaultValue={0}
                        value={payload?.myPartnership}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            myPartnership: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="mshare">My Share</label>
                      <input
                        type="number"
                        placeholder="Share"
                        className="form-control"
                        value={
                          clientData?.partnershipAndCommission?.myPartnership
                            ? clientData?.partnershipAndCommission
                                ?.myPartnership
                            : clientData?.myPartnership
                        }
                        id="mshare"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="share"
                        style={{ textTransform: "capitalize" }}
                      >
                        {userType} Casino Share
                      </label>
                      <input
                        type="number"
                        max={
                          clientData?.partnershipAndCommission
                            ?.myCasinoPartnership
                            ? clientData?.partnershipAndCommission
                                ?.myCasinoPartnership
                            : clientData?.myCasinoPartnership
                        }
                        min={0.0}
                        placeholder="Casino Share"
                        className="form-control"
                        id="cshare"
                        step="0.01"
                        required=""
                        defaultValue={0}
                        name="cshare"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            myCasinoPartnership: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="cmshare">My Casino Share</label>
                      <input
                        type="number"
                        placeholder="Share"
                        className="form-control"
                        value={
                          clientData?.partnershipAndCommission
                            ?.myCasinoPartnership
                            ? clientData?.partnershipAndCommission
                                ?.myCasinoPartnership
                            : clientData?.myCasinoPartnership
                        }
                        id="cmshare"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="form-group col-md-6">
                      <label
                        htmlFor="share"
                        style={{ textTransform: "capitalize" }}
                      >
                        {userType} Matka Share
                      </label>
                      <input
                        type="number"
                        max={
                          clientData?.partnershipAndCommission
                            ?.myMatkaPartnership
                            ? clientData?.partnershipAndCommission
                                ?.myMatkaPartnership
                            : clientData?.myMatkaPartnership
                        }
                        min={0.0}
                        placeholder="Casino Share"
                        className="form-control"
                        id="cshare"
                        step="0.01"
                        required=""
                        defaultValue={0}
                        name="cshare"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            myMatkaPartnership: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="cmshare">My Matka Share</label>
                      <input
                        type="number"
                        placeholder="Share"
                        className="form-control"
                        value={
                          clientData?.partnershipAndCommission
                            ?.myMatkaPartnership
                            ? clientData?.partnershipAndCommission
                                ?.myMatkaPartnership
                            : clientData?.myMatkaPartnership
                        }
                        id="cmshare"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="commissionOn">Commission Type</label>
                    <select
                      id="commissionOn"
                      className="form-control"
                      onChange={(e) => {
                        handleCommissionChange(e);
                        setIsCommission(e.target.value);
                      }}
                    >
                      <option>Please Select..</option>
                      <option value="false">No Comm</option>
                      <option value="true">Bet by Bet</option>
                    </select>
                  </div>

                  <>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="match_commission">
                          Match Commision
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          step="0.01"
                          id="match_commission"
                          required=""
                          name="matchcommission"
                          max={
                            clientData?.partnershipAndCommission
                              ?.matchCommission
                              ? clientData?.partnershipAndCommission
                                  ?.matchCommission
                              : clientData?.matchCommission
                          }
                          min={0.0}
                          defaultValue={0}
                          value={payload?.matchCommission}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              matchCommission: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mc">My Match Commission</label>
                        <input
                          id="mc"
                          type="text"
                          min={0}
                          max={3}
                          value={
                            clientData?.partnershipAndCommission
                              ?.matchCommission
                              ? clientData?.partnershipAndCommission
                                  ?.matchCommission
                              : clientData?.matchCommission
                          }
                          className="form-control"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="session_commission">
                          Session Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          step="0.01"
                          id="session_commission"
                          required=""
                          name="sessioncommission"
                          max={
                            clientData?.partnershipAndCommission
                              ?.sessionCommission
                              ? clientData?.partnershipAndCommission
                                  ?.sessionCommission
                              : clientData?.sessionCommission
                          }
                          min={0.0}
                          defaultValue={0}
                          value={payload?.sessionCommission}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              sessionCommission: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mc">My Session Commission</label>
                        <input
                          type="number"
                          value={
                            clientData?.partnershipAndCommission
                              ?.sessionCommission
                              ? clientData?.partnershipAndCommission
                                  ?.sessionCommission
                              : clientData?.sessionCommission
                          }
                          id="sc"
                          className="form-control"
                          placeholder="Session Commission"
                          readOnly
                        />
                      </div>
                    </div>
                  </>

                  <div className="form-group">
                    <label htmlFor="commissionOn">Casino Commission </label>
                    <select
                      id="commissionOn"
                      className="form-control"
                      value={isCasinoCommission}
                      onChange={(e) => setIsCasinoCommission(e.target.value)}
                    >
                      <option>Please Select..</option>
                      <option value="no">No Comm</option>
                      <option value="yes">Bet by Bet</option>
                    </select>
                  </div>
                  {isCasinoCommission == "yes" && (
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="casino_commission">
                          Casino Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          max={
                            clientData?.partnershipAndCommission
                              ?.casinoCommission
                              ? clientData?.partnershipAndCommission
                                  ?.casinoCommission
                              : clientData?.casinoCommission
                          }
                          min={0.0}
                          defaultValue={0}
                          id="casino_commission"
                          required=""
                          value={payload?.casinoCommission}
                          name="casinoCommission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              casinoCommission: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="cc">My Casino Commission</label>
                        <input
                          type="number"
                          value={
                            clientData?.partnershipAndCommission
                              ?.casinoCommission
                              ? clientData?.partnershipAndCommission
                                  ?.casinoCommission
                              : clientData?.casinoCommission
                          }
                          className="form-control"
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                  {PLATFORM == "2exch" && (
                    <>
                      <div className="form-group">
                        <label htmlFor="commissionOn">
                          Matka Commission Type
                        </label>
                        <select
                          id="commissionOn"
                          className="form-control"
                          onChange={(e) => {
                            setIsMatkaCommission(e.target.value);
                          }}
                        >
                          <option>Please Select..</option>
                          <option value="false">No Comm</option>
                          <option value="true">Bet by Bet</option>
                        </select>
                      </div>
                      {isMatkaCommission == "true" && (
                        <div className="form-group row">
                          <div className="form-group col-md-6">
                            <label htmlFor="casino_commission">
                              Matka Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              max={
                                clientData?.partnershipAndCommission
                                  ?.matkaCommission
                                  ? clientData?.partnershipAndCommission
                                      ?.matkaCommission
                                  : clientData?.matkaCommission
                              }
                              min={0.0}
                              defaultValue={0}
                              id="casino_commission"
                              required=""
                              value={payload?.matkaCommission}
                              name="casinoCommission"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  matkaCommission: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="cc">My Matka Commission</label>
                            <input
                              type="number"
                              value={
                                clientData?.partnershipAndCommission
                                  ?.matkaCommission
                                  ? clientData?.partnershipAndCommission
                                      ?.matkaCommission
                                  : clientData?.matkaCommission
                              }
                              defaultValue={0}
                              className="form-control"
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <a href="/master" className="btn btn-secondary">
                Cancel
              </a>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-success float-right"
              >
                Create New {userType}
              </button>
            </div>
          </div>
        </form>
        <br />
      </section>
    </div>
  );
}
