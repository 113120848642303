import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAdminPlExposureCricketAPI,
  getBetActiveDeactiveCricketAPI,
  getClientBetsCricketAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getSettledFancyAPI,
} from "../service/cricket";
import {
  getLiveStreamingUrlAPI,
  getSportsScoreAPI,
  getBookMakerPositionAPI,
} from "../service/AuthService";
import moment from "moment";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

export default function MatchPosition() {
  const userData = useSelector((state) => state.account.userData);
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [betFilter, setBetFilter] = useState("BOOKMAKER_ODDS_1");
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [settledFancy, setSettledFancy] = useState({});
  const [matchData, setMatchData] = useState(null);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [bookmakerPosition, setBookmakerPosition] = useState([]);
  const [gameSettings_, setGameSettings_] = useState({});

  const [channelId, setChannelId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  var gameType = "cricket";
  var eventId = params?.id;
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const scoreData = useQuery("scoreData", () => getSportsScoreAPI(eventId), {
    refetchInterval: 4000,
  });
  const getBookMakerposition = async (uid) => {
    const { response, code } = await getBookMakerPositionAPI(eventId, uid);
    if (code == 200) {
      setBookmakerPosition(response);
    }
  };

  const getGameEventDetails = useCallback(async () => {
    const { response } = await getCricketEventDetailsAPI(eventId);
    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  const getSettledFancy = async () => {
    const { response } = await getSettledFancyAPI(eventId);
    setSettledFancy(response);
  };
  useEffect(() => {
    getGameEventDetails();

    setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);

  const getCricketPlExposure = async () => {
    const { response, code } = await getAdminPlExposureCricketAPI(eventId);
    if (code == 200) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_, isFancy) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      isFancy ? isFancy : params?.runnerName ? true : false,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveCricketAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(eventId);
    if (code != 200) {
      toast.error("Something went wrong");
      window.location.href = "/";
      return;
    }
    let response_ = {};
    response_[eventId] = response;
    setMatchOdds(response);
    dispatch({
      type: "gameData/setCricketOdds",
      payload: response_,
    });
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      getCricketPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getCricketPlExposure();
        getClientBets();
        getBetActiveDeactive();
        getSettledFancy();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
    }, 800);
    return () => clearInterval(interval);
  }, [eventId]);

  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <iframe
                style={{
                  padding: "0 10px",
                  width: "100%",
                }}
                src={`https://video.proexch.in/tv/static?chid=${channelId}`}
              />
            </div>
            <div className="col-lg-8">
              {
                <iframe
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  src={`https://score.proexch.in/#/score1/${eventId}`}
                />
              }
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="mb-3">Match Position</h1>
              {/* {userData.userType == "SUPER_ADMIN" ? (
                <>
                  <a
                    className="mt-5"
                    onClick={() =>
                      navigate(
                        `/agent/dashboard/matches/${
                          matchDetails?.cricketId
                        }/current_bets`,
                        {
                          state: { matchDetails },
                        }
                      )
                    }
                  >
                    <button className="btn btn-primary">
                      View More Bets
                    </button>
                  </a>
                </>
              ) : null} */}
              {/*  <a
                className="mt-5 ml-2"
                onClick={() =>
                  navigate(
                    `/match/${matchDetails?.cricketId}/bookmaker_book`,
                    {
                      state: { matchDetails },
                    }
                  )
                }
              >
                <button className="btn btn-primary">
                  View BookMaker Book
                </button>
              </a> */}
              <a
                onClick={() => {
                  navigate(`/game/i/${eventId}/viewMatchReport`);
                }}
                className="mt-5 ml-2"
              >
                <button className="btn btn-primary">Match Bets</button>
              </a>
              <a
                onClick={() => {
                  navigate(`/game/i/${eventId}/viewSessionReport`);
                }}
                className="mt-5 ml-2"
              >
                <button className="btn btn-primary">Session Bets</button>
              </a>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="/game/inPlay">InPlay</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-secondary text-center">
                <div className="form-row">
                  <div className="col-md-6">
                    <table className="table table-bordered" id="mOdds">
                      <thead>
                        <tr>
                          <th> Team</th>
                          <th className="text-red" style={{ width: 40 }}>
                            {" "}
                            KHAI
                          </th>
                          <th className="text-blue" style={{ width: 40 }}>
                            {" "}
                            LAGAI
                          </th>
                          <th>
                            <b className="text-blue">PLUS</b>/
                            <b className="text-red">MINUS</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {matchOdds &&
                          matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                            (match, index) => (
                              <tr>
                                <td
                                  data-column="runner_name"
                                  className="bet-place-tbl-td active"
                                  style={{ textAlign: "left" }}
                                >
                                  {match?.runnerName}
                                </td>
                                <td
                                  data-runner-sid="Multan Sultans  _1436644"
                                  data-column="back"
                                  className="MarketTable text-bold text-red bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                                  odd-val="1.27"
                                  market-id={1594789}
                                  runner-id={8933387}
                                  match-id={1436644}
                                  data-id="LAGAI"
                                  id="Match Winner_1436644_back_Multan Sultans  _1436644"
                                >
                                  {match?.status == "ACTIVE"
                                    ? parseFloat(match?.back1 / 100).toFixed(
                                        2
                                      )
                                    : 0}
                                </td>
                                <td
                                  data-runner-sid="Multan Sultans  _1436644"
                                  data-column="lay"
                                  className="text-bold text-blue MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                                  odd-val="1.29"
                                  market-id={1594789}
                                  runner-id={8933387}
                                  match-id={1436644}
                                  data-id="KHAI"
                                  id="Match Winner_1436644_lay_Multan Sultans  _1436644"
                                >
                                  {match?.status == "ACTIVE"
                                    ? parseFloat(match?.lay1 / 100).toFixed(2)
                                    : 0}
                                </td>
                                <td
                                  data-runner-sid="Multan Sultans  _1436644"
                                  data-column="position"
                                  className="bet-place-tbl-td  bet-val-cent"
                                  data-position={0.0}
                                >
                                  <b>
                                    {matchData &&
                                      Array.isArray(matchData) &&
                                      matchData
                                        ?.find(
                                          (item) =>
                                            item.marketIdExternal ==
                                            matchOdds?.bookMakerOdds[0]?.bm1
                                              ?.marketId
                                        )
                                        ?.runnerPlExposureDTOS?.find(
                                          (pl) =>
                                            pl.selectionId ==
                                            match?.selectionId
                                        )?.exposure && (
                                        <span
                                          className={
                                            parseFloat(
                                              matchData
                                                ?.find(
                                                  (item) =>
                                                    item.marketIdExternal ==
                                                    matchOdds
                                                      ?.bookMakerOdds[0]?.bm1
                                                      ?.marketId
                                                )
                                                ?.runnerPlExposureDTOS?.find(
                                                  (pl) =>
                                                    pl.selectionId ==
                                                    match?.selectionId
                                                )?.exposure
                                            ) >= 0
                                              ? "text-blue mx-2"
                                              : "text-red mx-2"
                                          }
                                        >
                                          {parseFloat(
                                            matchData
                                              ?.find(
                                                (item) =>
                                                  item.marketIdExternal ==
                                                  matchOdds?.bookMakerOdds[0]
                                                    ?.bm1?.marketId
                                              )
                                              ?.runnerPlExposureDTOS?.find(
                                                (pl) =>
                                                  pl.selectionId ==
                                                  match?.selectionId
                                              )?.exposure
                                          ).toFixed(2)}
                                        </span>
                                      )}
                                  </b>
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <table className="table table-bordered" id="sOdds">
                      <thead>
                        <tr>
                          <th>Session</th>
                          <th className=" text-red">NOT</th>
                          <th className="text-blue">YES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matchOdds?.fancyOdds
                          ?.find(
                            (item) =>
                              item?.gameType?.toLocaleLowerCase() == "normal"
                          )
                          ?.oddDetailsDTOS?.map(
                            (match) =>
                              match?.status != "SUSPENDED" && (
                                <tr>
                                  <td
                                    data-column="session_title"
                                    className="bet-place-tbl-td"
                                    style={{ textAlign: "left" }}
                                  >
                                    <a
                                      data-method="get"
                                      data-remote="true"
                                      onClick={() => {
                                        window.location.href = `/game/matchPosition/${eventId}/${
                                          match?.runnerName
                                        }/${match?.marketId}`;
                                      }}
                                    >
                                      {match?.runnerName}
                                    </a>

                                    <br />

                                    <br />
                                  </td>
                                  <td
                                    className={`SessionTable   text-bold text-red bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer ${(match?.status ==
                                      "SUSPENDED" ||
                                      match?.status == "Ball Running") &&
                                      "close-status"}`}
                                  >
                                    {match?.status != "SUSPENDED" &&
                                    match?.status != "Ball Running"
                                      ? parseInt(match?.lay1)
                                      : 0}
                                    <br />
                                    <span className="badge badge-danger">
                                      {match?.status != "SUSPENDED" &&
                                      match?.status != "Ball Running"
                                        ? parseFloat(
                                            match?.laySize1 / 100
                                          ).toFixed(2)
                                        : 0.0}
                                    </span>
                                  </td>

                                  <td
                                    className={`${(match?.status ==
                                      "SUSPENDED" ||
                                      match?.status == "Ball Running") &&
                                      "close-status"} SessionTable text-bold text-blue bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer`}
                                  >
                                    {match?.status != "SUSPENDED" &&
                                    match?.status != "Ball Running"
                                      ? parseInt(match?.back1)
                                      : 0}
                                    <br />
                                    <span className="badge badge-primary">
                                      {match?.status != "SUSPENDED" &&
                                      match?.status != "Ball Running"
                                        ? parseFloat(
                                            match?.backSize1 / 100
                                          ).toFixed(2)
                                        : 0.0}
                                    </span>
                                  </td>
                                </tr>
                              )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-10">
                    {params?.runnerName ? (
                      <table
                        className="table table-bordered table-striped table-hover table-responsive-md"
                        id="matchBetsTable"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Run</th>
                            <th>Rate</th>

                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Team</th>
                            <th>Client</th>
                            <th>YES</th>
                            <th>NO</th>

                            <th>Date &amp; Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientBets?.bets
                            ?.filter((item) => {
                              if (params?.runnerName) {
                                return item?.runnerName == params?.runnerName;
                              } else if (betFilter) {
                                if (betFilter == "BOOKMAKER_ODDS_1")
                                  return item?.marketName == betFilter;
                                else
                                  return (
                                    item?.marketName != "BOOKMAKER_ODDS_1"
                                  );
                              } else {
                                return item;
                              }
                            })
                            .map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item?.run}</td>
                                  <td>{item.rate}</td>

                                  <td>
                                    {parseFloat(item.amount).toFixed(2)}
                                  </td>
                                  <td>{item?.back ? "YES" : "NO"}</td>
                                  <td>{item.runnerName}</td>
                                  <td>{item.userName}</td>

                                  {item?.upperLineExposure?.map((item) => (
                                    <td>
                                      {parseFloat(item?.exposure).toFixed(2)}
                                    </td>
                                  ))}
                                  <td>
                                    {moment(item.placeDate).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <table
                        className="table table-bordered table-striped table-hover table-responsive-md"
                        id="matchBetsTable"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Team</th>
                            <th>Client</th>
                            {matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS?.map(
                              (item) => <th>{item?.runnerName}</th>
                            )}

                            <th>Date &amp; Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clientBets?.bets
                            ?.filter((item) => {
                              if (params?.runnerName) {
                                return item?.runnerName == params?.runnerName;
                              } else if (betFilter) {
                                if (betFilter == "BOOKMAKER_ODDS_1")
                                  return item?.marketName == betFilter;
                                else
                                  return (
                                    item?.marketName != "BOOKMAKER_ODDS_1"
                                  );
                              } else {
                                return item;
                              }
                            })
                            .map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.rate}</td>
                                  <td>
                                    {parseFloat(item.amount).toFixed(2)}
                                  </td>
                                  <td>
                                    {params?.runnerName
                                      ? item?.back
                                        ? "YES"
                                        : "NO"
                                      : betFilter == "BOOKMAKER_ODDS_1"
                                      ? item?.back
                                        ? "LAGAI"
                                        : "KHAI"
                                      : "K"}
                                  </td>
                                  <td>{item.runnerName}</td>
                                  <td>{item.userName}</td>

                                  {item?.upperLineExposure?.map((item) => (
                                    <td>
                                      {parseFloat(item?.exposure).toFixed(2)}
                                    </td>
                                  ))}
                                  <td>
                                    {moment(item.placeDate + "Z").format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {params?.runnerName && (
                    <div className="col-lg-2">
                      <table
                        className="table table-bordered"
                        id="sessionPosition"
                      >
                        <thead>
                          <tr>
                            <th>Runs</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchData &&
                            matchData
                              ?.find(
                                (item) =>
                                  item.marketIdExternal == params?.marketId
                              )
                              ?.runnerPlExposureDTOS?.sort(
                                (a, b) =>
                                  parseInt(a.selectionName) -
                                  parseInt(b.selectionName)
                              )
                              ?.map((item) => (
                                <tr>
                                  <td>{item?.selectionName}</td>
                                  <td
                                    style={{
                                      color:
                                        item?.exposure < 0 ? "red" : "green",
                                    }}
                                  >
                                    {parseFloat(item?.exposure).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  <section className="content">
        <div className="col-md-6">
          <table className="table table-bordered" id="mOdds">
            <thead>
              <button
                className="btn btn-primary"
                onClick={() => getBookMakerposition()}
              >
                Get BookMaker Position
              </button>
            </thead>
            <thead>
              <tr>
                <th> User</th>
                {bookmakerPosition &&
                  bookmakerPosition.length > 0 &&
                  bookmakerPosition[0].runnerBookResponseDTOS?.map((item) => (
                    <th>{item.runnerName}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {bookmakerPosition &&
                bookmakerPosition.map((item, index) => (
                  <tr>
                    <td onClick={() => getBookMakerposition(item.uid)}>
                      {item.userName}
                    </td>
                    {item.runnerBookResponseDTOS?.map((item) => (
                      <td>{item.pandL}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section> */}
    </div>
  );
}
