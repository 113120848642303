import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientBetsCricketAPI } from "../service/cricket";
import Select from "react-select";
import moment from "moment";

export default function MatchPosition({ isBookMakerOnly }) {
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [totalLength, setTotalLength] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const params = useParams();

  const [uniqueUser, setUniqueUser] = useState([]);
  var gameType = "cricket";
  var eventId = params?.id;

  const getClientBets = async (betStatus_) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      false,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setUniqueUser(
        Array.from(new Set(response?.bets?.map((item) => item?.userName)))
      );
      if (selectedClient && selectedClient.value != "null") {
        let filteredData = response;
        filteredData.bets = response.bets.filter(
          (item) => item.userName == selectedClient.value
        );
        setClientBets(filteredData);
      } else {
        setClientBets(response);
      }

      const totalAmount = response?.bets?.reduce(
        (total, item) => total + item.amount,
        0
      );
      setTotalAmount(totalAmount);

      if (
        response.bets.length != 0 &&
        response.bets[0]?.upperLineExposure?.length > 0
      ) {
        setTotalLength(response.bets[0]?.upperLineExposure.length);
      }
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Bet Details </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Match Bet Details</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div id="myForm" method="post">
                  <div className="card-header ">
                    <div className="form-row col-md-6">
                      <table
                        className="table table-bordered table-striped"
                        data-select2-id={6}
                      >
                        <tbody className="text-bold">
                          {clientBets &&
                            clientBets?.bets?.length > 0 &&
                            clientBets?.bets[0]?.parentExposure?.map(
                              (match, index) => (
                                <tr>
                                  <th
                                    data-column="runner_name"
                                    className="bet-place-tbl-td active"
                                    style={{ textAlign: "left" }}
                                  >
                                    {match?.selectionName}
                                  </th>

                                  <th
                                    data-runner-sid="Multan Sultans  _1436644"
                                    data-column="position"
                                    className="bet-place-tbl-td  bet-val-cent"
                                    data-position={0.0}
                                  >
                                    <b>
                                      {
                                        <span
                                          className={
                                            parseFloat(
                                              clientBets?.bets?.reduce(
                                                (acc, item) =>
                                                  acc +
                                                  item?.parentExposure?.find(
                                                    (entity) =>
                                                      entity.selectionName ==
                                                      match.selectionName
                                                  )?.exposure,
                                                0
                                              )
                                            ) >= 0
                                              ? "text-blue mx-2"
                                              : "text-red mx-2"
                                          }
                                        >
                                          {parseFloat(
                                            clientBets?.bets?.reduce(
                                              (acc, item) =>
                                                acc +
                                                item?.parentExposure?.find(
                                                  (entity) =>
                                                    entity.selectionName ==
                                                    match.selectionName
                                                )?.exposure,
                                              0
                                            )
                                          ).toFixed(2)}
                                        </span>
                                      }
                                    </b>
                                  </th>
                                </tr>
                              )
                            )}
                          <tr>
                            <th>Client</th>
                            <th data-select2-id={4}>
                              <Select
                                required=""
                                id="name"
                                placeholder="Select Client"
                                name="client"
                                data-select2-id="name"
                                tabIndex={-1}
                                aria-hidden="true"
                                options={uniqueUser?.map((item, index) => ({
                                  value: item,
                                  label: item,
                                }))}
                                value={selectedClient}
                                onChange={(e) =>
                                  setSelectedClient(e)
                                }
                              />
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <button
                        onClick={() => getClientBets(betStatus)}
                        type="button"
                        className="form-control btn btn-primary"
                        id="btn"
                        name="submit"
                        hidden=""
                        defaultValue="Sumbit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Client</th>
                                <th>Rate</th>
                                <th>Team</th>
                                <th>Mode</th>
                                <th>Amount</th>
                                <th>Date &amp; Time</th>

                                {clientBets &&
                                  clientBets?.bets?.length > 0 &&
                                  Array.from(
                                    new Set(
                                      clientBets?.bets[0]?.parentExposure?.map(
                                        (item) => item?.selectionName
                                      )
                                    )
                                  )?.map((item, index) => <th>{item}</th>)}
                              </tr>
                              <tr>
                                <th
                                  colSpan={7}
                                  className="text-indigo text-lg text-center"
                                >
                                  Total Amount
                                </th>

                                {Array.from({ length: totalLength }).map(
                                  (_, index) => (
                                    <th
                                      className={
                                        clientBets?.bets?.reduce(
                                          (acc, item) =>
                                            acc +
                                            item?.upperLineExposure[index]
                                              ?.exposure,
                                          0
                                        ) > 0
                                          ? "text-green"
                                          : "text-red"
                                      }
                                    >
                                      {clientBets?.bets?.reduce(
                                        (acc, item) =>
                                          acc +
                                          item?.upperLineExposure[index]
                                            ?.exposure,
                                        0
                                      )}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {clientBets?.bets
                                ?.filter((item) => item?.marketName != "Normal")
                                ?.map((item, index) => (
                                  <>
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.userName}</td>
                                      <td>{item.rate}</td>
                                      <td>{item.runnerName}</td>
                                      <td>{item.back ? "LAGAI" : "KHAI"}</td>
                                      <td>{item.amount}</td>
                                      <td>
                                        {moment(item.placeDate + "Z").format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )}
                                      </td>
                                      {item?.upperLineExposure?.map((_) => (
                                        <th>{_?.exposure}</th>
                                      ))}
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
