import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientAPI } from "../service/UserService";
import { updateCommissionAPI } from "../service/AuthService";
import toast from "react-hot-toast";

export default function ClientLimitUpdate() {
  const userData = useSelector((state) => state.account.userData);
  const params = useParams();
  const [search, setSearch] = useState("");
  const [clientData, setClientData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const location = useLocation();
  const [userType, setUserType] = useState(params?.userType?.toUpperCase());
  const [id, setId] = useState(location?.state?.userName || "");
  const [updateCommission, setUpdateCommission] = useState({});

  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      id,
      params?.userType.toUpperCase()
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };
  const handleUpdateCommission = async (type) => {
    let updatedLimit;
    if (type === 'Minus') {
      updatedLimit = -updateCommission.currentLimit;
    } else {
      updatedLimit = updateCommission.currentLimit;
    }
    try {
      const { response, code } = await updateCommissionAPI({ ...updateCommission, currentLimit: updatedLimit });
      if (code === 200) {
        toast.success("Limit Updated Successfully");
        getClient();
        window.location.reload();
        setUpdateCommission({
          ...updateCommission,
          currentLimit:"",
        });
      } else {
        toast.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getClient();
  }, [location.state, currentPage]);
  return (
    <>
      <div className="content-wrapper" style={{ minHeight: "1300.41px" }}>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{params?.userType?.toLocaleUpperCase()}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/super">
                      {params?.userType?.toLocaleUpperCase()}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">
                    Update {params?.userType?.toLocaleUpperCase()} Limit
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div>
                {/* /.card */}
                <div className="card card-indigo">
                  <div className="card-header">
                    <h4>
                      UPDATE {params?.userType?.toLocaleUpperCase()} LIMIT
                    </h4>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <table
                            id="example1"
                            className="table table-bordered table-striped dataTable no-footer"
                            aria-describedby="example1_info"
                          >
                            <thead>
                              <tr>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="
                                      SNo
                                  : activate to sort column ascending"
                                >
                                  SNo
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Master Name: activate to sort column ascending"
                                >
                                  Master Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Limit: activate to sort column ascending"
                                >
                                  Limit
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Enter Limit: activate to sort column ascending"
                                >
                                  Enter Limit
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="example1"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="My Limit"
                                >
                                  <div
                                    className="d-flex gp-3"
                                    style={{ whiteSpace: "pre" }}
                                  >
                                    My Limit :
                                    {location?.state?.client
                                      ? location?.state?.client?.balance
                                      : userData?.balance}
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientData?.childrenUserResponseDTOS?.map(
                                (client, index) => (
                                  <tr data-user-id={5363}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {client?.username} {client?.name}
                                    </td>
                                    <td>
                                      {parseFloat(
                                        client?.balance + client?.liability
                                      ).toFixed(2)}
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="min-width-120 limit_input form-control"
                                        id="match-commission"
                                        data-field-name="match_commission"
                                        onChange={(e) => {
                                          setUpdateCommission({
                                            ...updateCommission,
                                            id: client?.uid,
                                            currentLimit: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>

                                    <td>
                                      <div className="d-flex gp-3">
                                        <button
                                          onClick={() =>
                                            handleUpdateCommission("Plus")
                                          }
                                          class="btn-sm btn-primary"
                                          type="submit"
                                          name="type"
                                          value="Add"
                                        >
                                          Add
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleUpdateCommission("Minus")
                                          }
                                          class="btn-sm btn-danger"
                                          type="submit"
                                          name="type"
                                          value="Minus"
                                        >
                                          {" "}
                                          Minus
                                        </button>
                                      </div>
                                    </td>
                                    <td />
                                  </tr>
                                )
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={10}>
                                  <div className="d-flex justify-content-end">
                                    <ul className="pagination">
                                      <li
                                        className={`page-item ${
                                          currentPage === 0 ? "disabled" : ""
                                        }`}
                                      >
                                        <button
                                          className="page-link"
                                          onClick={() => {
                                            if (currentPage > 0) {
                                              setCurrentPage(currentPage - 1);
                                            }
                                          }}
                                        >
                                          Previous
                                        </button>
                                      </li>
                                      {Array.from(
                                        Array(totalPages).keys()
                                      ).map((page) => (
                                        <li
                                          className={`page-item ${
                                            currentPage === page
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <button
                                            className="page-link"
                                            onClick={() => {
                                              setCurrentPage(page);
                                            }}
                                          >
                                            {page + 1}
                                          </button>
                                        </li>
                                      ))}
                                      <li
                                        className={`page-item ${
                                          currentPage === totalPages - 1
                                            ? "disabled"
                                            : ""
                                        }`}
                                      >
                                        <button
                                          className="page-link"
                                          onClick={() => {
                                            if (
                                              currentPage <
                                              totalPages - 1
                                            ) {
                                              setCurrentPage(currentPage + 1);
                                            }
                                          }}
                                        >
                                          Next
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
      {/*/ END Template Main Content */}
    </>
  );
}
