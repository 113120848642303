import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import {
  APP_NAME,
  PUBLIC_KEY,
  domainMap,
  generateFingerprint,
} from "../utils/constants";
import { generateHash } from "../service";

function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const login = async (e) => {
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    let fingerprint = await generateHash();
    localStorage.setItem("fingerprint", fingerprint);
    var { data, code, message, response, error } = await loginAPI(
      userName,
      encryptedPassword
    );
    if (code === 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      window.location.href = "/";
    } else if (code == 500) {
      toast.error(response);
    } else if (code == 403) {
      toast.error(error?.description + " contact admin");
    } else if (code == 401) {
      toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
  };
  /*  const location = useLocation(); */

  const currentLevel = window.location.hostname.split(".")[0];
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="login-logo" id="titleBox">
          <a href="#">
            <b id="siteName">
              {APP_NAME} {currentLevel?.toLocaleUpperCase()}
            </b>
          </a>
        </div>
        {/* /.login-logo */}
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <div autoComplete="off" method="POST">
              <input
                type="hidden"
                name="_csrf"
                defaultValue="ff657c4c-9076-42d6-a6a9-d0081ce8c3bb"
              />
              <div className="input-group mb-3 margin-top">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="username"
                  placeholder="username"
                  required="required"
                  autofocus="autofocus"
                  id="username"
                  name="username"
                  onChange={
                    (e) => setUsername(e.target.value)
                    // this.setState({ username: e.target.value })
                  }
                  defaultValue=""
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  required="required"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  defaultValue=""
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input
                      type="checkbox"
                      id="remember_me"
                      name="remember_me"
                    />
                    <label htmlFor="remember_me">Remember Me</label>
                  </div>
                </div>
              </div>
              <div className="social-auth-links text-center mb-3">
                <button
                  onClick={login}
                  type="submit"
                  name="submit"
                  className="form-control btn btn-info btn-block"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
      {/* /.login-box */}
      {/* jQuery */}
      {/* Bootstrap 4 */}
      {/* AdminLTE App */}
    </div>
  );
}
export default LoginPage;
