import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getChildrenByEventId,
  getMatchAndSessionBetsAPI,
} from "../service/AuthService";
import moment from "moment";
import Select from "react-select";
export default function DisplayMatchSession() {
  const location = useLocation();
  const { id } = useParams();
  const [client, setClient] = useState([]);
  const [clients, setClients] = useState([]);
  const [data, setData] = useState([]);
  const [aggregate, setAggregate] = useState({
    match: {
      amount: 0,
      a: 0,
      b: 0,
    },
    session: {
      amount: 0,
      a: 0,
      b: 0,
    },
  });
  const getMatchAndSessionBets = async (clientId) => {
    const { response } = await getMatchAndSessionBetsAPI(clientId, id);
    setData(response);
    let uniqueRunnerNames = Array.from(
      new Set(response?.bookmakerBets?.map((item) => item?.runnerName))
    );
    setAggregate({
      match: {
        amount: response?.bookmakerBets?.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        a: response?.bookmakerBets
          ?.filter((item) => item?.runnerName == uniqueRunnerNames[0])
          ?.reduce((acc, item) => acc + item.upperLineExposure[0].exposure, 0),
        b: response?.bookmakerBets
          ?.filter((item) => item?.runnerName == uniqueRunnerNames[1])
          .reduce((acc, item) => acc + item.upperLineExposure[1].exposure, 0),
      },
      session: {
        amount: response?.fancyBets?.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        a: response?.fancyBets
          ?.filter((item) => item.back)
          .reduce((acc, item) => acc + item.amount, 0),
        b: response?.fancyBets
          ?.filter((item) => !item.back)
          .reduce((acc, item) => acc + item.amount, 0),
      },
    });
  };
  const getClients = async () => {
    const { response } = await getChildrenByEventId(id, true);
    setClients(response?.children);
  };
  useEffect(() => {
    getClients();
  }, [id]);
  return (
    <div className="content-wrapper" style={{ minHeight: "1263.44px" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match &amp; Session Bet Details MatchCode : {id}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  Match &amp; Session Bet Details
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <form id="myForm" method="post">
                  <input
                    type="hidden"
                    name="_csrf"
                    defaultValue="d180f2fd-dc7e-4c26-831e-51583ece0679"
                  />
                  <div className="card-header ">
                    <div className="form-row col-md-9">
                      <div className="form-group col-md-4">
                        <label htmlFor="name">Client</label>
                        <Select
                          required=""
                          id="name"
                          placeholder="Select Client"
                          name="client"
                          data-select2-id="name"
                          tabIndex={-1}
                          aria-hidden="true"
                          options={clients?.map((item, index) => ({
                            value: item.uid,
                            label: item.name,
                          }))}
                          onChange={(e) => {
                            getMatchAndSessionBets(e.value);
                          }}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label
                          className="control-label text-purple"
                          htmlFor="btn"
                        >
                          `
                        </label>
                        <button
                          type="button"
                          className="form-control btn-primary"
                          id="btn"
                          name="button"
                        >
                          Show
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    {data?.length == 0 ? (
                      <div className="alert alert-warning">
                        <h6>No Record Found</h6>
                      </div>
                    ) : (
                      <div className="card-body">
                        <div>
                          <div className="form-row">
                            <div className="col-md-6">
                              <table className="table table-responsive table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th
                                      className="text-center bg-secondary"
                                      colSpan={8}
                                    >
                                      MATCH BETS
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>#</th>
                                    <th>Rate</th>
                                    <th>Mode</th>
                                    <th>Team</th>
                                    <th>Amount</th>
                                    {Array.from(
                                      new Set(
                                        data?.bookmakerBets?.map(
                                          (item) => item?.runnerName
                                        )
                                      )
                                    ).map((item) => (
                                      <th>{item}</th>
                                    ))}
                                    <th>Date &amp; Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.bookmakerBets?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.rate}</td>
                                      <td>{item.back ? "LAGAI" : "KHAI"}</td>
                                      <td>{item.runnerName}</td>
                                      <td>{item.amount}</td>
                                      {item?.upperLineExposure?.map(
                                        (_, index) => (
                                          <td key={index}>{_.exposure}</td>
                                        )
                                      )}
                                      <td>
                                        {moment(item.placeDate + "Z").format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tbody>
                                  <tr>
                                    <th className="text-center" colSpan={3}>
                                      {" "}
                                    </th>
                                    <th className="text-center">Minus</th>
                                    <th
                                      className={
                                        aggregate?.match?.amount < 0
                                          ? "text-red"
                                          : "text-green"
                                      }
                                    >
                                      {aggregate?.match?.amount}
                                    </th>
                                    <th className="text-red">
                                      {aggregate?.match?.a}
                                    </th>
                                    <th className="text-red">
                                      {aggregate?.match?.b}
                                    </th>
                                    <th />
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-md-6">
                              <table className="table table-responsive table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th
                                      className="text-center bg-secondary"
                                      colSpan={8}
                                    >
                                      SESSION BETS
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>#</th>
                                    <th>Session</th>
                                    <th>Rate</th>
                                    <th>Run</th>
                                    <th>Mode</th>
                                    <th>Amount</th>
                                    <th>Dec</th>
                                    <th>Date &amp; Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.fancyBets?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.runnerName}</td>
                                      <td>{item.rate}</td>
                                      <td>{item.run}</td>
                                      <td>{item.back ? "Yes" : "No"}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.winner}</td>
                                      <td>
                                        {moment(item.placeDate + "Z").format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th colSpan={5} className="text-center">
                                      Session Plus
                                    </th>
                                    <th colSpan={3} className="text-blue">
                                      0
                                    </th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* /.card-body */}
                </form>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
