import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getCommissionReportByUserAPI } from "../service/AuthService";

export default function CommissionLenaDenaUser() {
  const location = useLocation();
  const params = useParams();
  const [data, setData] = React.useState([]); // [
  const [payload, setPayload] = React.useState({
    startDate: new Date(moment().subtract("d", 7)).getTime(),
    endDate: new Date(moment().add("d", 1)).getTime(),
    page: 0,
    userId: params.id,
  });
  const [paginationData, setPaginationData] = React.useState({
    page: 0,
  });

  const getCommissionReportUser = async () => {
    const { response, code } = await getCommissionReportByUserAPI(payload);
    if (code === 200) {
      setData(response?.commissionReport?.content);
      setPaginationData({
        page: response?.commissionReport?.pageable?.pageNumber,
        size: response?.commissionReport?.pageable?.pageSize,
        totalElements: response?.commissionReport?.totalElements,
        totalPages: response?.commissionReport?.totalPages,
      });
    }
  };
  useEffect(() => {
    getCommissionReportUser();
  }, [payload]);
  return (
    <div className="content-wrapper">
      <section id="">
        <section className="vbox">
          <section className="scrollable padder">
            <section className="panel panel-default">
              <div
                className="ibox-title"
                style={{ background: "#004660", color: "#fff" }}
              >
                <h5>Commission Len Den</h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="content_bg x_panel">
                    <div
                      className="col-lg-3 col-xs-6"
                      style={{ marginBottom: 5 }}
                    >
                      <label style={{ width: "100%" }}>
                        Start Date
                        <input
                          value={moment(payload.startDate).format(
                            "YYYY-MM-DD "
                          )}
                          type="date"
                          id="startDate"
                          className="input-sm input-s form-control ng-untouched ng-pristine ng-valid"
                          style={{ width: "100%", background: "#d6e8d1" }}
                        />
                      </label>
                    </div>
                    <div
                      className="col-lg-3 col-xs-6"
                      style={{ marginBottom: 5 }}
                    >
                      <label style={{ width: "100%" }}>
                        End Date
                        <input
                          value={moment(payload.endDate).format("YYYY-MM-DD ")}
                          type="date"
                          id="endDate"
                          className="input-sm input-s form-control ng-untouched ng-pristine ng-valid"
                          style={{ width: "100%", background: "#d6e8d1" }}
                        />
                      </label>
                    </div>
                    <div
                      className="col-lg-3 col-xs-6"
                      style={{ marginBottom: 5 }}
                    >
                      <label style={{ width: "100%" }}>
                        Action
                        <br />
                        <button
                          onClick={() => getCommissionReportUser()}
                          type="button"
                          className="btn btn-success"
                          style={{
                            fontWeight: "bold",
                            border: "2px solid #000",
                          }}
                          onclick="gamesList(1)"
                        >
                          Show Report
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-lg-12 col-xs-12" style={{ padding: 0 }}>
                    <div
                      className="col-lg-12 col-xs-12"
                      style={{
                        border: "1px solid #e7eaec",
                        background:
                          "linear-gradient(to right,#2f5b69 0,#47889e 100%) !important",
                        color: "#fff",
                        padding: 7,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    />
                    <div className="table-responsive">
                      <table
                        style={{ marginBottom: 0 }}
                        className="table table-bordered table-striped dataTables-example dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_disabled tabledarkblue"
                              colSpan={2}
                            />
                            <th
                              className="sorting_disabled tablelightblue"
                              colSpan={4}
                              style={{ textAlign: "Center" }}
                            >
                              Mila Hai
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              colSpan={4}
                              style={{ textAlign: "Center" }}
                            >
                              Dena Hai
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              Date
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              Match Name
                            </th>
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              M.Comm
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              S.Comm
                            </th>
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              C.Comm.
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              T.Comm.
                            </th>
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              M.Comm.
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              S.Comm
                            </th>
                            <th
                              className="sorting_disabled tablelightblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              C.Comm
                            </th>
                            <th
                              className="sorting_disabled tabledarkblue"
                              rowSpan={1}
                              colSpan={1}
                              style={{}}
                            >
                              T.Comm
                            </th>
                          </tr>
                        </thead>
                        <tbody id="setresult">
                          {data?.map((item) => (
                            <tr className="odd">
                              <td>
                                {moment(item?.createdAt + "Z").format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td className="text-success">
                                {item?.eventName}
                              </td>
                              <td className="text-success">
                                {item?.agentMatchCommission}
                              </td>
                              <td className="text-success">
                                {item?.agentSessionCommission}
                              </td>
                              <td className="text-success">
                                {item?.agentCasinoCommission}
                              </td>
                              <td className="text-success">
                                {item?.agentMatchCommission +
                                  item?.agentSessionCommission +
                                  item?.agentCasinoCommission}
                              </td>
                              <td className="text-success">
                                {item?.clientMatchCommission}
                              </td>
                              <td className="text-success">
                                {item?.clientSessionCommission}
                              </td>
                              <td className="text-success">
                                {item?.clientCasinoCommission}
                              </td>
                              <td className="text-success">
                                {item?.clientMatchCommission +
                                  item?.clientSessionCommission +
                                  item?.clientCasinoCommission}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              className="tablelightblue"
                              colSpan={2}
                              style={{ textAlign: "center" }}
                            >
                              Total
                            </td>
                            <td className="text-success" id="mComd">
                              {data?.reduce(
                                (acc, item) => acc + item?.agentMatchCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="fComd">
                              {data?.reduce(
                                (acc, item) =>
                                  acc + item?.agentSessionCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="cComd">
                              {data?.reduce(
                                (acc, item) =>
                                  acc + item?.agentCasinoCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="tComd">
                              {data?.reduce(
                                (acc, item) =>
                                  acc +
                                  item?.agentMatchCommission +
                                  item?.agentSessionCommission +
                                  item?.agentCasinoCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="mCom">
                              {data?.reduce(
                                (acc, item) =>
                                  acc + item?.clientMatchCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="fCom">
                              {data?.reduce(
                                (acc, item) =>
                                  acc + item?.clientSessionCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="cCom">
                              {data?.reduce(
                                (acc, item) =>
                                  acc + item?.clientCasinoCommission,
                                0
                              )}
                            </td>
                            <td className="text-success" id="tCom">
                              {data?.reduce(
                                (acc, item) =>
                                  acc +
                                  item?.clientMatchCommission +
                                  item?.clientSessionCommission +
                                  item?.clientCasinoCommission,
                                0
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="pull-right">
                    <div
                      className="dataTables_paginate paging_simple_numbers col-sm-12 col-xs-12 nopadmobile"
                      id="DataTables_Table_0_paginate"
                    >
                      <ul className="pagination" id="paginationList">
                        <li
                          onClick={() =>
                            setPayload({
                              ...payload,
                              page: 0,
                            })
                          }
                          role="presentation"
                          aria-hidden="true"
                          className="page-item disabled"
                        >
                          <span
                            role="menuitem"
                            aria-label="Go to first page"
                            aria-disabled="true"
                            className="page-link"
                          >
                            <i className="fa fa-caret-left" />
                            <i className="fa fa-caret-left" />
                          </span>
                        </li>
                        <li
                          onClick={() =>
                            setPayload({
                              ...payload,
                              page: paginationData.page - 1,
                            })
                          }
                          role="presentation"
                          aria-hidden="true"
                          className="page-item disabled"
                        >
                          <span
                            role="menuitem"
                            aria-label="Go to previous page"
                            aria-disabled="true"
                            className="page-link"
                          >
                            <i className="fa fa-caret-left" />
                          </span>
                        </li>
                        <li role="presentation" className="page-item active">
                          <button
                            role="menuitemradio"
                            type="button"
                            aria-label="Go to page 1"
                            aria-checked="true"
                            aria-posinset={1}
                            aria-setsize={1}
                            tabIndex={0}
                            className="page-link"
                          >
                            {paginationData.page + 1}
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            setPayload({
                              ...payload,
                              page: paginationData.page + 1,
                            })
                          }
                          role="presentation"
                          aria-hidden="true"
                          className="page-item disabled"
                        >
                          <span
                            role="menuitem"
                            aria-label="Go to next page"
                            aria-disabled="true"
                            className="page-link"
                          >
                            <i className="fa fa-caret-right" />
                          </span>
                        </li>
                        <li
                          onClick={() =>
                            setPayload({
                              ...payload,
                              page: paginationData.page + 1,
                            })
                          }
                          role="presentation"
                          aria-hidden="true"
                          className="page-item disabled"
                        >
                          <span
                            role="menuitem"
                            aria-label="Go to last page"
                            aria-disabled="true"
                            className="page-link"
                          >
                            <i className="fa fa-caret-right" />
                            <i className="fa fa-caret-right" />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
