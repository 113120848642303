import React, { useEffect, useState } from "react";
import { getClientAPI } from "../service/UserService";
import { getUsersWithUserTypeAPI } from "../service/UserService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  bulkActiveDeactiveAPI,
  getCurrentBetsAPI,
  updateCommissionAPI,
  updateUserStatusAPI,
  currentBetsAPI,
  unSettledBetsAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import store from "../redux/store";
import { PERMISSION_MAP } from "../utils/constants";
import { Spinner } from "@chakra-ui/react";
import moment from "moment";
import { useQuery } from "react-query";
import { Modal } from "react-bootstrap";
export default function MyClients() {
  const [search, setSearch] = useState("");
  const [userTypeName, setUserType] = useState("");
  const [clientData, setClientData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [userTypeList, setGetUsersWithUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(50);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [userId, setGetUserId] = useState("");
  const userData = store.getState().account.userData;
  const location = useLocation();
  const { userType } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(location?.state?.userName || "");
  const [checkedItems, setCheckedItems] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [exposureTableModal, setExposureTableModal] = useState();
  const [userUnsettledBets, setUserUnsettledBets] = useState([]);
  const [showDetails, setShowDetails] = useState(
    Array(clientData?.childrenUserResponseDTOS?.length).fill(false)
  );
  const getUnsettledBetByUsername = async (username) => {
    const {
      response: { content },
    } = await unSettledBetsAPI({
      pageSize: 1000,
      pageNumber: 0,

      betStatus: "MATCHED",
    });
    setUserUnsettledBets(content?.filter((item) => item.userName == username));
  };

  const toggleDetails = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileUI(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUsersWithUserType = async () => {
    let userTypeGet;
    if (userType?.toUpperCase() === "SUB_ADMIN") {
      userTypeGet = "ADMIN";
    } else if (userType?.toUpperCase() === "MASTER") {
      userTypeGet = "SUB_ADMIN";
    } else if (userType?.toUpperCase() === "SUPER_AGENT") {
      userTypeGet = "MASTER";
    } else if (userType?.toUpperCase() === "AGENT") {
      userTypeGet = "SUPER_AGENT";
    } else if (userType?.toUpperCase() === "CLIENT") {
      userTypeGet = "AGENT";
    }
    setUserType(userTypeGet);
    const { response } = await getUsersWithUserTypeAPI(userTypeGet);
    let response_ = response;
    if (response_) {
      setGetUsersWithUsers(response_);
    }
  };
  const handleAllStatus = async (status) => {
    try {
      setIsLoading(true);
      const { response, code } = await bulkActiveDeactiveAPI(
        status,
        checkedItems.length == 0 ? userType?.toLocaleUpperCase() : null,
        checkedItems
      );
      if (code == 200) {
        getClient();
        window.location.reload();
        toast.success(`All ${userType} status changed`);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const handleActiveInactive = async (userData) => {
    const { response, code } = await updateUserStatusAPI(
      userData?.uid,
      !userData?.isActive,
      userData?.isBetLocked
    );
    if (code == 200) {
      getClient();
      window.location.reload();
      toast.success(`User status changed`);
    }
  };
  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      id,
      userType?.toUpperCase(),
      location?.state?.blocked,
      isActive != null ? isActive : null
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS = response_.childrenUserResponseDTOSPage.content.sort(
        (a, b) => a.uid - b.uid
      );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    setClientData(response_);
  };

  const navigate = useNavigate();
  useEffect(() => {
    getClient();
    getUsersWithUserType();
  }, [
    location.state,
    location.search,
    location.pathname,
    size,
    currentPage,
    isActive,
    search,
  ]);

  const handleSelectChangeUser = (e) => {
    const userID = e.target.value;
    setGetUserId(userID);
  };
  useEffect(() => {
    setShowDetails(
      Array(clientData?.childrenUserResponseDTOS?.length).fill(false)
    );
  }, [userType]);
  const shareDetails = (client) => {
    let url = `https://api.whatsapp.com/send?text=${userType?.replace(
      "_",
      ""
    )} : ${client?.username}\nname: ${client?.name}\nPassword : ${
      client?.password
    }\nshare: ${client?.myPartnership}${
      client?.userType?.toLowerCase() == "client"
        ? `\nlink: https://${process.env?.REACT_APP_NAME}.in`
        : client?.userType?.toLowerCase() == "sub_admin"
        ? `\nlink: https://sub.${process.env?.REACT_APP_NAME}.in`
        : `\nlink: https://${userType?.toLowerCase()?.replace("_", "")}.${
            process.env?.REACT_APP_NAME
          }.in`
    }`;
    window.open(url, "_blank");
  };
  return (
    <div className="content-wrapper">
      <Modal
        size="xl"
        onHide={() => setExposureTableModal(false)}
        show={exposureTableModal}
      >
        <Modal.Header
          style={{
            backgroundColor: "#f5f5f5",
          }}
          closeButton
        >
          <h4
            style={{
              color: "black",
            }}
          >
            My Market
          </h4>
        </Modal.Header>
        <Modal.Body
          style={{
            overflowX: "auto",
            width: "auto",
          }}
        >
          <table className="table table-stripped">
            <thead>
              <tr
                style={{
                  color: "black",
                }}
              >
                <th>Event Type</th>
                <th>Event Name</th>
                <th>Market Name</th>
                <th>Runner Name</th>
                <th>Mode</th>
                <th>Rate</th>
                <th>Run</th>
                <th>Amount</th>
                <th>Place Date</th>
              </tr>
            </thead>
            <tbody style={{ color: "black" }}>
              {userUnsettledBets?.map((item) => (
                <tr
                  style={{
                    backgroundColor: item?.lay_rate
                      ? "lightpink !important"
                      : "lightblue !important",
                  }}
                >
                  <td>{item?.event_type}</td>
                  <td>{item?.event_name}</td>
                  <td>{item?.marketName}</td>
                  <td>{item?.runner_name}</td>
                  <td>
                    {item?.marketName?.startsWith("BOOKMAKER")
                      ? item?.lay_rate
                        ? "KHAI"
                        : "LAGAI"
                      : item?.back_rate
                      ? "YES"
                      : "NO"}
                  </td>
                  <td>{item?.lay_rate ? item?.lay_rate : item?.back_rate}</td>
                  <td>{item?.lay_run ? item?.lay_run : item?.back_run}</td>
                  <td>{item?.amount}</td>
                  <td>
                    {moment(item?.created_date + "Z").format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{userType?.toUpperCase()} LIST</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  {userType?.toUpperCase()} LIST
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div method="post" id="demoForm">
                  <div className="card-header">
                    <div className="form-group">
                      {(userData?.userType === "SUPER_ADMIN" &&
                        userType.toUpperCase() === "ADMIN") ||
                      (userData?.userType === "ADMIN" &&
                        userType.toUpperCase() === "SUB_ADMIN") ||
                      (userData?.userType === "SUB_ADMIN" &&
                        userType.toUpperCase() === "MASTER") ||
                      (userData?.userType === "MASTER" &&
                        userType.toUpperCase() === "SUPER_AGENT") ||
                      (userData?.userType === "SUPER_AGENT" &&
                        userType.toUpperCase() === "AGENT") ||
                      (userData?.userType === "AGENT" &&
                        userType.toUpperCase() === "CLIENT") ? (
                        <Link
                          to={`/create/new/${userType}`}
                          type="button"
                          className="btn btn-primary"
                        >
                          New <i className="fa fa-plus-circle" />
                        </Link>
                      ) : (
                        <button
                          data-toggle="modal"
                          data-target="#modal-default"
                          className="btn btn-primary"
                        >
                          New <i className="fa fa-plus-circle" />
                        </button>
                      )}

                      <button
                        className="btn btn-success"
                        id="allActive"
                        type="submit"
                        name="check"
                        disabled={isLoading}
                        value={1}
                        onClick={() => handleAllStatus(true)}
                      >
                        All Active
                      </button>
                      <button
                        className="btn btn-danger"
                        id="allInActive"
                        type="submit"
                        disabled={isLoading}
                        name="check"
                        value={0}
                        onClick={() => handleAllStatus(false)}
                      >
                        All Deactive {<i className="fa fa-ban" />}
                      </button>
                      {(userData?.userType === "SUPER_ADMIN" &&
                        userType?.toUpperCase() === "ADMIN") ||
                      (userData?.userType === "ADMIN" &&
                        userType?.toUpperCase() === "SUB_ADMIN") ||
                      (userData?.userType === "SUB_ADMIN" &&
                        userType?.toUpperCase() === "MASTER") ||
                      (userData?.userType === "MASTER" &&
                        userType?.toUpperCase() === "SUPER_AGENT") ||
                      (userData?.userType === "SUPER_AGENT" &&
                        userType?.toUpperCase() === "AGENT") ||
                      (userData?.userType === "AGENT" &&
                        userType?.toUpperCase() === "CLIENT") ? (
                        <>
                          <Link
                            to={`/update_limit/${userType}`}
                            type="button"
                            className="btn btn-primary"
                          >
                            Limit Update
                          </Link>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="card-body">
                    <div
                      id="example1_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="col-sm-12 col-md-6">
                            <div
                              className="dataTables_length"
                              id="example1_length"
                            >
                              <label>
                                Show{" "}
                                <select
                                  name="example1_length"
                                  aria-controls="example1"
                                  className="custom-select custom-select-sm form-control form-control-sm"
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>{" "}
                                entries
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <div
                            id="example1_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              Search:
                              <input
                                onChange={(e) => setSearch(e.target.value)}
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-controls="example1"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          {userType == "client" ? (
                            <>
                              {!isMobileUI ? (
                                <>
                                  <table
                                    className="table table-bordered table-striped dataTables-example dataTable no-footer"
                                    id="example1"
                                    role="grid"
                                    aria-describedby="example1_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{
                                            width: 43,
                                          }}
                                        >
                                          #
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          CODE
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Name
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Agent
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Mobile
                                        </th>

                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Password
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Limit
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Share
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Match
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Session
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Charge
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {clientData?.childrenUserResponseDTOS?.map(
                                        (client, index) => (
                                          <tr
                                            role="row"
                                            className="odd"
                                            key={index}
                                          >
                                            <td>
                                              {index + 1}
                                              &nbsp;&nbsp;
                                              <div className="btn-group">
                                                <button
                                                  type="button"
                                                  className="btn btn-xs btn-outline-secondary dropdown-toggle dropdown-hover dropdown-icon"
                                                  data-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <span className="sr-only">
                                                    Toggle Dropdown
                                                  </span>
                                                </button>
                                                <div
                                                  className="dropdown-menu"
                                                  role="menu"
                                                  style={{}}
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                      navigate(
                                                        `/edit/${
                                                          client.uid
                                                        }/${userType}`,
                                                        {
                                                          state: {
                                                            client,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </a>

                                                  <span>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() => {
                                                        handleActiveInactive(
                                                          client
                                                        );
                                                      }}
                                                    >
                                                      {client?.isActive
                                                        ? "InActive"
                                                        : "Active"}
                                                    </a>
                                                  </span>
                                                  {
                                                    <a
                                                      onClick={() =>
                                                        navigate(
                                                          `/update/limit/${userType}`,
                                                          {
                                                            state: {
                                                              client,
                                                            },
                                                          }
                                                        )
                                                      }
                                                      type="button"
                                                      className="dropdown-item"
                                                    >
                                                      Limit Add/Minus
                                                    </a>
                                                  }

                                                  <a
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                      shareDetails(client)
                                                    }
                                                    target="_blank"
                                                  >
                                                    Share Details{" "}
                                                    <i
                                                      style={{
                                                        color: "green",
                                                      }}
                                                      class="fa fa-whatsapp"
                                                      aria-hidden="true"
                                                    />
                                                  </a>
                                                  <a
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                      navigate(
                                                        `/report/account_statement/${
                                                          client.uid
                                                        }`,
                                                        {
                                                          state: {
                                                            userName:
                                                              client.username,
                                                            uid: client.uid,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  >
                                                    Statement
                                                  </a>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              {client.username} <br />
                                              <div
                                                onClick={() => {
                                                  setExposureTableModal(
                                                    client
                                                  );
                                                  getUnsettledBetByUsername(
                                                    client?.username
                                                  );
                                                }}
                                              >
                                                EXP:{" "}
                                                {Math.abs(client?.liability)}
                                              </div>
                                              &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>{client.name}</td>
                                            <td>{client.parentUserName}</td>
                                            <td>{client.mobile}</td>

                                            <td>{client.password}</td>
                                            <td>
                                              {parseFloat(
                                                client.balance +
                                                  client.liability
                                              ).toFixed(2)}
                                            </td>
                                            <td>{client.myPartnership}</td>
                                            <td className="">
                                              {client.matchCommission}
                                            </td>
                                            <td className="">
                                              {client.sessionCommission}
                                            </td>
                                            <td className="">100</td>
                                            <td className="">
                                              {client.isActive == true ? (
                                                <span class="float-center badge bg-success">
                                                  Active
                                                </span>
                                              ) : (
                                                <span class="float-center badge bg-danger">
                                                  Deactive
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <>
                                  <table
                                    className="mobile-view-table table table-bordered table-striped dataTables-example dataTable no-footer"
                                    id="example1"
                                    role="grid"
                                    aria-describedby="example1_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{
                                            width: 43,
                                          }}
                                        >
                                          <div align="center">
                                            <input
                                              type="checkbox"
                                              name="all"
                                              id="all"
                                              checked={
                                                checkedItems?.length ==
                                                clientData
                                                  ?.childrenUserResponseDTOS
                                                  ?.length
                                              }
                                              defaultValue={1}
                                              onChange={() => {
                                                if (
                                                  checkedItems.length ==
                                                  clientData
                                                    .childrenUserResponseDTOS
                                                    .length
                                                ) {
                                                  setCheckedItems([]);
                                                } else {
                                                  setCheckedItems(
                                                    clientData?.childrenUserResponseDTOS?.map(
                                                      (client, index) =>
                                                        client.uid
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{
                                            width: 43,
                                          }}
                                        >
                                          #
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          CODE
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {clientData?.childrenUserResponseDTOS?.map(
                                        (client, index) => (
                                          <>
                                            <tr
                                              role="row"
                                              className="odd"
                                              key={index}
                                            >
                                              <td>
                                                <div
                                                  className="position-relative d-flex align-items-center"
                                                  style={{
                                                    gap: "10px",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className={`plus_button position-relative ${
                                                      showDetails &&
                                                      showDetails[index]
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      toggleDetails(index)
                                                    }
                                                  />
                                                  <div align="center">
                                                    <input
                                                      name={`checkbox-${index}`}
                                                      checked={checkedItems?.find(
                                                        (item) =>
                                                          item == client.uid
                                                      )}
                                                      onChange={() =>
                                                        setCheckedItems(
                                                          (prev) => {
                                                            if (
                                                              prev.includes(
                                                                client.uid
                                                              )
                                                            ) {
                                                              let index = prev.indexOf(
                                                                client.uid
                                                              );
                                                              delete prev[
                                                                index
                                                              ];
                                                            } else {
                                                              prev.push(
                                                                client.uid
                                                              );
                                                            }
                                                            return prev;
                                                          }
                                                        )
                                                      }
                                                      type="checkbox"
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="position-relative">
                                                <div className="d-flex gap-3">
                                                  {index + 1}
                                                  &nbsp;&nbsp;&nbsp;
                                                  <div className="btn-group">
                                                    <button
                                                      type="button"
                                                      className="btn btn-xs btn-outline-secondary dropdown-toggle dropdown-hover dropdown-icon"
                                                      data-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <span className="sr-only">
                                                        Toggle Dropdown
                                                      </span>
                                                    </button>
                                                    <div
                                                      className="dropdown-menu"
                                                      role="menu"
                                                      style={{}}
                                                    >
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          navigate(
                                                            `/edit/${
                                                              client.uid
                                                            }/${userType}`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </a>

                                                      <span>
                                                        <a
                                                          className="dropdown-item"
                                                          onClick={() => {
                                                            handleActiveInactive(
                                                              client
                                                            );
                                                          }}
                                                        >
                                                          {client?.isActive
                                                            ? "InActive"
                                                            : "Active"}
                                                        </a>
                                                      </span>
                                                      {
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/${userType}`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Limit Add/Minus
                                                        </a>
                                                      }
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          shareDetails(client)
                                                        }
                                                        target="_blank"
                                                      >
                                                        Share Details{" "}
                                                        <i
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          class="fa fa-whatsapp"
                                                          aria-hidden="true"
                                                        />
                                                      </a>
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          navigate(
                                                            `/report/account_statement/${
                                                              client.uid
                                                            }`,
                                                            {
                                                              state: {
                                                                userName:
                                                                  client.username,
                                                                uid:
                                                                  client.uid,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        Statement
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                {client.username}
                                                <br />
                                                <div
                                                  onClick={() => {
                                                    setExposureTableModal(
                                                      client
                                                    );
                                                    getUnsettledBetByUsername(
                                                      client?.username
                                                    );
                                                  }}
                                                >
                                                  EXP:{" "}
                                                  {Math.abs(
                                                    client?.liability
                                                  )}
                                                </div>
                                              </td>
                                              <td>{client.name}</td>
                                            </tr>
                                            {showDetails &&
                                              showDetails[index] && (
                                                <tr className="child">
                                                  <td colSpan={5}>
                                                    <div>
                                                      <ul
                                                        data-dtr-index={0}
                                                        className="dtr-details"
                                                      >
                                                        <li
                                                          data-dtr-index={5}
                                                          data-dt-row={0}
                                                          data-dt-column={5}
                                                        >
                                                          <span className="dtr-title">
                                                            Agent
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.parentUserName
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={5}
                                                          data-dt-row={0}
                                                          data-dt-column={5}
                                                        >
                                                          <span className="dtr-title">
                                                            Mobile
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {client.mobile}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={5}
                                                          data-dt-row={0}
                                                          data-dt-column={5}
                                                        >
                                                          <span className="dtr-title">
                                                            Password
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {client.password}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={6}
                                                          data-dt-row={0}
                                                          data-dt-column={6}
                                                        >
                                                          <span className="dtr-title">
                                                            Limit
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {parseFloat(
                                                              client.balance +
                                                                client.liability
                                                            ).toFixed(2)}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={6}
                                                          data-dt-row={0}
                                                          data-dt-column={6}
                                                        >
                                                          <span className="dtr-title">
                                                            Casino Share
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {parseFloat(
                                                              client.myCasinoPartnership
                                                            ).toFixed(2)}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={6}
                                                          data-dt-row={0}
                                                          data-dt-column={6}
                                                        >
                                                          <span className="dtr-title">
                                                            Casino Commission
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {parseFloat(
                                                              client.casinoCommission
                                                            ).toFixed(2)}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={9}
                                                          data-dt-row={0}
                                                          data-dt-column={9}
                                                        >
                                                          <span className="dtr-title">
                                                            Share
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.myPartnership
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={7}
                                                          data-dt-row={0}
                                                          data-dt-column={7}
                                                        >
                                                          <span className="dtr-title">
                                                            Match
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.matchCommission
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={8}
                                                          data-dt-row={0}
                                                          data-dt-column={8}
                                                        >
                                                          <span className="dtr-title">
                                                            Session
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.sessionCommission
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={10}
                                                          data-dt-row={0}
                                                          data-dt-column={10}
                                                        >
                                                          <span className="dtr-title">
                                                            Status
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {client.isActive ==
                                                            true ? (
                                                              <span class="float-center badge bg-success">
                                                                Active
                                                              </span>
                                                            ) : (
                                                              <span class="float-center badge bg-danger">
                                                                Deactive
                                                              </span>
                                                            )}
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                          </>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {!isMobileUI ? (
                                <>
                                  <table
                                    className="desk-view-table table table-bordered table-striped dataTables-example dataTable no-footer"
                                    id="example1"
                                    role="grid"
                                    aria-describedby="example1_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{
                                            width: 43,
                                          }}
                                        >
                                          #
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          CODE
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Name
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Mobile
                                        </th>

                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Password
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Limit
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Share
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Match
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Session
                                        </th>

                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {clientData?.childrenUserResponseDTOS?.map(
                                        (client, index) => (
                                          <tr
                                            role="row"
                                            className="odd"
                                            key={index}
                                          >
                                            <td>
                                              <div className="d-flex gap-3">
                                                {index + 1}
                                                &nbsp;&nbsp;&nbsp;
                                                <div className="btn-group">
                                                  <button
                                                    type="button"
                                                    className="btn btn-xs btn-outline-secondary dropdown-toggle dropdown-hover dropdown-icon"
                                                    data-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <span className="sr-only">
                                                      Toggle Dropdown
                                                    </span>
                                                  </button>
                                                  <div
                                                    className="dropdown-menu"
                                                    role="menu"
                                                    style={{}}
                                                  >
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        navigate(
                                                          `/edit/${
                                                            client.uid
                                                          }/${userType}`,
                                                          {
                                                            state: {
                                                              client,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </a>
                                                    <span>
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() => {
                                                          handleActiveInactive(
                                                            client
                                                          );
                                                        }}
                                                      >
                                                        {client?.isActive
                                                          ? "InActive"
                                                          : "Active"}
                                                      </a>
                                                    </span>

                                                    {userType?.toUpperCase() ===
                                                    "ADMIN" ? (
                                                      <a
                                                        onClick={() =>
                                                          navigate(
                                                            `/update/limit/SUB_ADMIN`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        type="button"
                                                        className="dropdown-item"
                                                      >
                                                        Sub Admin Limit Update
                                                      </a>
                                                    ) : userType?.toUpperCase() ===
                                                      "SUB_ADMIN" ? (
                                                      <a
                                                        onClick={() =>
                                                          navigate(
                                                            `/update/limit/MASTER`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        type="button"
                                                        className="dropdown-item"
                                                      >
                                                        Master Limit Update
                                                      </a>
                                                    ) : userType?.toUpperCase() ===
                                                      "MASTER" ? (
                                                      <a
                                                        onClick={() =>
                                                          navigate(
                                                            `/update/limit/SUPER_AGENT`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        type="button"
                                                        className="dropdown-item"
                                                      >
                                                        Super Agent Limit
                                                        Update
                                                      </a>
                                                    ) : userType?.toUpperCase() ===
                                                      "SUPER_AGENT" ? (
                                                      <a
                                                        onClick={() =>
                                                          navigate(
                                                            `/update/limit/AGENT`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        type="button"
                                                        className="dropdown-item"
                                                      >
                                                        Agent Limit Update
                                                      </a>
                                                    ) : userType?.toUpperCase() ===
                                                      "AGENT" ? (
                                                      <a
                                                        onClick={() =>
                                                          navigate(
                                                            `/update/limit/CLIENT`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        type="button"
                                                        className="dropdown-item"
                                                      >
                                                        Client Limit Update
                                                      </a>
                                                    ) : null}
                                                    {
                                                      <a
                                                        onClick={() =>
                                                          navigate(
                                                            `/update/limit/${userType}`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                        type="button"
                                                        className="dropdown-item"
                                                      >
                                                        Limit Add/Minus
                                                      </a>
                                                    }

                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        shareDetails(client)
                                                      }
                                                      target="_blank"
                                                    >
                                                      Share Details{" "}
                                                      <i
                                                        style={{
                                                          color: "green",
                                                        }}
                                                        class="fa fa-whatsapp"
                                                        aria-hidden="true"
                                                      />
                                                    </a>
                                                    <a
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        navigate(
                                                          `/report/account_statement/${
                                                            client.uid
                                                          }`,
                                                          {
                                                            state: {
                                                              userName:
                                                                client.username,
                                                              uid: client.uid,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      Statement
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td>{client.username}</td>
                                            <td>{client.name}</td>
                                            <td>{client.mobile}</td>
                                            <td>{client.password}</td>
                                            <td>
                                              {parseFloat(
                                                client.balance +
                                                  client.liability
                                              ).toFixed(2)}
                                            </td>

                                            <td>{client.myPartnership}</td>
                                            <td className="">
                                              {client.matchCommission}
                                            </td>
                                            <td className="">
                                              {client.sessionCommission}
                                            </td>

                                            <td className="">
                                              {client.isActive == true ? (
                                                <span class="float-center badge bg-success">
                                                  Active
                                                </span>
                                              ) : (
                                                <span class="float-center badge bg-danger">
                                                  Deactive
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              ) : (
                                <>
                                  <table
                                    className="mobile-view-table table table-bordered table-striped dataTables-example dataTable no-footer"
                                    id="example1"
                                    role="grid"
                                    aria-describedby="example1_info"
                                  >
                                    <thead>
                                      <tr role="row">
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{
                                            width: 43,
                                          }}
                                        >
                                          <div align="center">
                                            <input
                                              type="checkbox"
                                              name="all"
                                              id="all"
                                              defaultValue={1}
                                              checked={
                                                checkedItems?.length ==
                                                clientData
                                                  ?.childrenUserResponseDTOS
                                                  ?.length
                                              }
                                              onChange={() => {
                                                if (
                                                  checkedItems.length ==
                                                  clientData
                                                    .childrenUserResponseDTOS
                                                    .length
                                                ) {
                                                  setCheckedItems([]);
                                                } else {
                                                  setCheckedItems(
                                                    clientData?.childrenUserResponseDTOS?.map(
                                                      (client, index) =>
                                                        client.uid
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{
                                            width: 43,
                                          }}
                                        >
                                          #
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          CODE
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                        >
                                          Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {clientData?.childrenUserResponseDTOS?.map(
                                        (client, index) => (
                                          <>
                                            <tr
                                              role="row"
                                              className="odd"
                                              key={index}
                                            >
                                              <td>
                                                <div
                                                  className="position-relative d-flex align-items-center"
                                                  style={{
                                                    gap: "10px",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className={`plus_button position-relative ${
                                                      showDetails &&
                                                      showDetails[index]
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      toggleDetails(index)
                                                    }
                                                  />
                                                  <div align="center">
                                                    <input
                                                      name={`checkbox-${index}`}
                                                      checked={checkedItems?.find(
                                                        (item) =>
                                                          item == client.uid
                                                      )}
                                                      onChange={() =>
                                                        setCheckedItems(
                                                          (prev) => {
                                                            if (
                                                              prev.includes(
                                                                client.uid
                                                              )
                                                            ) {
                                                              let index = prev.indexOf(
                                                                client.uid
                                                              );
                                                              delete prev[
                                                                index
                                                              ];
                                                            } else {
                                                              prev.push(
                                                                client.uid
                                                              );
                                                            }
                                                            return prev;
                                                          }
                                                        )
                                                      }
                                                      type="checkbox"
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="position-relative">
                                                <div className="d-flex gap-3">
                                                  {index + 1}
                                                  &nbsp;&nbsp;&nbsp;
                                                  <div className="btn-group">
                                                    <button
                                                      type="button"
                                                      className="btn btn-xs btn-outline-secondary dropdown-toggle dropdown-hover dropdown-icon"
                                                      data-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <span className="sr-only">
                                                        Toggle Dropdown
                                                      </span>
                                                    </button>
                                                    <div
                                                      className="dropdown-menu"
                                                      role="menu"
                                                      style={{}}
                                                    >
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          navigate(
                                                            `/edit/${
                                                              client.uid
                                                            }/${userType}`,
                                                            {
                                                              state: {
                                                                client,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </a>
                                                      <span>
                                                        <a
                                                          className="dropdown-item"
                                                          onClick={() => {
                                                            handleActiveInactive(
                                                              client
                                                            );
                                                          }}
                                                        >
                                                          {client?.isActive
                                                            ? "InActive"
                                                            : "Active"}
                                                        </a>
                                                      </span>

                                                      {userType?.toUpperCase() ===
                                                      "ADMIN" ? (
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/SUB_ADMIN`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Sub Admin Limit
                                                          Update
                                                        </a>
                                                      ) : userType?.toUpperCase() ===
                                                        "SUB_ADMIN" ? (
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/MASTER`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Master Limit Update
                                                        </a>
                                                      ) : userType?.toUpperCase() ===
                                                        "MASTER" ? (
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/SUPER_AGENT`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Super Agent Limit
                                                          Update
                                                        </a>
                                                      ) : userType?.toUpperCase() ===
                                                        "SUPER_AGENT" ? (
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/AGENT`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Agent Limit Update
                                                        </a>
                                                      ) : userType?.toUpperCase() ===
                                                        "AGENT" ? (
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/CLIENT`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Client Limit Update
                                                        </a>
                                                      ) : null}
                                                      {
                                                        <a
                                                          onClick={() =>
                                                            navigate(
                                                              `/update/limit/${userType}`,
                                                              {
                                                                state: {
                                                                  client,
                                                                },
                                                              }
                                                            )
                                                          }
                                                          type="button"
                                                          className="dropdown-item"
                                                        >
                                                          Limit Add/Minus
                                                        </a>
                                                      }

                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          shareDetails(client)
                                                        }
                                                        target="_blank"
                                                      >
                                                        Share Details{" "}
                                                        <i
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          class="fa fa-whatsapp"
                                                          aria-hidden="true"
                                                        />
                                                      </a>
                                                      <a
                                                        className="dropdown-item"
                                                        onClick={() =>
                                                          navigate(
                                                            `/report/account_statement/${
                                                              client.uid
                                                            }`,
                                                            {
                                                              state: {
                                                                userName:
                                                                  client.username,
                                                                uid:
                                                                  client.uid,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        Statement
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>{client.username}</td>
                                              <td>{client.name}</td>
                                            </tr>
                                            {showDetails &&
                                              showDetails[index] && (
                                                <tr className="child">
                                                  <td colSpan={5}>
                                                    <div>
                                                      <ul
                                                        data-dtr-index={0}
                                                        className="dtr-details"
                                                      >
                                                        <li>
                                                          <span className="dtr-title">
                                                            Parent
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.parentUserName
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={5}
                                                          data-dt-row={0}
                                                          data-dt-column={5}
                                                        >
                                                          <span className="dtr-title">
                                                            Mobile
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {client.mobile}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={5}
                                                          data-dt-row={0}
                                                          data-dt-column={5}
                                                        >
                                                          <span className="dtr-title">
                                                            Password
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {client.password}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={6}
                                                          data-dt-row={0}
                                                          data-dt-column={6}
                                                        >
                                                          <span className="dtr-title">
                                                            Limit
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {parseFloat(
                                                              client.balance +
                                                                client.liability
                                                            ).toFixed(2)}
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={9}
                                                          data-dt-row={0}
                                                          data-dt-column={9}
                                                        >
                                                          <span className="dtr-title">
                                                            Share
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.myPartnership
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={7}
                                                          data-dt-row={0}
                                                          data-dt-column={7}
                                                        >
                                                          <span className="dtr-title">
                                                            Match
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.matchCommission
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={8}
                                                          data-dt-row={0}
                                                          data-dt-column={8}
                                                        >
                                                          <span className="dtr-title">
                                                            Session
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {
                                                              client.sessionCommission
                                                            }
                                                          </span>
                                                        </li>
                                                        <li
                                                          data-dtr-index={10}
                                                          data-dt-row={0}
                                                          data-dt-column={10}
                                                        >
                                                          <span className="dtr-title">
                                                            Status
                                                          </span>{" "}
                                                          <span className="dtr-data">
                                                            {client.isActive ==
                                                            true ? (
                                                              <span class="float-center badge bg-success">
                                                                Active
                                                              </span>
                                                            ) : (
                                                              <span class="float-center badge bg-danger">
                                                                Deactive
                                                              </span>
                                                            )}
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )}
                                          </>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5" />
                        <div className="col-sm-12 col-md-7" />
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                <div className="card-footer clearfix">
                  <div className="float-left">
                    <p>
                      Showing
                      {currentPage * size + 1} to{" "}
                      {currentPage * size +
                        clientData?.childrenUserResponseDTOS?.length}{" "}
                      of {totalElements} entries
                    </p>
                  </div>
                  <ul className="pagination pagination-sm m-0 float-right">
                    <div>
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => {
                            if (currentPage > 0) {
                              setCurrentPage(currentPage - 1);
                            }
                          }}
                        >
                          «
                        </a>
                      </li>
                    </div>
                    <li className="page-item active">
                      <a className="page-link" href="/client/0/?by=#">
                        {currentPage + 1}
                      </a>
                    </li>
                    <div>
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={(e) => {
                            if (currentPage + 1 < totalPages) {
                              setCurrentPage(currentPage + 1);
                            }
                          }}
                        >
                          »
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
      {/* /.content */}
      <section className="content">
        <div
          className={`modal fade show ${isCreateNew ? "show" : ""}`}
          id="modal-default"
          aria-modal="true"
          role="dialog"
          style={
            isCreateNew
              ? {
                  display: "block",
                  paddingRight: "17px",
                }
              : {
                  display: "none",
                }
          }
        >
          <div className="modal-dialog">
            <form>
              <input type="hidden" name="_method" defaultValue="PUT" />
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Select {userTypeName}</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <select
                    id="agent"
                    className="form-control"
                    onChange={handleSelectChangeUser}
                  >
                    <option> Please Select</option>
                    {userTypeList?.users
                      ?.sort((a, b) => a.userId - b.userId)
                      ?.map((item, index) => (
                        <option value={item.userId}>
                          {" "}
                          {item.userName} {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="modal-footer justify-content-between">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    data-dismiss="modal"
                    onClick={() =>
                      navigate(`/create/new/${userType}`, {
                        state: {
                          userId,
                        },
                      })
                    }
                    id="Agentpath"
                    type="button"
                    className="btn btn-primary"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </form>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
      </section>
    </div>
  );
}
