import React, { useEffect } from "react";
import { getProfitLossReportAPI } from "../service/AuthService";
import moment from "moment";
import { useSelector } from "react-redux";
import { getEarningReportAPI } from "../service/UserService";
import ReactSelect from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function ProfitLoss() {
  const location = useLocation();
  const [payload, setPayload] = React.useState({
    from: moment()
      .subtract(7, "days")
      .add(5, "h")
      .add(30, "m")
      .toDate(),
    to: moment()
      .add(5, "h")
      .add(30, "m")
      .toDate(),
    size: 10,
    page: 0,
    sports: JSON.parse(decodeURIComponent(location.search).split("=")[1]).map(
      (i) => i.toUpperCase()
    ),
  });
  const [userName, setUserName] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();
  const getProfitLossReport = async () => {
    const { response, code } = await getEarningReportAPI({
      ...payload,
      from: new Date(
        moment(payload.from)
          .add(5, "h")
          .add(30, "m")
      ).getTime(),
      to: new Date(
        moment(payload.to)
          .add(5, "h")
          .add(30, "m")
      ).getTime(),
    });
    if (code === 200) {
      setData(response);
    }
  };
  useEffect(() => {
    getProfitLossReport();
  }, [payload]);
  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>EARNING REPORT</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="#">{userData?.username}</a>
            </li>
            <li>
              <a href="#">
                <strong>Profit &amp; Loss</strong>
              </a>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="row">
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          style={{ margin: "11px 0px" }}
        >
          <div className="select-wrapper mdb-select colorful-select dropdown-primary md-form">
            <div className="form-group">
              <select
                className="js-select2 select2-hidden-accessible select-event-type"
                multiple=""
                tabIndex={-1}
                aria-hidden="true"
                style={{}}
              >
                <option value="selectall" data-badge="">
                  ALL
                </option>
                <option value="cricket" data-badge="" selected="">
                  Cricket
                </option>
                <option value="football" data-badge="" selected="">
                  Football
                </option>
                <option value="horse_race" data-badge="">
                  Horse Race
                </option>
                <option value="kabaddi" data-badge="">
                  Kabaddi
                </option>
                <option value="single_digit" data-badge="">
                  Dus Ka Dum
                </option>
                <option
                  value="rng"
                  data-event-sub-type="single-digit-live"
                  data-badge=""
                >
                  Dus Ka Dum Live
                </option>
                <option value="roulette" data-badge="">
                  Roulette
                </option>
                <option
                  value="TeenPatti"
                  data-event-sub-type="TeenPatti-T-20"
                  data-badge=""
                >
                  TeenPatti-T-20
                </option>
                <option
                  value="TeenPatti"
                  data-event-sub-type="TeenPatti-One-Day"
                  data-badge=""
                >
                  TeenPatti-One-Day
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-dragon-tiger"
                  data-badge=""
                >
                  Dragon Tiger
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-andar-bahar"
                  data-badge=""
                >
                  Andar Bahar
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-kqj"
                  data-badge=""
                >
                  KQJ
                </option>
                {/* <option value="card-game" data-event-sub-type="card-game-matka" data-badge="" >Matka</option> */}
                <option value="matka" data-event-sub-type="matka" data-badge="">
                  Matka
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-se7enup-se7endown"
                  data-badge=""
                >
                  7Up-7Down
                </option>
                <option value="inside_outside" data-badge="">
                  Inside Outside
                </option>
                <option value="triple_chance" data-badge="">
                  Triple Chance
                </option>
                <option value="king_queen_jack" data-badge="">
                  KingQueenJack
                </option>
                <option value="cricket_tournament" data-badge="">
                  Cricket Tournament
                </option>
                <option value="football_tournament" data-badge="">
                  Football Tournament
                </option>
              </select>
              <ReactSelect
                isMulti
                options={[
                  { label: "Cricket", value: "CRICKET" },
                  /*  { label: "Football", value: "FOOTBALL" },
                  { label: "Tennis", value: "TENNIS" },
                  { label: "Casino", value: "CASINO" }, */
                ]}
                value={payload.sports.map((sport) => ({
                  value: sport,
                  label: sport,
                }))}
                onChange={(e) => {
                  setPayload({ ...payload, sports: e.map((i) => i.value) });
                  navigate(
                    "/agent/ledgers/earning_report?event_type=" +
                      JSON.stringify(payload.sports),
                    { replace: true }
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="">
          <input
            value={moment(payload.from).format("YYYY-MM-DDTHH:mm")}
            style={{ margin: "11px 5px" }}
            onChange={(e) => {
              setPayload({
                ...payload,
                from: moment(e.target.value).toISOString(),
              });
            }}
            type="datetime-local"
            className=" input-md  form-input col-lg-2 col-md-4 col-sm-6 col-xs-12"
          />
          <input
            value={moment(payload.to).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => {
              setPayload({
                ...payload,
                to: moment(e.target.value).toISOString(),
              });
            }}
            style={{ margin: "11px 5px" }}
            type="datetime-local"
            className=" input-md form-input col-lg-2 col-md-4 col-sm-6 col-xs-12"
          />
        </div>

        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          style={{ margin: "11px 0px" }}
        >
          <a
            className="btn btn-primary btn-xs search_date_btn"
            onclick="searchData();"
          >
            Search
          </a>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Summary</h5>
              </div>
              <div className="ibox-content table-responsive">
                <table
                  className="table table-bordered table-striped"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>All Time Total</th>
                      <th>{data?.totalAllTimePAL}</th>
                    </tr>
                    <tr>
                      <th>Selected Cricket </th>
                      <th>{data?.totalAllTimePAL}</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Earning Report</h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <i className="fa fa-wrench" />
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/ledgers/earning_report_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                  {/* <a class="close-link">
                          <i class="fa fa-times"></i>
                      </a> */}
                </div>
              </div>
              <div className="ibox-content table-responsive">
                <table
                  className="table table-bordered table-striped"
                  id="earning-report-pdf"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>DATE/TIME</th>
                      <th>Match Id</th>
                      <th>Match Title</th>
                      <th>Match Earnings</th>
                      <th>Commision Earnings</th>
                      <th>Total Earnings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.earningReport?.content?.map((item) => (
                      <tr>
                        <td>
                          {moment(item?.eventTime).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>{item?.eventId}</td>
                        <td>{item?.eventName}</td>
                        <td>{item?.matchEarning}</td>
                        <td>{item?.commissionEarning}</td>
                        <td>{item?.totalEarning}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination">
                  <span
                    onClick={() => {
                      setPayload({ ...payload, page: payload.page - 1 });
                    }}
                    className={`previous_page ${payload?.page == 0 &&
                      "disabled"}`}
                  >
                    ← Previous
                  </span>{" "}
                  <em className="current">{payload?.page}</em>{" "}
                  <a
                    rel="next"
                    onClick={() => {
                      setPayload({ ...payload, page: payload.page + 1 });
                    }}
                  >
                    {payload.page + 1}
                  </a>{" "}
                  <a
                    className="next_page"
                    rel="next"
                    onClick={() => {
                      setPayload({ ...payload, page: payload.page + 1 });
                    }}
                  >
                    Next →
                  </a>
                </div>
                <table
                  className="table table-bordered table-striped"
                  id="earning-report-pdf"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>DATE/TIME</th>
                      <th>Match Id</th>
                      <th>Match Title</th>
                      <th>Match Earnings</th>
                      <th>Commision Earnings</th>
                      <th>Total Earnings</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        All Page Total
                      </th>{" "}
                      {/* Username */}
                      <th>
                        {data?.earningReport?.content?.reduce(
                          (acc, curr) => acc + curr.matchEarning,
                          0
                        )}
                      </th>
                      <th>
                        {data?.earningReport?.content?.reduce(
                          (acc, curr) => acc + curr.commissionEarning,
                          0
                        )}
                      </th>
                      <th>
                        {data?.earningReport?.content?.reduce(
                          (acc, curr) => acc + curr.totalEarning,
                          0
                        )}
                      </th>{" "}
                      {/* Total */}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
