import React, { useEffect, useState } from "react";
import { accountLogAPI, getChildrenByEventId } from "../service/AuthService";
import {
  ClientWithdrawAndDepositAPI,
  getClientAPI,
} from "../service/UserService";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useQuery } from "react-query";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import toast from "react-hot-toast";

export default function DebitCreditEntry() {
  const [child, setChild] = useState([]);
  const userData = useSelector((state) => state.account.userData);
  const { userType } = useParams();
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState(
    location?.state?.userId
      ? {
          value: location?.state?.userId,
          label: location?.state?.userName,
        }
      : ""
  );

  const [accountLogs, setAccountLog] = useState([]);

  const [payload, setPayload] = React.useState({
    pageSize: 50,
    pageNumber: 0,
    search: "",
    userName: selectedUser,
  });
  const [paymentPayload, setPaymentPayload] = useState({
    id: selectedUser,
    balance: 0,
    remarks: "",
    transactionType: "WITHDRAW",
  });
  const accountLog = async (userName) => {
    try {
      const { response, code } = await accountLogAPI(
        {
          ...payload,
          userName: userName?.label.split(" ")[0],
        },
        location?.state?.isOld
      );
      if (code == 200) {
        setAccountLog(response);
      } else {
        toast.error(response);
      }
    } catch {
      console.log("error");
    }
  };

  const getChild = async (username) => {
    const { response } = await getClientAPI(
      username ? username : payload.search,
      payload.pageNumber,
      payload.pageSize,
      "",
      userType?.toLocaleUpperCase(),
      false
    );

    setChild(response.childrenUserResponseDTOSPage?.content);
    return response.childrenUserResponseDTOSPage?.content?.map((item) => ({
      value: item.uid,
      label: `${item.username} ${item.name}`,
    }));
  };
  const submitData = async () => {
    const { response, code } = await ClientWithdrawAndDepositAPI(
      selectedUser?.value,
      paymentPayload
    );
    if (code == 200) {
      accountLog(selectedUser);
      toast.success("Transaction Success");
    }
  };
  useEffect(() => {
    getChild(
      location?.state?.userName?.split(" ")[0] || location?.state?.userName
    );
    if (selectedUser) {
      accountLog(selectedUser);
    }
    setAccountLog([]);
  }, [selectedUser, payload.pageNumber]);
  useEffect(() => {
    setSelectedUser("");
    setAccountLog([]);
  }, [userType]);
  return (
    <div className="content-wrapper" style={{ minHeight: "1300.41px" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Cash Transaction</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Cash Transaction</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div action="/ct/client" id="myForm" method="post">
                  <input
                    type="hidden"
                    name="_csrf"
                    defaultValue="e410f09e-f36e-4cae-9fc6-409bdf5a10b5"
                  />
                  <div className="card-header ">
                    <h4 className="text-capitalize">{userType} Ledger</h4>
                    <div className="form-row col-md-9">
                      <div className="form-group col-md-4">
                        <label htmlFor="name" className="text-capitalize">
                          client
                        </label>

                        <AsyncSelect
                          loadOptions={async (inputValue) => {
                            if (inputValue.length >= 2)
                              return getChild(inputValue);
                          }}
                          cacheOptions
                          defaultOptions={child?.map((item) => ({
                            value: item?.uid,
                            label: `${item?.username} ${item?.name}`,
                          }))}
                          defaultValue={selectedUser}
                          value={selectedUser}
                          onChange={(selectedOption) => {
                            setSelectedUser(selectedOption);
                          }}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="amount">Amount</label>
                        <input
                          type="number"
                          className="form-control "
                          defaultValue=""
                          step="any"
                          id="amount"
                          onChange={(e) => {
                            setPaymentPayload({
                              ...paymentPayload,
                              balance: e.target.value,
                            });
                          }}
                          value={paymentPayload.balance}
                          placeholder="Amount"
                          name="amount"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="type">Payment Type</label>
                        <select
                          className="form-control custom-select"
                          required=""
                          id="type"
                          name="paymentType"
                          onChange={(e) => {
                            setPaymentPayload({
                              ...paymentPayload,
                              transactionType: e.target.value,
                            });
                          }}
                          value={paymentPayload.transactionType}
                        >
                          <option
                            value={
                              userType == "client" ? "WITHDRAW" : "DEPOSIT"
                            }
                          >
                            PAYMENT - DENA
                          </option>
                          <option
                            value={
                              userType == "client" ? "DEPOSIT" : "WITHDRAW"
                            }
                          >
                            RECEIPT - LENA
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="remark">Remark</label>
                        <input
                          type="text"
                          className="form-control "
                          id="remark"
                          onChange={(e) => {
                            setPaymentPayload({
                              ...paymentPayload,
                              remarks: e.target.value,
                            });
                          }}
                          value={paymentPayload.remarks}
                          placeholder="Remark"
                          name="remark"
                          defaultValue=""
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label
                          className="control-label text-purple"
                          htmlFor="btn"
                        >
                          `
                        </label>
                        <input
                          type="submit"
                          className="form-control btn-primary"
                          id="btn"
                          name="submit"
                          onClick={submitData}
                          defaultValue="Sumbit"
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div>
                    {accountLogs?.length == 0 ? (
                      <div className="alert alert-warning">
                        <h6>No Record Found</h6>
                      </div>
                    ) : (
                      <div className="card-body">
                        <table
                          id="example1"
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Event id</th>
                              <th>Date</th>
                              <th>Collection Name</th>
                              <th>Debit</th>
                              <th>Credit</th>
                              <th>Balance</th>
                              <th>Type</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th
                                colSpan={3}
                                className="text-indigo text-lg text-center"
                              >
                                Total Amount
                              </th>
                              {userType == "client" ? (
                                <th className="text-gray-dark text-lg">
                                  {parseFloat(
                                    accountLogs?.reduce(
                                      (acc, item) => acc + item.creditInfo,
                                      0
                                    )
                                  ).toFixed(2)}
                                </th>
                              ) : (
                                <th className="text-gray-dark text-lg">
                                  {parseFloat(
                                    accountLogs?.reduce(
                                      (acc, item) => acc + item.debitInfo,
                                      0
                                    )
                                  ).toFixed(2)}
                                </th>
                              )}
                              {userType == "client" ? (
                                <th className="text-gray-dark text-lg">
                                  {parseFloat(
                                    accountLogs?.reduce(
                                      (acc, item) => acc + item.debitInfo,
                                      0
                                    )
                                  ).toFixed(2)}
                                </th>
                              ) : (
                                <th className="text-gray-dark text-lg">
                                  {parseFloat(
                                    accountLogs?.reduce(
                                      (acc, item) => acc + item.creditInfo,
                                      0
                                    )
                                  ).toFixed(2)}
                                </th>
                              )}
                              <th
                                className={` ${
                                  accountLogs[0]?.updatedBalance > 0
                                    ? "text-green"
                                    : "text-red"
                                } text-lg`}
                              >
                                {parseFloat(
                                  accountLogs[0]?.updatedBalance
                                ).toFixed(2)}
                              </th>
                              <th />
                              <th />
                            </tr>
                            {accountLogs
                              ?.sort(
                                (a, b) =>
                                  new Date(b.createdAt) -
                                  new Date(a.createdAt)
                              )
                              ?.map((item, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item?.eventId}</td>
                                  <td id="getdate_477155">
                                    {moment(item.createdAt + "Z").format(
                                      "DD MMM YY hh:mm a"
                                    )}
                                  </td>
                                  <td>CA1 CASH</td>
                                  {userType == "client" ? (
                                    <td
                                      style={{
                                        color:
                                          item?.creditInfo < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {item?.creditInfo &&
                                        parseFloat(item?.creditInfo).toFixed(
                                          2
                                        )}{" "}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        color:
                                          item?.debitInfo < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {" "}
                                      {item?.debitInfo &&
                                        parseFloat(item?.debitInfo).toFixed(
                                          2
                                        )}{" "}
                                    </td>
                                  )}
                                  {userType == "client" ? (
                                    <td
                                      style={{
                                        color:
                                          item?.debitInfo < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {" "}
                                      {item?.debitInfo &&
                                        parseFloat(item?.debitInfo).toFixed(
                                          2
                                        )}{" "}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        color:
                                          item?.creditInfo < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {item?.creditInfo &&
                                        parseFloat(item?.creditInfo).toFixed(
                                          2
                                        )}{" "}
                                    </td>
                                  )}
                                  <td>
                                    {item?.updatedBalance &&
                                      parseFloat(
                                        item?.updatedBalance
                                      ).toFixed(2)}
                                  </td>
                                  <td>
                                    {item?.type == "BALANCE_REPORT"
                                      ? item?.userType == "client"
                                        ? item?.creditInfo
                                          ? "Debit"
                                          : "Credit"
                                        : item?.creditInfo
                                        ? "Credit"
                                        : "Debit"
                                      : null}
                                  </td>
                                  <td>{item?.comment}</td>
                                </tr>
                              ))}
                          </tbody>
                          <tfoot>
                            {/* Pagination */}
                            <tr>
                              <td colSpan={8}>
                                <div className="pagination">
                                  <a
                                    onClick={() => {
                                      if (payload.pageNumber > 0) {
                                        setPayload({
                                          ...payload,
                                          pageNumber: payload.pageNumber - 1,
                                        });
                                      }
                                    }}
                                  >
                                    «
                                  </a>
                                  <a
                                    onClick={() => {
                                      setPayload({
                                        ...payload,
                                        pageNumber: 0,
                                      });
                                    }}
                                    className="active text-blue"
                                  >
                                    {payload.pageNumber + 1}
                                  </a>
                                  {payload.pageNumber + 1 <
                                    accountLogs.length / payload.pageSize && (
                                    <a
                                      onClick={() => {
                                        setPayload({
                                          ...payload,
                                          pageNumber: payload.pageNumber + 1,
                                        });
                                      }}
                                    >
                                      {payload.pageNumber + 2}
                                    </a>
                                  )}
                                  <a
                                    onClick={() => {
                                      if (
                                        accountLogs.length > 0 &&
                                        payload.pageNumber + 1 <
                                          accountLogs.length /
                                            payload.pageSize
                                      ) {
                                        setPayload({
                                          ...payload,
                                          pageNumber: payload.pageNumber + 1,
                                        });
                                      }
                                    }}
                                  >
                                    »
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    )}
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
