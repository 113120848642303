import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClientBetsCricketAPI } from "../service/cricket";
import Select from "react-select";
import moment from "moment";

export default function ViewSessionReport() {
  const [clients, setClients] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [isFancy, setIsFancy] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [payload, setPayload] = useState({});
  const [bets, setBets] = useState([]);

  const params = useParams();

  var gameType = "cricket";
  var eventId = params?.id;
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }

  const getClientBets = async (
    betStatus_,
    username,
    fancyTypes,
    filterWithoutCall = false
  ) => {
    if (filterWithoutCall) {
      if (username == "false" || fancyTypes == "false") {
        setClientBets(bets);
      } else {
        if (fancyTypes || username) {
          let bets_ = [...bets.bets];
          if (username) {
            bets_ = bets_.filter((item) => item.userName === username);
          }
          if (fancyTypes) {
            bets_ = bets_.filter((item) => item.runnerName === fancyTypes);
          }

          setClientBets({
            ...clientBets,
            bets: bets_,
          });
        }
      }
    } else {
      const { response } = await getClientBetsCricketAPI(
        eventId,
        isFancy,
        betStatus_ ? betStatus_ : betStatus
      );
      if (response) {
        setBets(response);
        setClients(
          Array.from(new Set(response?.bets?.map((item) => item?.userName)))
        );
        setSessions(
          Array.from(new Set(response?.bets?.map((item) => item?.runnerName)))
        );

        if (fancyTypes || username) {
          let bets_ = response;
          if (username) {
            bets_.bets = response.bets.filter(
              (item) => item.userName === username
            );
          }
          if (fancyTypes) {
            bets_.bets = response.bets.filter(
              (item) => item.runnerName === fancyTypes
            );
          }
          setClientBets(bets_);
        } else {
          setClientBets(response);
        }

        const totalAmount = response?.bets?.reduce(
          (total, item) => total + item.amount,
          0
        );

        setTotalAmount(totalAmount);
      }
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session Bet Details </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Session Bet Details</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid bg-white">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <form
                  action="/game/i/2048/viewSessionReport"
                  id="myForm"
                  method="post"
                >
                  <div className="card-header ">
                    <div className="form-row">
                      <table
                        className="table table-bordered dataTable table-striped"
                        data-select2-id={6}
                      >
                        <thead className="text-bold" data-select2-id={5}>
                          <tr>
                            <th>Client</th>
                            <th
                              className="col-lg-4"
                              style={{ minWidth: 240 }}
                              data-select2-id={4}
                            >
                              <Select
                                id="name"
                                placeholder="Select Client"
                                name="client"
                                options={clients.map((item) => ({
                                  value: item,
                                  label: item,
                                }))}
                                onChange={(e) => {
                                  if (e.value)
                                    setPayload({
                                      ...payload,
                                      client: e.value,
                                    });
                                  else setPayload({});
                                }}
                              />
                            </th>
                            <th />
                            <th>
                              Declare @{" "}
                              {
                                bets?.bets?.find(
                                  (item) => item?.runnerName == payload.session
                                )?.winner
                              }
                            </th>
                          </tr>
                          <tr>
                            <th>Session</th>
                            <th className="col-lg-4" style={{ minWidth: 240 }}>
                              <select
                                className="form-control custom-select"
                                required=""
                                id="session"
                                placeholder="Select Client"
                                name="remark"
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    session: e.target.value,
                                  })
                                }
                              >
                                <option value={false}>All Session</option>
                                {sessions?.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </th>
                            <th>
                              <button
                                onClick={() =>
                                  getClientBets(
                                    betStatus,
                                    payload.client,
                                    payload.session
                                  )
                                }
                                type="button"
                                className="btn-primary btn"
                              >
                                View
                              </button>
                            </th>
                            <th className="text-red">
                              {
                                bets?.bets?.find(
                                  (item) => item?.runnerName == payload.session
                                )?.plusMinus
                              }
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>

                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-10">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Client</th>
                                <th>Session</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Runs</th>
                                <th style={{ width: 40 }}>Mode</th>
                                <th>Yes</th>
                                <th>No</th>

                                <th>Date &amp; Time</th>
                              </tr>
                              <tr>
                                <th
                                  colSpan={3}
                                  className="text-indigo text-lg text-center"
                                >
                                  Total Amount
                                </th>
                                <th
                                  colSpan={4}
                                  className="text-gray-dark text-lg text-center"
                                >
                                  {totalAmount}
                                </th>
                                <th
                                  colSpan={3}
                                  className={`${
                                    clientBets?.bets?.reduce(
                                      (total, item) =>
                                        total + item.clientPlusMinus,

                                      0
                                    ) > 0
                                      ? "text-blue"
                                      : "text-red"
                                  } text-lg text-center`}
                                >
                                  {clientBets?.bets?.reduce(
                                    (total, item) =>
                                      total + item.clientPlusMinus,

                                    0
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientBets?.bets?.map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.userName}</td>
                                    <td>{item.runnerName}</td>
                                    <td>{item.rate}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.run}</td>
                                    <td>
                                      {item.back == false ? <>No</> : <>Yes</>}
                                    </td>
                                    {item?.upperLineExposure?.map((item) => (
                                      <td>{item?.exposure}</td>
                                    ))}

                                    <td>
                                      {moment(item.placeDate + "Z").format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </form>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
