import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCollectionReportAPI } from "../service/AuthService";
import moment from "moment";

export default function CollectionReport() {
  const userData = useSelector((state) => state.account.userData);
  const [dateFilter, setDateFilter] = React.useState({
    //startdate must bet 7 days before the current date
    startDate: moment()
      .subtract(7, "days")
      .toISOString(),
    endDate: moment().toISOString(),
  });
  const [collectionReportData, setCollectionReportData] = React.useState([]);
  const [ledgerReport, setLedgerReport] = React.useState({});
  const collectionReport = async () => {
    const { response, code } = await getCollectionReportAPI(
      new Date(dateFilter.startDate).getTime(),
      new Date(dateFilter.endDate).getTime()
    );
    let ledger = {
      lenaHai: [],
      denaHai: [],
      clearHai: [],
    };
    if (code === 200) {
      setCollectionReportData(response.collectionReport);
      for (let item of response.collectionReport) {
        if (item.balance < 0) {
          ledger.lenaHai.push({
            total: Math.abs(item.balance),
            userName: item.userName,
          });
        } else if (item.balance > 0) {
          ledger.denaHai.push({
            total: Math.abs(item.balance),
            userName: item.userName,
          });
        } else if (item.balance === 0) {
          ledger.clearHai.push({
            total: Math.abs(item.balance),
            userName: item.userName,
          });
        }
      }
      setLedgerReport(ledger);
    }
  };
  useEffect(() => {
    collectionReport();
  }, []);
  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>COLLECTION REPORT</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="#">{userData?.username}</a>
            </li>
            <li>
              <a href="#">
                <strong>Collection Report</strong>
              </a>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="row">
        <div
          id="reportrange"
          className="my_ledgers_dated_picker col-lg-3 col-md-4 col-sm-6 col-xs-11 pull-right"
        >
          <i className="glyphicon glyphicon-calendar fa fa-calendar" />
          &nbsp;
          <span> - </span>
          <b className="caret" />
        </div>
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          style={{ margin: "11px 0px" }}
        >
          <a className="btn btn-primary btn-xs search_date_btn">Serach</a>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>PAYMENT RECEIVING FROM (Lena He)</h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <i className="fa fa-wrench" />
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/ledgers/collection_report_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table" id="example_lena_hai">
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledgerReport?.lenaHai?.map((item) => (
                      <tr>
                        <td>
                          <a href={`/agent/users/${item?.userName}`}>
                            {item?.userName}
                          </a>
                        </td>{" "}
                        <td>{item?.total}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th>
                      <th>
                        {ledgerReport?.lenaHai?.reduce(
                          (acc, item) => acc + item.total,
                          0
                        )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>PAYMENT PAID TO (Dena He)</h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <i className="fa fa-wrench" />
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/ledgers/collection_report_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table display" id="example_dena_hai">
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledgerReport?.denaHai?.map((item) => (
                      <tr>
                        <td>
                          <a href={`/agent/users/${item?.userName}`}>
                            {item?.userName}
                          </a>
                        </td>{" "}
                        <td>{item?.total}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th> {/* Username */}
                      <th>
                        {ledgerReport?.denaHai?.reduce(
                          (acc, item) => acc + item.total,
                          0
                        )}
                      </th>{" "}
                      {/* Total */}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>PAYMENT Clear (Clear Hai)</h5>
                <div className="ibox-tools">
                  <a
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                  >
                    <i className="fa fa-wrench" />
                  </a>
                  <ul className="dropdown-menu dropdown-user">
                    <li>
                      <a
                        target="_blank"
                        href="/agent/ledgers/collection_report_pdf.pdf"
                      >
                        Export PDF
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table" id="example_lena_hai">
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledgerReport?.clearHai?.map((item) => (
                      <tr>
                        <td>
                          <a href={`/agent/users/${item?.userName}`}>
                            {item?.userName}
                          </a>
                        </td>{" "}
                        <td>{item?.total}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total</th> {/* Username */}
                      <th>
                        {ledgerReport?.clearHai?.reduce(
                          (acc, item) => acc + item.total,
                          0
                        )}
                      </th>{" "}
                      {/* Total */}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
