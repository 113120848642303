import React, { useEffect, useState } from "react";
import { getAllMatchesAPI } from "../service/AuthService";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";


export default function Matches() {

  const location = useLocation();
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const getAllMatches = async () => {
    const { response } = await getAllMatchesAPI(currentPage);
    setMatches(response.content);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllMatches();
  }, [currentPage]);

  return (
    <div className="content-wrapper" style={{ minHeight: "1263.44px" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Games</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Inplay</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <form action="#" method="post" id="demoForm">
                  <div className="card-header">
                    <h2 className="card-title">Sport Details</h2>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example1"
                      className="table table-bordered table-striped "
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>SNo</th>
                          <th>Event Id</th>
                          <th>Name</th>
                          <th>Date Time</th>
                          <th>SPORT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div align="center">
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn  btn-outline-primary dropdown-toggle dropdown-hover dropdown-icon"
                                    data-toggle="dropdown"
                                  >
                                    <span className="sr-only">
                                      Toggle Dropdown
                                    </span>
                                  </button>
                                  <div className="dropdown-menu" role="menu">
                                    <a class="dropdown-item btn"
                                      onClick={() =>
                                        navigate(
                                          `/game/matchPosition/${
                                            item.id
                                          }`, {
                                            state:{match:item}
                                          }
                                        )
                                      }>
                                      Match &amp; Session Position
                                    </a>
                                    <a class="dropdown-item btn"
                                      onClick={() =>
                                        navigate(`/game/sessionPlusMinusSelect/${
                                            item.id
                                          }`, {
                                            state:{match:item}
                                          }
                                        )
                                      }>
                                      Session Plus Minus
                                    </a>
                                    <a class="dropdown-item btn"
                                      onClick={() =>
                                        navigate(`/game/displayMatchSession/${
                                            item.id
                                          }`, {
                                            state:{match:item}
                                          }
                                        )
                                      }>
                                       Display Match &amp; Session Bets
                                    </a>
                                    <a
                                      className="dropdown-item btn"
                                      onClick={() =>
                                        navigate(`/game/viewMatchReport/${
                                            item.id
                                          }`, {
                                            state:{match:item}
                                          }
                                        )
                                      }
                                    >
                                      Display Match Bets
                                    </a>
                                    <a
                                      className="dropdown-item btn"
                                      onClick={() =>
                                        navigate(`/game/viewSessionReport/${
                                            item.id
                                          }`, {
                                            state:{match:item}
                                          }
                                        )
                                      }
                                    >
                                      Display Session Bets
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <label>{index + 1}</label>
                            </td>
                            <td>
                              <span>{item?.eventId}</span>
                            </td>
                            <td>{item?.eventName}</td>
                            <td>
                              {moment(item?.eventDateTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </td>
                            <td>{item?.sport}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot />
                    </table>
                    <div className="pagination">
                      <a
                        onClick={() => {
                          if (currentPage == 1) return;
                          setCurrentPage(currentPage - 1);
                        }}
                        className="previous_page"
                      >
                        ← Previous
                      </a>
                      <em className="current">{currentPage}</em>
                      <a
                        className="next_page"
                        rel="next"
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      >
                        Next →
                      </a>
                    </div>
                  </div>
                  {/* /.card-body */}
                </form>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  );
}
