import React, { useState } from "react";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import toast from "react-hot-toast";

const ChangePassword = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);

  const dispatch = useDispatch();
  const changePassword = async () => {
    if (password === "" || oldPassword === "") {
      toast.error("Please fill all the fields");
      return;
    }

    if (password === oldPassword) {
      toast.error("New password should be different from old password");
      return;
    }

    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: Encrypt(password),
        password: Encrypt(oldPassword),
        resetRequired: false,
        uid: userData.id,
      },
      userData.token
    );
    if (code === 200) {
      toast.success("Password changed successfully");
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      localStorage.removeItem("userData");
      localStorage.clear();
      window.location.href = "/";
    } else {
      toast.error(message);
    }
  };
  return (
    <div className="container">
      <div className="container-fluid" style={{ marginTop: 40 }}>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-md-4 col-md-offset-4">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="text-center">
                  <h3>
                    <i className="fa fa-lock fa-4x" />
                  </h3>
                  <h2 className="text-center">Change Password?</h2>
                  <p>You can change your password here.</p>
                  <div className="panel-body">
                    <div
                      id="reset-form"
                      role="form"
                      autoComplete="off"
                      className="form"
                    >
                      <div className="form-group">
                        <label htmlFor="password">
                          Enter Current Password
                        </label>
                        <input
                          type="password"
                          placeholder="Enter Current Password"
                          id="password"
                          value={oldPassword}
                          className="form-control"
                          onChange={(e) => setOldPassword(e.target.value)}
                          name="password"
                          required=""
                        />
                        <br />
                        <div className="input-group">
                          <input
                            type="text"
                            name="pass"
                            id="pass"
                            className="form-control"
                            min={6}
                            minLength={8}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength={12}
                            placeholder="Enter New Password"
                            required=""
                          />
                          <span className="input-group-btn">
                            <button
                              className="btn btn-default"
                              onClick={() => {
                                var pass = "";
                                var str =
                                  "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
                                  "abcdefghijklmnopqrstuvwxyz0123456789@#$";
                                for (var i = 1; i <= 8; i++) {
                                  var char = Math.floor(
                                    Math.random() * str.length + 1
                                  );
                                  pass += str.charAt(char);
                                }
                                setPassword(pass);
                              }}
                              type="button"
                            >
                              Generate
                            </button>
                          </span>
                        </div>
                        <small className="form-text text-muted">
                          Must be at least 8 characters include numeric,
                          alphabets and special symbols i.e. Bharat@007#{" "}
                        </small>
                      </div>
                      <div className="form-group" align="left">
                        <p>
                          आपके अकाउंट की सुरक्षा को ध्यान रखते हुए आपके लिए
                          कंप्यूटर जनित पासवर्ड बनाया जा रहा है | नीचे दिए गए
                          बटन पर क्लिक करके एक नया पासवर्ड बनाएं|
                          <br /> Keeping in mind the security of your account,
                          a computer-generated password is being created for
                          you.. Create a new password by clicking on the
                          button below
                        </p>
                      </div>
                      <div className="form-group">
                        <input
                          onClick={changePassword}
                          name="recover-submit"
                          className="btn btn-lg btn-primary btn-block"
                          defaultValue="Change Password"
                          type="submit"
                        />
                      </div>
                      <div className="form-group">
                        <a
                          href="/"
                          className="btn  btn-lg btn-outline-danger"
                        >
                          Back
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
