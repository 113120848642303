import React, { useEffect, useState } from "react";
import { getUserActivityAPI, getUsersByTypeAPI } from "../service/AuthService";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import ReactSelect from "react-select";



export default function LoginReport() {
  const [selectedUserName, setSelectedUserName] = useState(20);
  const [payload, setPayload] = useState({
    activityLogType: "LOGIN",
    from: moment()
      .subtract(7, "d")
      .toISOString(),
    to: moment().toISOString(),
    userName: "",
    pageSize: 100,
  });
  const [data, setData] = useState([]);
  const location = useLocation();
  const params = useParams();

  const [users, setUsers] = useState([]);
  const getUsersByType = async () => {
    const { response, code } = await getUsersByTypeAPI(
      params?.userType?.toLocaleUpperCase()
    );
    if (code === 200) {
      setUsers(response?.users);
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };
  const getUserActivity = async () => {
    const { response, code } = await getUserActivityAPI(payload);
    if (code == 200) {
      setData(response?.content);
    }
  };
  useEffect(() => {
    getUsersByType();
  }, [params]);
  return (
    <div className="content-wrapper" style={{ minHeight: "1448.29px" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Login Reports</h1></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active text-capitalize">
                  {params?.userType?.toLocaleUpperCase()} Login Reports
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div method="post">
                  <input
                    type="hidden"
                    name="_csrf"
                    defaultValue="81e63a0d-809f-416f-86bb-6e73b5e60768"
                  />
                  <div className="card-header bg-gradient-purple">
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="name" className="text-capitalize">
                          {params?.userType?.toLocaleUpperCase()}
                        </label>
                        {/* <select
                          className="form-control "
                          required=""
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              userName: e.target.value,
                            })
                          }
                          id="name"
                          placeholder="Select Client"
                          name="client"
                          data-select2-id="name"
                          tabIndex={-1}
                          aria-hidden="true"
                        >
                          <option value={0} data-select2-id={2}>
                            {" "}
                            All {params?.userType?.toLocaleUpperCase()}{" "}
                          </option>

                          {users.map((user) => (
                            <option key={user.id} value={user.userName}>
                              {user.userName} {user?.name}
                            </option>
                          ))}
                        </select> */}

                        <ReactSelect
                          options={
                            users
                              ? users?.map((item) => ({
                                value: item.userName,
                                label: `${item.userName} ${item.name}`,
                              }))
                              : []
                          }
                          styles={customStyles}
                          onChange={(selectedOption) =>
                            setPayload({
                              ...payload,
                              userName: selectedOption.value,
                            })
                          }
                        />
                      

                      </div>
                      <div className="form-group col-md-3">
                        <div className="form-group">
                          <label htmlFor="from">Date From</label>
                          <div
                            className="input-group date"
                            id="reservationdateFrom"
                            data-target-input="nearest"
                          >
                            <input
                              type="datetime-local"
                              value={moment(payload.from).format(
                                "YYYY-MM-DDTHH:mm"
                              )}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  from: moment(e.target.value).toISOString(),
                                })
                              }
                              className="form-control datetimepicker-input"
                              id="from"
                              data-target="#reservationdateFrom"
                              name="from"
                              defaultValue=""
                            />
                            <div
                              className="input-group-append"
                              data-target="#reservationdateFrom"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <div className="form-group">
                          <label htmlFor="to">Date To</label>
                          <div
                            className="input-group date"
                            id="reservationdateTo"
                            data-target-input="nearest"
                          >
                            <input
                              type="datetime-local"
                              value={moment(payload.to).format(
                                "YYYY-MM-DDTHH:mm"
                              )}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  to: moment(e.target.value).toISOString(),
                                })
                              }
                              className="form-control datetimepicker-input"
                              id="to"
                              data-target="#reservationdateTo"
                              name="to"
                              defaultValue=""
                            />
                            <div
                              className="input-group-append"
                              data-target="#reservationdateTo"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <label
                          className="control-label text-purple"
                          htmlFor="btn"
                        >
                          `
                        </label>
                        <input
                          onClick={getUserActivity}
                          type="submit"
                          className="form-control btn-primary"
                          id="btn"
                          name="submit"
                          defaultValue="Sumbit"
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="pt-2">
                    <table
                      id="example1"
                      className="table table-bordered table-striped"
                    >
                      <thead className="bg-gradient-yellow">
                        <tr>
                          <th>#</th>
                          <th>Code</th>
                          <th>Login Name</th>
                          <th>IPAddress</th>
                          <th>DateTime</th>
                          <th>Last Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.userId}</td>
                            <td>{item?.userName}</td>
                            <td>{item?.ipAddress}</td>
                            <td>
                              {moment(item?.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot />
                    </table>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
