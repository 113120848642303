import React, { useEffect } from "react";
import { getBetHistoryAPI, searchUserAPI } from "../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";

export default function BetHistory() {
  const [from, setFrom] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [to, setTo] = React.useState(
    moment()
      .add(1, "d")
      .format("YYYY-MM-DD")
  );
  const [sport, setSport] = React.useState("CRICKET");
  const [matched, setMatched] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const getBetHistory = async () => {
    try {
      let payload = {
        from: new Date(from),
        matched: matched == "MATCHED" ? true : false,
        page,
        size,
        sport,
        to: new Date(to),
        userName: userName?.value?.split(" ")[0],
      };
      const {
        response: { content },
      } = await getBetHistoryAPI(payload);
      let data_ = content.map((item, index) => ({
        ...item,

        bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
        rate: item.back_run ? item.back_run : item.lay_run,
        "p/l": item.profit ? item.profit : item.loss,
      }));
      setData(data_);
    } catch (e) {
      setData([]);
    }
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  useEffect(() => {
    getBetHistory();
  }, [size]);
  return (
    <>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="mb-3">Bet History</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/">Report</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="/game/inPlay">Bet History</a>
                    </li>
                  </ol>
                </div>
              </div>

              <div className="col-lg-2" />
            </div>

            <div className="">
              <div className="row">
                <div className="col-sm-3 m-b-xs mb-3">
                  <select
                    onChange={(e) => {
                      setSport(e.target.value);
                    }}
                    mx="1"
                    value={sport}
                    className="input-sm form-control input-s-sm inline"
                  >
                    <option value="ALL">Sports Type</option>
                    <option value="CRICKET">Cricket</option>
                    <option value="SOCCER">Football</option>
                    <option value="TENNIS">Tennis</option>
                    <option value="CASINO">Casino</option>
                  </select>
                </div>
                <div className="col-sm-3 m-b-xs">
                  <select
                    onChange={(e) => {
                      setMatched(e.target.value);
                    }}
                    mx="1"
                    value={matched}
                    className="input-sm form-control input-s-sm inline"
                  >
                    <option value="ALL">Bet Status</option>
                    <option value="MATCHED">Matched</option>
                    <option value="DELETED">Deleted</option>
                  </select>
                </div>
                <div className="col-sm-3 m-b-xs">
                  <input
                    onChange={(e) => {
                      setFrom(e.target.value);
                    }}
                    value={from}
                    mx="1"
                    type="date"
                    className="input-sm form-control input-s-sm inline"
                  />
                </div>
                <div className="col-sm-3 m-b-xs">
                  <input
                    onChange={(e) => {
                      setTo(e.target.value);
                    }}
                    value={to}
                    mx="1"
                    type="date"
                    className="input-sm form-control input-s-sm inline"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 m-b-xs">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchClient}
                    onChange={(e) => setUserName(e)}
                    isClearable={true}
                    value={userName}
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: "35px",
                        minHeight: "35px",
                      }),
                    }}
                  />
                </div>
                <div className="col-sm-3 m-b-xs">
                  <button
                    className="btn btn-primary btn-md search_date_btn"
                    onClick={() => getBetHistory()}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="table-responsive data-table" id="tbldata">
                <div
                  id="table-active_bets_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    className="dataTables_length"
                    id="table-active_bets_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="table-active_bets_length"
                        aria-controls="table-active_bets"
                        className="form-control input-sm"
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                  {/*  <div id="table-active_bets_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                onFocus={() => search_.length > 2 && setSize(50)}
              
                type="search"
                className="form-control"
                placeholder
                aria-controls="table-active_bets"
                onChange={search}
              />
            </label>
          </div> */}
                  <table
                    id="table-active_bets"
                    className="table table-bordered datatable dataTable no-footer"
                    role="grid"
                    aria-describedby="table-active_bets_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>S.No.</th>

                        <th>Event Name</th>

                        <th>Runner Name</th>
                        <th>Bet Type</th>
                        <th>User Rate</th>
                        <th>Profit Loss</th>
                        <th>Amount</th>

                        <th>Place Date</th>
                        <th>Match Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length == 0 && (
                        <tr className="odd">
                          <td
                            valign="top"
                            colSpan={10}
                            className="dataTables_empty"
                          >
                            No data available in table
                          </td>
                        </tr>
                      )}
                      {data.map((item, index) => {
                        return (
                          <tr role="row" className="odd" key={index}>
                            <td width="60px">{index + 1}</td>

                            <td>{item.event_name}</td>

                            <td>{item.runner_name}</td>
                            <td>
                              {item?.marketName == "BOOKMAKER_ODDS_1"
                                ? item.bet_type == "Back"
                                  ? "KHAI"
                                  : "LAGAI"
                                : item.bet_type == "Back"
                                ? "Yes"
                                : "No"}
                            </td>
                            <td>
                              {item.back_run ? item.back_run : item.lay_run}
                            </td>
                            <td>{item["p/l"]}</td>
                            <td>{item.amount}</td>

                            <td>
                              {moment(item.created_date + "Z")
                                .utc()
                                .local()
                                .format("DD/MM/YYYY hh:mm")}
                            </td>
                            <td>
                              {moment(item?.created_date + "Z")
                                .utc()
                                .format("DD-MM-YYYY HH:mm")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
