import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  addNewClientAPI,
  getGeneratedUsernameAPI,
  getUserDataAPI,
  getChildUserInfoAPI,
  ChangeClientStatusAPI,
} from "../service/UserService";
import { EditClientAPI } from "../service/UserService";
import { Encrypt } from "../utils/constants";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function CreateClient() {
  const [clientData, setClientData] = useState({});
  const location = useLocation();
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const userType = useParams()?.userType;
  const [userId, setUserId] = useState(location.state?.client?.uid);
  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    setIsAdmin(false);
    setClientData(response);
  };

  const getChildUserInfo = async () => {
    const { response } = await getChildUserInfoAPI(userId);

    setClientData((prevState) => ({
      ...prevState,
      ...response?.childInfo,
    }));
    setPayload((prevState) => ({
      ...prevState,
      ...response?.childInfo,
    }));
  };

  useEffect(() => {
    if (userId) {
      getChildUserInfo();
    } else {
      getUserInfo();
    }
  }, [userId]);

  const handleSubmit = async () => {
    let filteredPayload = {
      ...payload,
    };

    //remove unchanged values
    Object.keys(filteredPayload).forEach(
      (key) =>
        (filteredPayload[key] === location?.state?.client[key] ||
          filteredPayload[key] === clientData[key]) &&
        delete filteredPayload[key]
    );
    try {
      const { response, code, error, status } = await EditClientAPI(
        filteredPayload,
        userId
      );

      if (code === 200) {
        toast.success(response?.status || "User Update Successfully");
        setTimeout(() => {
          window.location.href = `/${userType}`;
        }, 500);
        setPayload({});
      } else if (code === 500) {
        toast.error(response);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
        });
      } else {
        toast.error(
          response || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };
  const handleCasinoChange = async (e, matka = false) => {
    if (matka) {
      const isMatkaLocked = e.target.value === "true";
      const { response, code, error, status } = await ChangeClientStatusAPI(
        userId,
        clientData.isActive,
        clientData.isBetLocked,
        clientData.isCasinoBetLocked,
        isMatkaLocked
      );
      if (code === 200) {
        toast.success(response?.status || "User Update Successfully");
      } else if (code === 500) {
        toast.error(response);
      } else {
        toast.error(
          response || "Something went wrong Please check all you field"
        );
      }
    } else {
      const isCasinoLocked = e.target.value === "true";
      const { response, code, error, status } = await ChangeClientStatusAPI(
        userId,
        clientData.isActive,
        clientData.isBetLocked,
        isCasinoLocked
      );
      if (code === 200) {
        toast.success(response?.status || "User Update Successfully");
      } else if (code === 500) {
        toast.error(response);
      } else {
        toast.error(
          response || "Something went wrong Please check all you field"
        );
      }
    }
  };
  const handleCommissionChange = (e) => {
    const commissionOn = e.target.value === "true";
  };
  useEffect(() => {
    setClientData({
      partnershipAndCommission: {
        matchCommission: 0,
        sessionCommission: 0,
        casinoCommission: 0,
      },
    });
  }, []);

  useEffect(() => {
    setPayload(location?.state?.client);
  }, []);

  const generatePassword = () => {
    const passwordLength = 6;
    let newPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      newPassword += Math.floor(Math.random() * 10); // Generate a random number between 0 and 9
    }
    setPayload({
      ...payload,
      password: newPassword,
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit {userType}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/super">Super</a>
                </li>
                <li className="breadcrumb-item active">New {userType}</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <form>
          <input
            type="hidden"
            name="_csrf"
            defaultValue="ba9d3d2b-b2c1-4143-bd84-a7f988415a48"
          />
          {userType == "client" ? (
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">General</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        control-id="ControlID-1"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="scode">Parent Code</label>
                      <input
                        type="text"
                        id="scode"
                        defaultValue={
                          location?.state?.client?.parentUserName?.split(
                            " "
                          )[0]
                        }
                        placeholder=""
                        readOnly
                        disabled
                        className="form-control"
                        control-id="ControlID-2"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="code">Code</label>
                      <input
                        type="text"
                        id="code"
                        placeholder=""
                        readOnly
                        className="form-control"
                        name="code"
                        defaultValue={location?.state?.client?.username}
                        control-id="ControlID-3"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        min={2}
                        required=""
                        name="name"
                        defaultValue="dho 04"
                        control-id="ControlID-4"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            name: e.target.value,
                          })
                        }
                        value={payload?.name}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Reference</label>
                      <input
                        type="text"
                        id="reference"
                        className="form-control"
                        placeholder="Reference"
                        required=""
                        name="reference"
                        defaultValue="self"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            reference: e.target.value,
                          })
                        }
                        value={payload?.reference}
                        control-id="ControlID-5"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <div className="input-group ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          min={6}
                          id="password"
                          required=""
                          value={payload?.password}
                          name="password"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              password: e.target.value,
                            })
                          }
                          control-id="ControlID-6"
                        />
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-info btn-flat"
                            onClick={generatePassword}
                            control-id="ControlID-7"
                          >
                            Generate Password
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Contact No</label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobile"
                        placeholder="Mobile No"
                        required=""
                        name="contactno"
                        defaultValue={0}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            phone: e.target.value,
                          })
                        }
                        value={payload?.phone}
                        control-id="ControlID-8"
                      />
                    </div>
                    {/*  <div className="form-group">
                    <label htmlFor="flat">Flat Share</label>
                    <select
                      className="form-control"
                      id="flat"
                      name="sharestatus"
                      control-id="ControlID-9"
                    >
                      <option value="false">No</option>
                      <option value="true" selected="selected">
                        Yes
                      </option>
                    </select>
                  </div>
                  */}
                    <div className="form-group">
                      <label htmlFor="casinoCheck">Casino Play</label>
                      <select
                        value={clientData?.isCasinoBetLocked}
                        id="casinoCheck"
                        className="form-control"
                        name="casinoCheck"
                        control-id="ControlID-10"
                        onChange={handleCasinoChange}
                      >
                        <option value={false} selected="selected">
                          Yes
                        </option>
                        <option value={true}>No</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="casinoCheck">Matka Play</label>
                      <select
                        value={clientData?.isMatkaBetLocked}
                        id="casinoCheck"
                        className="form-control"
                        name="casinoCheck"
                        control-id="ControlID-10"
                        onChange={(e) => handleCasinoChange(e, true)}
                      >
                        <option value={false} selected="selected">
                          Yes
                        </option>
                        <option value={true}>No</option>
                      </select>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              <div className="col-md-6">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">Share and Commission</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        control-id="ControlID-11"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">
                          {userType?.toLocaleUpperCase()} Share
                        </label>
                        <input
                          type="number"
                          min={0}
                          placeholder="Share"
                          className="form-control"
                          id="share"
                          step="0.01"
                          required=""
                          name="share"
                          control-id="ControlID-12"
                          value={payload?.myPartnership}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myPartnership: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="sashare">Parent Share On Agent</label>
                        <input
                          type="number"
                          placeholder="Share"
                          className="form-control"
                          value={clientData?.parentPartnership}
                          id="sashare"
                          disabled
                          readOnly
                          control-id="ControlID-13"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">
                          {userType?.toLocaleUpperCase()} Casino Share
                        </label>
                        <input
                          type="number"
                          min={0}
                          placeholder="Casino Share"
                          className="form-control"
                          id="cshare"
                          step="0.01"
                          required=""
                          name="cshare"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myCasinoPartnership: e.target.value,
                            })
                          }
                          value={payload?.myCasinoPartnership}
                          control-id="ControlID-14"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="csashare">
                          Parent Casino Share On Agent
                        </label>
                        <input
                          type="number"
                          placeholder="Share"
                          className="form-control"
                          value={clientData?.parentCasinoPartnership}
                          disabled
                          readOnly
                          id="csashare"
                          control-id="ControlID-15"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">
                          {userType?.toLocaleUpperCase()} Matka Share
                        </label>
                        <input
                          type="number"
                          min={0}
                          placeholder="Casino Share"
                          className="form-control"
                          id="cshare"
                          step="0.01"
                          required=""
                          name="cshare"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myMatkaPartnership: e.target.value,
                            })
                          }
                          value={payload?.myMatkaPartnership}
                          control-id="ControlID-14"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="csashare">
                          Parent Matka Share On Agent
                        </label>
                        <input
                          type="number"
                          placeholder="Share"
                          className="form-control"
                          value={clientData?.parentMatkaPartnership}
                          disabled
                          readOnly
                          id="csashare"
                          control-id="ControlID-15"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="session_commission_type">
                        Commission Type
                      </label>
                      <select
                        id="session_commission_type"
                        className="form-control"
                        control-id="ControlID-20"
                        onChange={(e) => {
                          if (e.target.value === "1") {
                            setPayload({
                              ...payload,
                              matchCommission: 0,
                              sessionCommission: 0,
                              casinoCommission: 0,
                            });
                          }
                        }}
                      >
                        <option value="1">No Comm</option>
                        <option value="0">Bet by Bet</option>
                      </select>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="match_commission">
                          Match Commision
                        </label>
                        <input
                          type="number"
                          value={payload?.matchCommission}
                          className="form-control"
                          placeholder="Match"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              matchCommission: e.target.value,
                            })
                          }
                          min={0}
                          max={clientData?.parentMatchCommission}
                          step="0.01"
                          id="match_commission"
                          required=""
                          name="matchcommission"
                          control-id="ControlID-21"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }{" "}
                          Match Commission
                        </label>
                        <input
                          id="mc"
                          type="text"
                          min={0}
                          value={clientData?.parentMatchCommission}
                          className="form-control"
                          readOnly
                          disabled
                          control-id="ControlID-22"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="session_commission">
                          Session Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Match"
                          min={0}
                          max={clientData?.parentSessionCommission}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              sessionCommission: e.target.value,
                            })
                          }
                          value={payload?.sessionCommission}
                          step="0.01"
                          id="session_commission"
                          required=""
                          name="sessioncommission"
                          control-id="ControlID-23"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }
                          Session Commission
                        </label>
                        <input
                          type="number"
                          defaultValue={3.0}
                          value={clientData?.parentSessionCommission}
                          id="sc"
                          className="form-control"
                          placeholder="Session Commission"
                          readOnly
                          disabled
                          control-id="ControlID-24"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="casino_commission">
                          Casino Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Match"
                          min={0}
                          max={clientData?.parentCasinoCommission}
                          step="0.01"
                          id="casino_commission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              casinoCommission: e.target.value,
                            })
                          }
                          value={payload?.casinoCommission}
                          required=""
                          name="casinocommission"
                          control-id="ControlID-25"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="cc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }
                          Casino Commission
                        </label>
                        <input
                          type="number"
                          defaultValue={2.0}
                          id="cc"
                          className="form-control"
                          placeholder="Casino Commission"
                          readOnly
                          disabled
                          value={clientData?.parentCasinoCommission}
                          control-id="ControlID-26"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="casino_commission">
                          Matka Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Match"
                          min={0}
                          max={clientData?.parentMatkaCommission}
                          step="0.01"
                          id="casino_commission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              matkaCommission: e.target.value,
                            })
                          }
                          value={payload?.matkaCommission}
                          required=""
                          name="casinocommission"
                          control-id="ControlID-25"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="cc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }
                          Matka Commission
                        </label>
                        <input
                          type="number"
                          defaultValue={2.0}
                          id="cc"
                          className="form-control"
                          placeholder="Casino Commission"
                          readOnly
                          disabled
                          value={clientData?.parentMatkaCommission}
                          control-id="ControlID-26"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">General</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        control-id="ControlID-1"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="scode">Parent Code</label>
                      <input
                        type="text"
                        id="scode"
                        defaultValue={
                          location?.state?.client?.parentUserName?.split(
                            " "
                          )[0]
                        }
                        placeholder=""
                        readOnly
                        disabled
                        className="form-control"
                        control-id="ControlID-2"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="code">Code</label>
                      <input
                        type="text"
                        id="code"
                        placeholder=""
                        readOnly
                        className="form-control"
                        name="code"
                        defaultValue={location?.state?.client?.username}
                        control-id="ControlID-3"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        min={2}
                        required=""
                        name="name"
                        defaultValue="dho 04"
                        control-id="ControlID-4"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            name: e.target.value,
                          })
                        }
                        value={payload?.name}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Reference</label>
                      <input
                        type="text"
                        id="reference"
                        className="form-control"
                        placeholder="Reference"
                        required=""
                        name="reference"
                        defaultValue="self"
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            reference: e.target.value,
                          })
                        }
                        value={payload?.reference}
                        control-id="ControlID-5"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <div className="input-group ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          min={6}
                          id="password"
                          required=""
                          value={payload?.password}
                          name="password"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              password: e.target.value,
                            })
                          }
                          control-id="ControlID-6"
                        />
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-info btn-flat"
                            onClick={generatePassword}
                            control-id="ControlID-7"
                          >
                            Generate Password
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Contact No</label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobile"
                        placeholder="Mobile No"
                        required=""
                        name="contactno"
                        defaultValue={0}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            phone: e.target.value,
                          })
                        }
                        value={payload?.phone}
                        control-id="ControlID-8"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="casinoCheck">Casino Play</label>
                      <select
                        id="casinoCheck"
                        className="form-control"
                        name="casinoCheck"
                        control-id="ControlID-10"
                        value={clientData?.isCasinoBetLocked}
                        onChange={handleCasinoChange}
                      >
                        <option value={false} selected="selected">
                          Yes
                        </option>
                        <option value={true}>No</option>
                      </select>
                      </div>
                      <div className="form-group">
                      <label htmlFor="casinoCheck">Matka Play</label>
                      <select
                        id="casinoCheck"
                        className="form-control"
                        name="casinoCheck"
                        control-id="ControlID-10"
                        value={clientData?.isCasinoBetLocked}
                        onChange={(e)=>handleCasinoChange(e,true)}
                      >
                        <option value={false} selected="selected">
                          Yes
                        </option>
                        <option value={true}>No</option>
                      </select>
                    </div>
                    {/*  <div className="form-group">
                      <label htmlFor="flat">Flat Share</label>
                      <select
                        className="form-control"
                        id="flat"
                        name="sharestatus"
                        control-id="ControlID-9"
                      >
                        <option value="false">No</option>
                        <option value="true" selected="selected">
                          Yes
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="casinoCheck">Casino Play</label>
                      <select
                        id="casinoCheck"
                        className="form-control"
                        name="casinoCheck"
                        control-id="ControlID-10"
                      >
                        <option value={1} selected="selected">
                          Yes
                        </option>
                        <option value={0}>No</option>
                      </select>
                    </div> */}
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              <div className="col-md-6">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">Share and Commission</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                        control-id="ControlID-11"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">
                          {userType?.toLocaleUpperCase()} Share
                        </label>
                        <input
                          type="number"
                          min={0}
                          placeholder="Share"
                          className="form-control"
                          id="share"
                          step="0.01"
                          required=""
                          name="share"
                          control-id="ControlID-12"
                          value={payload?.myPartnership}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myPartnership: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="sashare">Parent Share On Agent</label>
                        <input
                          type="number"
                          placeholder="Share"
                          className="form-control"
                          value={clientData?.parentPartnership}
                          id="sashare"
                          disabled
                          readOnly
                          control-id="ControlID-13"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">
                          {userType?.toLocaleUpperCase()} Casino Share
                        </label>
                        <input
                          type="number"
                          min={0}
                          placeholder="Casino Share"
                          className="form-control"
                          id="cshare"
                          step="0.01"
                          required=""
                          name="cshare"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myCasinoPartnership: e.target.value,
                            })
                          }
                          value={payload?.myCasinoPartnership}
                          control-id="ControlID-14"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="csashare">
                          Parent Casino Share On Agent
                        </label>
                        <input
                          type="number"
                          placeholder="Share"
                          className="form-control"
                          value={clientData?.parentCasinoPartnership}
                          disabled
                          readOnly
                          id="csashare"
                          control-id="ControlID-15"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">
                          {userType?.toLocaleUpperCase()} Matka Share
                        </label>
                        <input
                          type="number"
                          min={0}
                          placeholder="Casino Share"
                          className="form-control"
                          id="cshare"
                          step="0.01"
                          required=""
                          name="cshare"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              myMatkaPartnership: e.target.value,
                            })
                          }
                          value={payload?.myMatkaPartnership}
                          control-id="ControlID-14"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="csashare">
                          Parent Matka Share On Agent
                        </label>
                        <input
                          type="number"
                          placeholder="Share"
                          className="form-control"
                          value={clientData?.parentMatkaPartnership}
                          disabled
                          readOnly
                          id="csashare"
                          control-id="ControlID-15"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="session_commission_type">
                        Commission Type
                      </label>
                      <select
                        id="session_commission_type"
                        className="form-control"
                        control-id="ControlID-20"
                        onChange={(e) => {
                          if (e.target.value === "1") {
                            setPayload({
                              ...payload,
                              matchCommission: 0,
                              sessionCommission: 0,
                              casinoCommission: 0,
                            });
                          }
                        }}
                      >
                        <option value="1">No Comm</option>
                        <option value="0">Bet by Bet</option>
                      </select>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="match_commission">
                          Match Commision
                        </label>
                        <input
                          type="number"
                          value={payload?.matchCommission}
                          className="form-control"
                          placeholder="Match"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              matchCommission: e.target.value,
                            })
                          }
                          min={0}
                          max={clientData?.parentMatchCommission}
                          step="0.01"
                          id="match_commission"
                          required=""
                          name="matchcommission"
                          control-id="ControlID-21"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }{" "}
                          Match Commission
                        </label>
                        <input
                          id="mc"
                          type="text"
                          min={0}
                          value={clientData?.parentMatchCommission}
                          className="form-control"
                          readOnly
                          disabled
                          control-id="ControlID-22"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="session_commission">
                          Session Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Match"
                          min={0}
                          max={clientData?.parentSessionCommission}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              sessionCommission: e.target.value,
                            })
                          }
                          value={payload?.sessionCommission}
                          step="0.01"
                          id="session_commission"
                          required=""
                          name="sessioncommission"
                          control-id="ControlID-23"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="mc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }
                          Session Commission
                        </label>
                        <input
                          type="number"
                          defaultValue={3.0}
                          value={clientData?.parentSessionCommission}
                          id="sc"
                          className="form-control"
                          placeholder="Session Commission"
                          readOnly
                          disabled
                          control-id="ControlID-24"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="casino_commission">
                          Casino Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Match"
                          min={0}
                          max={clientData?.parentCasinoCommission}
                          step="0.01"
                          id="casino_commission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              casinoCommission: e.target.value,
                            })
                          }
                          value={payload?.casinoCommission}
                          required=""
                          name="casinocommission"
                          control-id="ControlID-25"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="cc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }
                          Casino Commission
                        </label>
                        <input
                          type="number"
                          defaultValue={2.0}
                          id="cc"
                          className="form-control"
                          placeholder="Casino Commission"
                          readOnly
                          disabled
                          value={clientData?.parentCasinoCommission}
                          control-id="ControlID-26"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="casino_commission">
                          Matka Commission
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Match"
                          min={0}
                          max={clientData?.parentMatkaCommission}
                          step="0.01"
                          id="casino_commission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              matkaCommission: e.target.value,
                            })
                          }
                          value={payload?.matkaCommission}
                          required=""
                          name="casinocommission"
                          control-id="ControlID-25"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="cc">
                          {
                            location?.state?.client?.parentUserName?.split(
                              " "
                            )[0]
                          }
                          Matka Commission
                        </label>
                        <input
                          type="number"
                          defaultValue={2.0}
                          id="cc"
                          className="form-control"
                          placeholder="Casino Commission"
                          readOnly
                          disabled
                          value={clientData?.parentMatkaCommission}
                          control-id="ControlID-26"
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <a href="/master" className="btn btn-secondary">
                Cancel
              </a>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-success float-right"
              >
                Update {userType}
              </button>
            </div>
          </div>
        </form>
        <br />
      </section>
    </div>
  );
}
