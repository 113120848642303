import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Select from "react-select";
import moment from "moment";
import { getMatkaBetsByIdAPI } from "../../service/matka/matka.service";

export default function MatkaBets() {
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [totalLength, setTotalLength] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const params = useParams();

  const [uniqueUser, setUniqueUser] = useState([]);
  var eventId = params?.id;

  const getClientBets = async (betStatus_) => {
    const { response } = await getMatkaBetsByIdAPI(
      eventId,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setUniqueUser(
        Array.from(new Set(response?.map((item) => item?.userName)))
      );
      if (selectedClient && selectedClient.value != "null") {
        let filteredData = response;
        filteredData = response.filter(
          (item) => item.userName == selectedClient.value
        );
        setClientBets(filteredData);
      } else {
        setClientBets(response);
      }

      const totalAmount = response?.reduce(
        (total, item) => total + item.amount,
        0
      );
      setTotalAmount(totalAmount);
    }
  };
  useEffect(() => {
    getClientBets(betStatus);
  }, [betStatus]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Bet Details </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Match Bet Details</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div id="myForm" method="post">
                  <div className="card-header ">
                    <div className="form-row col-md-6">
                      <table
                        className="table table-bordered table-striped"
                        data-select2-id={6}
                      >
                        <tbody className="text-bold">
                          {clientBets && clientBets?.length > 0 && (
                            <tr>
                              <th>Client</th>
                              <th data-select2-id={4}>
                                <Select
                                  required=""
                                  id="name"
                                  placeholder="Select Client"
                                  name="client"
                                  data-select2-id="name"
                                  tabIndex={-1}
                                  aria-hidden="true"
                                  options={uniqueUser?.map((item, index) => ({
                                    value: item,
                                    label: item,
                                  }))}
                                  value={selectedClient}
                                  onChange={(e) => setSelectedClient(e)}
                                />
                              </th>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <button
                        onClick={() => getClientBets(betStatus)}
                        type="button"
                        className="form-control btn btn-primary"
                        id="btn"
                        name="submit"
                        hidden=""
                        defaultValue="Sumbit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Client</th>
                                <th>Bet</th>
                                <th>Amount</th>
                                <th>Mode</th>
                                <th>Date &amp; Time</th>
                              </tr>
                              <tr>
                                <th
                                  colSpan={3}
                                  className="text-indigo text-lg text-center"
                                >
                                  Total Amount
                                </th>
                                <th>{totalAmount}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {clientBets?.map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.userName}</td>
                                    <td>{item.betNumber}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.betType}</td>

                                    <td>
                                      {moment(item.createdAt + "Z").format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
