import React from "react";

export default function DailyGameReport() {
  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>Games Daily Report</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a href="#">
                <strong>
                  <strong>Games Daily Report</strong>
                </strong>
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          style={{ margin: "11px 0px" }}
        >
          <div className="select-wrapper mdb-select colorful-select dropdown-primary md-form">
            <div className="form-group">
              <select
                className="js-select2 select2-hidden-accessible select-event-type"
                multiple=""
                tabIndex={-1}
                aria-hidden="true"
                style={{}}
              >
                <option value="selectall" data-badge="">
                  ALL
                </option>
                <option value="single_digit" selected="">
                  Dus Ka Dum
                </option>
                <option
                  value="rng"
                  data-event-sub-type="single-digit-live"
                  data-badge=""
                >
                  Dus Ka Dum Live
                </option>
                <option value="roulette">Roulette</option>
                <option
                  value="TeenPatti"
                  data-event-sub-type="TeenPatti-T-20"
                  data-badge=""
                  selected=""
                >
                  TeenPatti-T-20
                </option>
                <option
                  value="TeenPatti"
                  data-event-sub-type="TeenPatti-One-Day"
                  data-badge=""
                >
                  TeenPatti-One-Day
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-dragon-tiger"
                  data-badge=""
                >
                  Dragon Tiger
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-andar-bahar"
                  data-badge=""
                >
                  Andar Bahar
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-kqj"
                  data-badge=""
                >
                  KQJ
                </option>
                {/* <option value="card-game" data-event-sub-type="card-game-matka" data-badge="" >Matka</option> */}
                <option value="matka" data-event-sub-type="matka" data-badge="">
                  Matka
                </option>
                <option
                  value="card-game"
                  data-event-sub-type="card-game-se7enup-se7endown"
                  data-badge=""
                >
                  7Up-7Down
                </option>
                <option value="inside_outside">Inside Outside</option>
                <option value="triple_chance">Triple Chance</option>
                <option value="king_queen_jack">KingQueenJack</option>
              </select>
              <span
                className="select2 select2-container select2-container--default"
                dir="ltr"
                style={{ width: 1 }}
              >
                <span className="selection">
                  <span
                    className="select2-selection select2-selection--multiple"
                    role="combobox"
                    aria-haspopup="true"
                    aria-expanded="false"
                    tabIndex={-1}
                  >
                    <ul className="select2-selection__rendered">
                      <span className="select2-selection__clear">×</span>
                      <li
                        className="select2-selection__choice"
                        title="Dus Ka Dum"
                      >
                        <span
                          className="select2-selection__choice__remove"
                          role="presentation"
                        >
                          ×
                        </span>
                        Dus Ka Dum
                      </li>
                      <li
                        className="select2-selection__choice"
                        title="TeenPatti-T-20"
                      >
                        <span
                          className="select2-selection__choice__remove"
                          role="presentation"
                        >
                          ×
                        </span>
                        TeenPatti-T-20
                      </li>
                      <li className="select2-search select2-search--inline">
                        <input
                          className="select2-search__field"
                          type="search"
                          tabIndex={-1}
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          role="textbox"
                          aria-autocomplete="list"
                          placeholder=""
                          style={{ width: "0.75em" }}
                        />
                      </li>
                    </ul>
                  </span>
                </span>
                <span className="dropdown-wrapper" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
        <div
          id="reportrange"
          className="my_ledgers_dated_picker col-lg-3 col-md-4 col-sm-6 col-xs-11 pull-right"
        >
          <i className="glyphicon glyphicon-calendar fa fa-calendar" />
          &nbsp;
          <span> - </span>
          <b className="caret" />
        </div>
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          style={{ margin: "11px 0px" }}
        >
          <a
            className="btn btn-primary btn-xs search_date_btn"
            onclick="searchData()"
          >
            Search
          </a>
        </div>
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h5>Games Daily Report</h5>
              </div>
              <div className="ibox-content table-responsive">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Client Name</th>
                      <th>Ledger Title</th>
                      <th>Event Type</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody />
                </table>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Client Name</th>
                      <th>Ledger Title</th>
                      <th>Event type</th>
                      <th>Debit Total</th>
                      <th>Credit Total</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th colSpan={4} style={{ textAlign: "center" }}>
                        All Page Total
                      </th>
                      <th>0.0</th>
                      <th>-0.0</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
