import React from "react";

export default function ClientPlusMinus() {
  return (
    <div className="content-wrapper" style={{ minHeight: "1300.41px" }}>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Plus Minus</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Client Plus/Minus</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div method="post">
                  <input
                    type="hidden"
                    name="_csrf"
                    defaultValue="22793efe-eac3-40df-9f7b-5ba8b7ea28b6"
                  />
                  <div className="card-header bg-gradient-purple">
                    <h4>Client Report</h4>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="name">Client</label>
                        <select
                          className="form-control "
                          required=""
                          id="name"
                          placeholder="Select Client"
                          name="client"
                          data-select2-id="name"
                          tabIndex={-1}
                          aria-hidden="true"
                        >
                          <option value={346826}>C346826 Sivam Hardev</option>
                          <option value={347091}>C347091 Prince Dinesh</option>
                          <option
                            value={347264}
                            selected="selected"
                            data-select2-id={2}
                          >
                            C347264 Mishra ji 13 13 13
                          </option>
                          <option value={347378}>C347378 Manmooji</option>
                          <option value={347451}>C347451 Santosh Radha</option>
                          <option value={347455}>C347455 Kissan</option>
                          <option value={347850}>C347850 Raghu Dinesh</option>
                          <option value={348396}>C348396 Sonu suaabaag</option>
                          <option value={348422}>C348422 Sanjeev radha</option>
                          <option value={348424}>
                            C348424 Vijay Neeraj 131313
                          </option>
                          <option value={348425}>C348425 Arvind Neeraj</option>
                          <option value={348427}>C348427 Vishnu Radha</option>
                          <option value={348430}>
                            C348430 Veerander Pepsi
                          </option>
                          <option value={348434}>
                            C348434 Guddu suabaag 131313
                          </option>
                          <option value={348616}>C348616 Vishal Dinesh</option>
                          <option value={348617}>
                            C348617 Kherwender Tadha
                          </option>
                          <option value={348698}>
                            C348698 Girish adhanpur
                          </option>
                          <option value={348924}>C348924 Deepak 131313</option>
                          <option value={348937}>
                            C348937 Dayaram Dinesh 131313
                          </option>
                          <option value={349165}>C349165 Rohit Aman</option>
                          <option value={349217}>
                            C349217 Nasir 13 13 13 khan
                          </option>
                          <option value={349608}>C349608 Rajveer Dinesh</option>
                          <option value={349714}>C349714 Tinka Dinesh</option>
                          <option value={349854}>C349854 Heera 131313</option>
                          <option value={350060}>C350060 Ravi tyagi</option>
                          <option value={350311}>C350311 Madan Dinesh</option>
                          <option value={350395}>C350395 Bholu bhai</option>
                          <option value={350408}>
                            C350408 Munander Neeraj
                          </option>
                          <option value={350555}>C350555 Sonu suabaag</option>
                          <option value={350638}>
                            C350638 Ravinder Dinesh
                          </option>
                          <option value={350644}>C350644 Rohit D</option>
                          <option value={351147}>C351147 Banti sunil</option>
                          <option value={351306}>C351306 Santosh Dinesh</option>
                          <option value={351658}>C351658 Madan Dinesh </option>
                          <option value={351689}>C351689 Pavan </option>
                          <option value={351789}>
                            C351789 Sonu sorabh Rajpoot{" "}
                          </option>
                          <option value={351922}>
                            C351922 Sorabh Rajpoot{" "}
                          </option>
                          <option value={351940}>
                            C351940 Kharvender Radh
                          </option>
                          <option value={352598}>C352598 Sreekant Radha</option>
                          <option value={353105}>C353105 Aman </option>
                          <option value={353427}>C353427 Vijay Dinesh </option>
                          <option value={353551}>C353551 Alok suneel</option>
                          <option value={353853}>C353853 Vivek jain </option>
                          <option value={354112}>C354112 Rakesh Dinesh </option>
                          <option value={354433}>C354433 Sachin Dinesh </option>
                          <option value={354841}>C354841 Dinesh </option>
                          <option value={355099}>C355099 Vijay Dinesh </option>
                          <option value={355716}>
                            C355716 Ramsewak Dinesh
                          </option>
                          <option value={356522}>C356522 Veeru dinesh </option>
                          <option value={356990}>
                            C356990 Durgesh Dinesh{" "}
                          </option>
                          <option value={357119}>C357119 Suresh Naveen</option>
                          <option value={357507}>C357507 Pardeep dinesh</option>
                          <option value={358043}>
                            C358043 Ramniwas dinesh
                          </option>
                          <option value={358067}>C358067 Vineet dinesh</option>
                          <option value={358094}>C358094 Neeraj pal</option>
                          <option value={358243}>C358243 Mevaram Dinesh</option>
                          <option value={358427}>C358427 Pankaj</option>
                          <option value={358704}>C358704 Rajat tyagi</option>
                          <option value={359116}>C359116 Ramesh Dinesh </option>
                          <option value={359312}>C359312 Rajat tyagi </option>
                          <option value={359782}>
                            C359782 Devender rajpoot
                          </option>
                          <option value={359933}>C359933 Prema Dinesh </option>
                          <option value={360327}>C360327 Sanjeev radha</option>
                          <option value={360397}>C360397 Deewan odhi</option>
                          <option value={360486}>C360486 Mulayam Dinesh</option>
                          <option value={360500}>
                            C360500 Priyanka Dinesh
                          </option>
                          <option value={360694}>C360694 Ramu pachori</option>
                          <option value={361244}>C361244 Vishnu batham</option>
                          <option value={361783}>C361783 Amit krishna</option>
                          <option value={361937}>C361937 Rajesh dinesh</option>
                          <option value={362156}>C362156 WSA7</option>
                          <option value={362643}>
                            C362643 Vikramveer yogender{" "}
                          </option>
                          <option value={363245}>C363245 Ravinder radha</option>
                          <option value={364222}>
                            C364222 Ramniwas dinesh
                          </option>
                          <option value={364553}>
                            C364553 Prayanshu 131313
                          </option>
                          <option value={364916}>
                            C364916 Gagender Dinesh{" "}
                          </option>
                          <option value={365978}>C365978 Ashok rajput </option>
                          <option value={366385}>C366385 Vinit Dinesh 2</option>
                          <option value={367509}>C367509 Sivam Hardev</option>
                          <option value={368039}>C368039 Hardev </option>
                          <option value={368418}>C368418 Mullah </option>
                          <option value={369448}>C369448 Deepak tyagi</option>
                          <option value={370169}>C370169 Rahul </option>
                          <option value={370342}>C370342 Ajay seni</option>
                          <option value={370602}>
                            C370602 Vihari veerander{" "}
                          </option>
                          <option value={371559}>
                            C371559 Love lala thakur{" "}
                          </option>
                          <option value={372677}>
                            C372677 Ajay Akash edalpur
                          </option>
                          <option value={373946}>C373946 Ravi Dinesh </option>
                          <option value={375086}>C375086 Ashu jasupra</option>
                          <option value={375259}>C375259 Heera 13 13 13</option>
                          <option value={375328}>
                            C375328 Aakash edalpur 2nd
                          </option>
                          <option value={375572}>C375572 Sundar Dinesh </option>
                          <option value={377572}>
                            C377572 Sonu suaabaag b
                          </option>
                          <option value={377749}>C377749 Dilip 3</option>
                          <option value={378157}>C378157 Demo</option>
                          <option value={378378}>C378378 Hardev D</option>
                          <option value={379863}>C379863 Rahul tyagi</option>
                          <option value={379937}>C379937 Ajay seni</option>
                          <option value={380479}>
                            C380479 Edalpur Akash 2nd
                          </option>
                          <option value={380523}>C380523 Rahul tyagi</option>
                          <option value={380731}>
                            C380731 Doctor sua baag
                          </option>
                          <option value={382024}>C382024 Lala bhai</option>
                          <option value={383064}>
                            C383064 Ramji lal Dinesh{" "}
                          </option>
                          <option value={383291}>C383291 Suresh dinesh</option>
                          <option value={384048}>C384048 Ravi sangeet</option>
                          <option value={384209}>C384209 Deepak Dinesh </option>
                          <option value={385318}>
                            C385318 R k sangeet 13 13 13
                          </option>
                          <option value={385523}>
                            C385523 Gagender Dinesh{" "}
                          </option>
                          <option value={385858}>C385858 Pavan Edalpur</option>
                          <option value={386217}>C386217 Raghu Dinesh</option>
                          <option value={386590}>
                            C386590 Narottam edalpur
                          </option>
                          <option value={387869}>
                            C387869 Pardeep Adhanpir
                          </option>
                          <option value={388017}>C388017 Sammi</option>
                          <option value={388023}>C388023 Neeraj Radha</option>
                          <option value={388168}>C388168 Rinku Dinesh </option>
                          <option value={388382}>C388382 Prince Dinesh</option>
                          <option value={388745}>
                            C388745 Rambharan Dinesh{" "}
                          </option>
                          <option value={388989}>C388989 Amit krishna</option>
                          <option value={389505}>C389505 Naresh Dinesh</option>
                          <option value={389648}>
                            C389648 Ramsewak Dinesh{" "}
                          </option>
                          <option value={389810}>C389810 test</option>
                          <option value={390066}>
                            C390066 Ravi Mishra ji{" "}
                          </option>
                          <option value={390713}>C390713 Arjun Dinesh </option>
                          <option value={391117}>C391117 Jaisingh sonu</option>
                          <option value={391722}>C391722 Tundaa Dinesh </option>
                          <option value={392766}>C392766 Raju edalpur</option>
                          <option value={392836}>C392836 Edalpur Akash</option>
                          <option value={392939}>C392939 Ramdeen</option>
                          <option value={392971}>C392971 Kumarsain odhi</option>
                          <option value={393315}>C393315 Ravi tyagi 2</option>
                          <option value={393519}>C393519 Shera Dinesh </option>
                          <option value={393901}>C393901 Raaj Dinesh </option>
                          <option value={394862}>C394862 Ved sudhama</option>
                          <option value={394899}>C394899 Love kush </option>
                          <option value={395228}>
                            C395228 Shiv Dutt Radha
                          </option>
                          <option value={395590}>C395590 Rukka sangeet </option>
                          <option value={395760}>C395760 Ram 2</option>
                          <option value={396080}>
                            C396080 Ramdeen dinesh{" "}
                          </option>
                          <option value={396361}>C396361 Madan sonu</option>
                          <option value={397325}>
                            C397325 Ramkumar jharsingh
                          </option>
                          <option value={398041}>C398041 Rahul Dinesh </option>
                          <option value={398796}>C398796 Rajesh dinesh </option>
                          <option value={399414}>
                            C399414 Diwailal Dinesh{" "}
                          </option>
                          <option value={399722}>C399722 Janved Dinesh </option>
                          <option value={400102}>
                            C400102 Ramlakhan Dinesh{" "}
                          </option>
                          <option value={400300}>C400300 Sheru tyagi</option>
                          <option value={400419}>C400419 Virju Dinesh </option>
                          <option value={400696}>
                            C400696 Devendra Sanjay{" "}
                          </option>
                          <option value={401511}>C401511 Pp </option>
                          <option value={402521}>
                            C402521 Himanshu krishna
                          </option>
                          <option value={404229}>C404229 Bhola Dinesh</option>
                          <option value={406371}>C406371 Neeraj Radha</option>
                          <option value={406450}>
                            C406450 Brajesh Dinesh{" "}
                          </option>
                          <option value={406597}>
                            C406597 Ramdeen dinesh 13 13 13
                          </option>
                          <option value={406709}>
                            C406709 Himanshu krishna
                          </option>
                          <option value={407619}>
                            C407619 Pavan basainawab 131313
                          </option>
                          <option value={408499}>C408499 Madan 03</option>
                          <option value={408519}>C408519 Dinesh</option>
                          <option value={409019}>
                            C409019 Ramlakhan Dinesh
                          </option>
                          <option value={409058}>C409058 Tunda Dinesh </option>
                          <option value={409172}>C409172 Ravinder Radha</option>
                          <option value={409344}>C409344 Sakuni Dinesh</option>
                          <option value={409508}>C409508 Barjesh Dinesh</option>
                          <option value={410565}>C410565 Janved Dinesh </option>
                          <option value={410751}>C410751 Rajesh Dinesh</option>
                          <option value={410954}>C410954 Golu</option>
                          <option value={411521}>C411521 Delhi</option>
                          <option value={411522}>C411522 Ravi</option>
                          <option value={411523}>C411523 Pavan </option>
                          <option value={411555}>C411555 Chotu </option>
                          <option value={413287}>C413287 Dilip Dinesh </option>
                          <option value={413852}>
                            C413852 Mishra ji 13 13 13
                          </option>
                          <option value={413860}>C413860 Sanjeev Radha</option>
                          <option value={414029}>C414029 Sonu suaabaag</option>
                          <option value={414078}>
                            C414078 Ashutosh 13 13 13
                          </option>
                          <option value={416048}>C416048 Ravi tyagi</option>
                          <option value={416049}>C416049 Dayaram Dinesh</option>
                          <option value={416325}>C416325 Kaliya Mukesh </option>
                          <option value={416616}>C416616 Bhola Dinesh </option>
                          <option value={416792}>C416792 Suresh Dinesh</option>
                          <option value={417143}>
                            C417143 Sobharam 13 13 13
                          </option>
                          <option value={417636}>C417636 Pillu Dinesh </option>
                          <option value={418444}>C418444 Akko sharma</option>
                          <option value={418649}>C418649 Pankaj dinesh</option>
                          <option value={418812}>C418812 Manu Dinesh </option>
                          <option value={419144}>C419144 Raju Edalpur</option>
                          <option value={420316}>C420316 Bittu Lakhan</option>
                          <option value={421129}>
                            C421129 Ramjilal Dinesh{" "}
                          </option>
                          <option value={421334}>
                            C421334 Deewan Odhi ka pura
                          </option>
                          <option value={422100}>C422100 Naresh Dinesh </option>
                          <option value={423032}>
                            C423032 Gagender Dinesh{" "}
                          </option>
                          <option value={423035}>C423035 Raghu Dinesh </option>
                          <option value={423533}>C423533 Firoj </option>
                          <option value={424828}>C424828 Ajay seni</option>
                          <option value={425285}>C425285 Suraj Dinesh </option>
                          <option value={426645}>C426645 Sachin Rajput </option>
                          <option value={426819}>C426819 Dinesh </option>
                          <option value={427571}>
                            C427571 Neeraj paal 13 13 13
                          </option>
                          <option value={427652}>C427652 dhh</option>
                          <option value={427745}>C427745 demo</option>
                          <option value={430003}>C430003 Govind </option>
                          <option value={431374}>
                            C431374 Ravi tyagi 13 13 13
                          </option>
                          <option value={431943}>C431943 Janved Radh</option>
                          <option value={433170}>C433170 Dinesh chand</option>
                          <option value={435560}>C435560 Naveen</option>
                        </select>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group">
                          <label htmlFor="from">Date From</label>
                          <div
                            className="input-group date"
                            id="reservationdateFrom"
                            data-target-input="nearest"
                          >
                            <input
                              type="text"
                              className="form-control datetimepicker-input"
                              id="from"
                              data-target="#reservationdateFrom"
                              name="from"
                              defaultValue="2023-12-11"
                            />
                            <div
                              className="input-group-append"
                              data-target="#reservationdateFrom"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <div className="form-group">
                          <label htmlFor="to">Date To</label>
                          <div
                            className="input-group date"
                            id="reservationdateTo"
                            data-target-input="nearest"
                          >
                            <input
                              type="text"
                              className="form-control datetimepicker-input"
                              id="to"
                              data-target="#reservationdateTo"
                              name="to"
                              defaultValue="2024-03-21"
                            />
                            <div
                              className="input-group-append"
                              data-target="#reservationdateTo"
                              data-toggle="datetimepicker"
                            >
                              <div className="input-group-text">
                                <i className="fa fa-calendar" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <label
                          className="control-label text-purple"
                          htmlFor="btn"
                        >
                          `
                        </label>
                        <input
                          type="submit"
                          className="form-control btn-primary"
                          id="btn"
                          name="submit"
                          defaultValue="Sumbit"
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div>
                      <table
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th colSpan={2} />
                            <th colSpan={3}>Match</th>
                            <th colSpan={3}>Session</th>
                            <th colSpan={2}>Other Amount</th>
                            <th />
                          </tr>
                          <tr>
                            <th> Date</th>
                            <th>Match Name</th>
                            <th>Match</th>
                            <th>Session</th>
                            <th>Total</th>
                            <th>Match</th>
                            <th>Session</th>
                            <th>Total</th>
                            <th>Mob</th>
                            <th>Final</th>
                            <th>Balance</th>
                          </tr>
                          <tr>
                            <th className="text-right" colSpan={10}>
                              Opening Balance
                            </th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2023-12-11 13:45:00.0</td>
                            <td>HOBART HURRICANES V SYDNEY SIXERS</td>
                            <td>240.00</td>
                            <td>6,440.00</td>
                            <td>6,680.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>6,680.00</td>
                            <td>6,680.00</td>
                          </tr>
                          <tr>
                            <td>2023-12-14 20:30:00.0</td>
                            <td>SOUTH AFRICA V INDIA</td>
                            <td>3,800.00</td>
                            <td>200.00</td>
                            <td>4,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>4,000.00</td>
                            <td>10,680.00</td>
                          </tr>
                          <tr>
                            <td>2023-12-17 13:30:00.0</td>
                            <td>SOUTH AFRICA V INDIA</td>
                            <td>0.00</td>
                            <td>1,700.00</td>
                            <td>1,700.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>1,700.00</td>
                            <td>12,380.00</td>
                          </tr>
                          <tr>
                            <td>2023-12-17 12:45:00.0</td>
                            <td>ZIMBABWE V IRELAND</td>
                            <td>0.00</td>
                            <td>3,300.00</td>
                            <td>3,300.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>3,300.00</td>
                            <td>15,680.00</td>
                          </tr>
                          <tr>
                            <td>2023-12-19 13:45:00.0</td>
                            <td>ADELAIDE STRIKERS V SYDNEY THUNDER</td>
                            <td>0.00</td>
                            <td>3,000.00</td>
                            <td>3,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>3,000.00</td>
                            <td>18,680.00</td>
                          </tr>
                          <tr>
                            <td>2023-12-19 16:30:00.0</td>
                            <td>SOUTH AFRICA V INDIA</td>
                            <td>30.00</td>
                            <td>2,970.00</td>
                            <td>3,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>3,000.00</td>
                            <td>21,680.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-11 19:00:00.0</td>
                            <td>INDIA V AFGHANISTAN</td>
                            <td>0.00</td>
                            <td>-1,700.00</td>
                            <td>-1,700.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-1,700.00</td>
                            <td>19,980.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-11 21:00:00.0</td>
                            <td>DURBAN SUPER GIANTS V MI CAPE TOWN</td>
                            <td>-10,000.00</td>
                            <td>500.00</td>
                            <td>-9,500.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-9,500.00</td>
                            <td>10,480.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-12 08:00:00.0</td>
                            <td>NORTHERN BRAVE V AUCKLAND</td>
                            <td>0.00</td>
                            <td>-2,200.00</td>
                            <td>-2,200.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-2,200.00</td>
                            <td>8,280.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-12 11:40:00.0</td>
                            <td>NEW ZEALAND V PAKISTAN</td>
                            <td>0.00</td>
                            <td>-6,000.00</td>
                            <td>-6,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-6,000.00</td>
                            <td>2,280.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-12 13:45:00.0</td>
                            <td>SYDNEY SIXERS V SYDNEY THUNDER</td>
                            <td>0.00</td>
                            <td>-2,700.00</td>
                            <td>-2,700.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-2,700.00</td>
                            <td>-420.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-12 21:00:00.0</td>
                            <td>PAARL ROYALS V PRETORIA CAPITALS</td>
                            <td>0.00</td>
                            <td>-3,940.00</td>
                            <td>-3,940.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-3,940.00</td>
                            <td>-4,360.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-13 08:30:00.0</td>
                            <td>WELLINGTON V CENTRAL DISTRICTS</td>
                            <td>0.00</td>
                            <td>-4,500.00</td>
                            <td>-4,500.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-4,500.00</td>
                            <td>-8,860.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-13 10:45:00.0</td>
                            <td>PERTH SCORCHERS V BRISBANE HEAT</td>
                            <td>1,800.00</td>
                            <td>2,540.00</td>
                            <td>4,340.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>4,340.00</td>
                            <td>-4,520.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-13 14:00:00.0</td>
                            <td>MELBOURNE RENEGADES V MELBOURNE STARS</td>
                            <td>0.00</td>
                            <td>200.00</td>
                            <td>200.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>200.00</td>
                            <td>-4,320.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-13 17:00:00.0</td>
                            <td>JOHANNESBURG SUPER KINGS V MI CAPE TOWN</td>
                            <td>2,200.00</td>
                            <td>10,800.00</td>
                            <td>13,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>13,000.00</td>
                            <td>8,680.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-13 21:00:00.0</td>
                            <td>
                              SUNRISERS EASTERN CAPE V DURBAN SUPER GIANTS
                            </td>
                            <td>2,100.00</td>
                            <td>5,000.00</td>
                            <td>7,100.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>7,100.00</td>
                            <td>15,780.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-14 08:30:00.0</td>
                            <td>AUCKLAND HEARTS WOMEN V OTAGO SPARKS WOMEN</td>
                            <td>0.00</td>
                            <td>4,840.00</td>
                            <td>4,840.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>4,840.00</td>
                            <td>20,620.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-14 11:40:00.0</td>
                            <td>NEW ZEALAND V PAKISTAN</td>
                            <td>0.00</td>
                            <td>-1,930.00</td>
                            <td>-1,930.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-1,930.00</td>
                            <td>18,690.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-14 13:45:00.0</td>
                            <td>SYDNEY THUNDER V ADELAIDE STRIKERS</td>
                            <td>0.00</td>
                            <td>6,990.00</td>
                            <td>6,990.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>6,990.00</td>
                            <td>25,680.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-20 17:00:00.0</td>
                            <td>
                              DURBAN SUPER GIANTS V SUNRISERS EASTERN CAPE
                            </td>
                            <td>0.00</td>
                            <td>4,950.00</td>
                            <td>4,950.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>4,950.00</td>
                            <td>30,630.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-20 20:00:00.0</td>
                            <td>DUBAI CAPITALS V MI EMIRATES</td>
                            <td>0.00</td>
                            <td>-6,000.00</td>
                            <td>-6,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-6,000.00</td>
                            <td>24,630.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-20 21:00:00.0</td>
                            <td>
                              JOHANNESBURG SUPER KINGS V PRETORIA CAPITALS
                            </td>
                            <td>0.00</td>
                            <td>-2,000.00</td>
                            <td>-2,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-2,000.00</td>
                            <td>22,630.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-21 16:00:00.0</td>
                            <td>DESERT VIPERS V ABU DHABI KNIGHT RIDERS</td>
                            <td>0.00</td>
                            <td>-1,950.00</td>
                            <td>-1,950.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-1,950.00</td>
                            <td>20,680.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-21 19:00:00.0</td>
                            <td>PAARL ROYALS V MI CAPE TOWN</td>
                            <td>0.00</td>
                            <td>8,900.00</td>
                            <td>8,900.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>8,900.00</td>
                            <td>29,580.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-21 20:00:00.0</td>
                            <td>GULF GIANTS V MI EMIRATES</td>
                            <td>100.00</td>
                            <td>17,700.00</td>
                            <td>17,800.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>17,800.00</td>
                            <td>47,380.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-27 16:00:00.0</td>
                            <td>ABU DHABI KNIGHT RIDERS V DESERT VIPERS</td>
                            <td>0.00</td>
                            <td>500.00</td>
                            <td>500.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>500.00</td>
                            <td>47,880.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-27 17:00:00.0</td>
                            <td>SUNRISERS EASTERN CAPE V MI CAPE TOWN</td>
                            <td>0.00</td>
                            <td>-6,000.00</td>
                            <td>-6,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-6,000.00</td>
                            <td>41,880.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-27 20:00:00.0</td>
                            <td>GULF GIANTS V DUBAI CAPITALS</td>
                            <td>0.00</td>
                            <td>6,000.00</td>
                            <td>6,000.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>6,000.00</td>
                            <td>47,880.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-28 09:00:00.0</td>
                            <td>AUCKLAND V CANTERBURY</td>
                            <td>0.00</td>
                            <td>1,300.00</td>
                            <td>1,300.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>1,300.00</td>
                            <td>49,180.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-25 09:30:00.0</td>
                            <td>INDIA V ENGLAND</td>
                            <td>0.00</td>
                            <td>2,750.00</td>
                            <td>2,750.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>2,750.00</td>
                            <td>51,930.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-28 16:00:00.0</td>
                            <td>MI EMIRATES V ABU DHABI KNIGHT RIDERS</td>
                            <td>0.00</td>
                            <td>4,945.00</td>
                            <td>4,945.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>4,945.00</td>
                            <td>56,875.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-28 19:00:00.0</td>
                            <td>DURBAN SUPER GIANTS V PAARL ROYALS</td>
                            <td>900.00</td>
                            <td>-6,295.00</td>
                            <td>-5,395.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-5,395.00</td>
                            <td>51,480.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-28 20:00:00.0</td>
                            <td>DESERT VIPERS V SHARJAH WARRIORS</td>
                            <td>3,400.00</td>
                            <td>5,000.00</td>
                            <td>8,400.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>8,400.00</td>
                            <td>59,880.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-29 18:00:00.0</td>
                            <td>KHULNA TIGERS V DURDANTO DHAKA</td>
                            <td>0.00</td>
                            <td>4,500.00</td>
                            <td>4,500.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>4,500.00</td>
                            <td>64,380.00</td>
                          </tr>
                          <tr>
                            <td>2024-01-29 20:00:00.0</td>
                            <td>SHARJAH WARRIORS V DUBAI CAPITALS</td>
                            <td>0.00</td>
                            <td>500.00</td>
                            <td>500.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>500.00</td>
                            <td>64,880.00</td>
                          </tr>
                          <tr>
                            <td>2024-02-07 20:00:00.0</td>
                            <td>ABU DHABI KNIGHT RIDERS V SHARJAH WARRIORS</td>
                            <td>0.00</td>
                            <td>-1,700.00</td>
                            <td>-1,700.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>-1,700.00</td>
                            <td>63,180.00</td>
                          </tr>
                          <tr>
                            <td>2024-02-07 21:00:00.0</td>
                            <td>PAARL ROYALS V JOHANNESBURG SUPER KINGS</td>
                            <td>0.00</td>
                            <td>9,700.00</td>
                            <td>9,700.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>9,700.00</td>
                            <td>72,880.00</td>
                          </tr>
                          <tr className="text text-lg text-bold">
                            <td>Total</td>
                            <td />
                            <td>4,570.00</td>
                            <td>68,310.00</td>
                            <td>72,880.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>0.00</td>
                            <td>72,880.00</td>
                            <td />
                          </tr>
                        </tbody>
                        <tfoot />
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
