import React, { useEffect } from "react";
import { getCurrentDateTimeLocal } from "../utils/constants";
import useSound from "use-sound";

import ping from "../assets/ping.mp3";
import moment from "moment";
import { unSettledBetsAPI } from "../service/AuthService";
import { Button, Row } from "react-bootstrap";
export default function CurrentBets() {
  const [sport, setSport] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(500);
  const [data, setData] = React.useState([]);
  const [search_, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState("All");
  const [runnerNames, setRunnerNames] = React.useState([]);
  const [eventName, setEventName] = React.useState([]);
  const [play] = useSound(ping);
  const getBetHistory = async (userName) => {
    let payload = {
      betStatus: sport,
      pageNumber: page,
      pageSize: size,
      gameType: filter.market ? filter.market : undefined,
    };

    if (search_.length > 0) {
      payload["search"] = userName;
    }
    const {
      response: { content },
    } = await unSettledBetsAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,
      bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
      "p/l": item.profit ? item.profit : item.loss,
    }));
    let data__ = JSON.parse(localStorage.getItem("betHistory"));
    if (localStorage.getItem("betHistory")) {
      if (content[0]?.created_date != data__[0]?.created_date) {
        sendNotification(
          "New Bet Placed",
          `Bet Placed on ${content[0]?.event_name} by ${content[0]?.userName}`
        );
        play();
      }
    }
    localStorage.setItem("betHistory", JSON.stringify(data_));

    setRunnerNames(
      Array.from(new Set(data_?.map((item) => item?.runner_name)))
    );
    setEventName(Array.from(new Set(data_?.map((item) => item?.event_name))));

    setData(data_);
  };
  useEffect(() => {
    getBetHistory(search_);
  }, [size, page, search_]);
  const handleSubmit = () => {
    let data_ = [...data];
    if (filter?.event_name) {
      data_ = data_.filter((item) => item?.event_name == filter?.event_name);
    }
    if (filter.market == "FANCY") {
      data_ = data_.filter((item) => item?.marketName != "BOOKMAKER_ODDS_1");
    }
    if (filter.market == "MATCH") {
      data_ = data_.filter((item) => item?.marketName == "BOOKMAKER_ODDS_1");
    }

    if (filter?.runner_name) {
      data_ = data_.filter(
        (item) => item?.runner_name == filter?.runner_name
      );
    }

    setData(data_);
  };

  const nextPage = () => {
    if (data.length > 0) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const sendNotification = (title, body) => {
    play();
    if (Notification.permission === "granted") {
      new Notification(title, {
        body,
      });
    }
  };
  const enableNotification = () => {
    console.log("enableNotification");
    Notification.requestPermission();
  };
  const changeFilter = (e) => {};
  return (
    <>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="mb-3">Current Bets</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/">Report</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="/game/inPlay">Current Bets</a>
                    </li>
                  </ol>
                </div>
              </div>

              <div className="row">
                <Row>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Team Name</label>
                      <select
                        className="form-control"
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            event_name: e.target.value,
                          })
                        }
                      >
                        <option value={""}>All</option>
                        {eventName?.map((item, index) => (
                          <option value={item}>
                            {item +
                              " " +
                              moment(
                                data?.find(
                                  (item_) => item_?.event_name == item
                                )?.created_date + "Z"
                              )
                                ?.toDate()
                                .toLocaleString()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Market</label>
                      <select
                        className="form-control"
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            market: e.target.value,
                          })
                        }
                      >
                        <option value={""}>All</option>
                        <option value="FANCY">Fancy</option>
                        <option value="MATCH">Bookmaker</option>
                      </select>
                    </div>
                  </div>
                  {filter.market == "FANCY" && (
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Runner Name</label>
                        <select
                          className="form-control"
                          value={filter.runner_name}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              runner_name: e.target.value,
                            })
                          }
                        >
                          <option value={""}>All</option>
                          {Array.from(
                            new Set(
                              data
                                ?.filter((item) => {
                                  if (filter.event_name) {
                                    return (
                                      item?.event_name == filter.event_name
                                    );
                                  } else {
                                    return item;
                                  }
                                })
                                .map((item) => item?.runner_name)
                            )
                          )?.map((item, index) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </Row>
              </div>
              <Row
                style={{
                  marginBottom: "10px",
                }}
              >
                <Button onClick={handleSubmit}>Submit</Button>
                <Button
                  style={{
                    backgroundColor: "red",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setFilter({});
                    setRunnerNames([]);
                    setEventName([]);
                    getBetHistory();
                    window.location.reload();
                  }}
                >
                  Reset
                </Button>
              </Row>
            </div>
            <div className="">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ibox float-e-margins">
                    <div className="ibox-content table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>S.NO</th>
                            <th>EVENT TYPE</th>
                            <th>EVENT NAME</th>
                            <th>USERNAME</th>
                            <th>RUNNER NAME</th>
                            <th>BET TYPE</th>
                            <th>USER RATE</th>
                            <th>AMOUNT</th>
                            <th>PLACE DATE</th>
                            <th>MATCH DATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length == 0 && (
                            <tr className="odd">
                              <td
                                valign="top"
                                colSpan={10}
                                className="dataTables_empty"
                              >
                                No data available in table
                              </td>
                            </tr>
                          )}
                          {data.map((item, index) => {
                            return (
                              <tr role="row" className="odd" key={index}>
                                <td width="60px">{index + 1}</td>
                                <td className="sorting_1">
                                  {item.event_type}
                                </td>
                                <td>{item.event_name}</td>
                                <td>{item.userName}</td>
                                <td>{item.runner_name}</td>
                                <td>
                                  {item?.marketName == "BOOKMAKER_ODDS_1"
                                    ? item.bet_type == "Back"
                                      ? "KHAI"
                                      : "LAGAI"
                                    : item.bet_type == "Back"
                                    ? "Yes"
                                    : "No"}
                                </td>
                                <td>
                                  {item.back_run
                                    ? item.back_run
                                    : item.lay_run}
                                </td>
                                <td>{item.amount}</td>

                                <td>
                                  {moment(item.created_date + "Z")
                                    .utc()
                                    .local()
                                    .format("DD/MM/YYYY hh:mm:ss A")}
                                </td>
                                <td>
                                  {moment(item?.created_date)
                                    .utc()
                                    .format("DD-MM-YYYY HH:mm:ss")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="pagination">
                        <span onClick={prevPage} className="previous_page">
                          ← Previous
                        </span>{" "}
                        <em className="current text-blue">{page}</em>{" "}
                        <a className="" rel="next" onClick={() => nextPage()}>
                          {page + 1}
                        </a>{" "}
                        <a
                          className="next_page"
                          rel="next"
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        >
                          Next →
                        </a>
                      </div>
                      <div className="pull-right" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
