import React from "react";
import { useSelector } from "react-redux";

export default function Profile() {
    const userData=useSelector((state)=>state.account.userData);
  return (
    <div className="content-wrapper">
      <br />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              {/* Profile Image */}
              <div className="card card-primary card-outline">
                <div className="card-body box-profile">
                  <div className="text-center">
                    <img
                      className="profile-user-img img-fluid img-circle"
                      src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg"
                      alt="User profile picture"
                    />
                  </div>
                  <h3 className="profile-username text-center">
                    {userData?.id} {userData?.username}
                  </h3>
                  <p className="text-muted text-center">
                    {userData?.userType}
                  </p>
                  <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item">
                      <b>Share</b>{" "}
                      <a className="float-right">
                        {userData?.partnershipAndCommission?.myPartnership}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>Match Commission</b>{" "}
                      <a className="float-right">
                        {userData?.partnershipAndCommission?.matchCommission}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>Session Commission</b>{" "}
                      <a className="float-right">
                        {
                          userData?.partnershipAndCommission
                            ?.sessionCommission
                        }
                      </a>
                    </li>
                  </ul>
                  <a
                    href="/changePassword"
                    className="btn btn-primary btn-block"
                  >
                    <b>Change Password</b>
                  </a>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* About Me Box */}
            <div className="card card-primary col-lg-4">
              <div className="card-header">
                <h3 className="card-title">About Me</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <strong>
                  <i className="fas fa-id-card" /> Profile
                </strong>
                <p className="text-muted">
                  {userData?.id} {userData?.username}
                </p>
                <hr />
                <strong>
                  <i className="fas fa-coins" />{" "}
                  <label>Current Limit : {userData?.balance}</label>
                </strong>
                <hr />
                <strong>
                  <i className="fas fa-pencil-alt mr-1" /> Contact No
                </strong>
                <p className="text-muted">
                  <span className="tag tag-danger">94664</span>
                </p>
                <hr />
                <strong>
                  <i className="far fa-file-alt mr-1" /> Date Of Joining
                </strong>
                <p className="text-muted">2024-02-11 13:11:03.0</p>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
    </div>
  );
}
