import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";

import { getAnnouncementAPI, getUserDataAPI } from "../../service/AuthService";

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
 
  useEffect(() => {
    function handleResize() {
      setIsMobileUI(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add('sidebar-mini');
    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
    // document.body.classList.toggle('sidebar-collapse');
  };

  const handleMenuToggleMobile = () => {
    setMenuOpen(!isMenuOpen);
    if (isMobileUI) {
      document.body.classList.toggle('sidebar-open');
    }
  };

  const location = useLocation();
  const active = location.pathname.split("/")[1];
  const pathname = location.pathname;
  const isLedger = pathname.startsWith("/ledger");
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const _isMounted = useRef(true);
  const [announcement, setAnnouncement] = React.useState({});
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const openMobileDrawer = () => {
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: userData.isSideBarOpen ? false : true,
    });
  };
  const closeMobileDrawer = () => {
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: false,
    });
  };
  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);
  const getUserData = async () => {
    var { response, code } = await getUserDataAPI(userData.id);
    if (code === 200) {
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
    }
  };

  const getAnnouncement = async () => {
    const { response } = await getAnnouncementAPI();
    setAnnouncement(response);
  };

  const fetchProfile = () => {
    const interval = setInterval(getUserData, 5000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    getUserData();
    if (_isMounted.current) {
      fetchProfile();
      getAnnouncement();
    }
  }, []);


  if (!userData.token) {
    return (window.location.href = "/");
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link d_desk_btn"
            data-widget="pushmenu"
            href="#"
            role="button"
            onClick={handleMenuToggle}
          >
            <i className="fas fa-bars" />
          </a>
          <a
            className="nav-link text-center d_mobile_btn"
            // data-widget="pushmenu"
            href="#"
            role="button"
            onClick={handleMenuToggleMobile}
          >
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>
      <marquee>{announcement?.announcement}</marquee>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown user-menu">
          <a
            href="#"
            className="nav-link d-flex dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              style={{
                height: "40px",
                width: "40px",
              }}
              src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg"
              className="user-image img-circle elevation-2"
              alt="User Image"
            />
            <span className="d-none d-md-inline">{userData?.username}</span>
          </a>
          <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            {/* User image */}
            <li className="user-header bg-primary">
              <img
                style={{
                  height: "40px",
                  width: "40px",
                }}
                src="https://adminlte.io/themes/v3/dist/img/user2-160x160.jpg"
                className="img-circle elevation-2"
                alt="User Image"
              />
              <p>
                {userData?.username} {userData?.name}
              </p>
            </li>

            {/* Menu Footer*/}
            <li className="user-footer">
              <a href="/profile" className="btn btn-default btn-flat">
                Profile
              </a>
              <a
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/";
                }}
                href="#"
                className="btn btn-default btn-flat float-right"
                onclick="event.preventDefault(); document.getElementById('logout-form').submit();"
              >
                Sign out
              </a>
              <form
                id="logout-form"
                action="/logout"
                method="POST"
                className="d-none"
              >
                <input
                  type="hidden"
                  name="_csrf"
                  defaultValue="06b3041e-bf77-41b4-8b9f-7790f72e5839"
                />
              </form>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
