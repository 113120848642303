import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLedgerAndCoinBalance } from "../service/UserService";

export default function ClientDashboard() {
  const params = useParams();
  const location = useLocation();
  const [clientData, setClientData] = React.useState(
    location?.state?.client || {}
  );
  const getClientData = async () => {
    const { response } = await getLedgerAndCoinBalance(params?.id);
    console.log(response);
    setClientData({
      ...clientData,
      ...response,
    });
  };
  useEffect(() => {
    getClientData();
  }, [params?.id]);

  const navigate = useNavigate();
  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>{clientData?.userType}</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a
                href={`/agent/agents?role=${clientData?.userType?.toLowerCase()}`}
              >
                {clientData?.userType}
              </a>
            </li>
            <li>
              <strong>{clientData?.username}</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="ibox float-e-margins">
              <div className="ibox-title">
                <h1>{clientData?.userType} Dashboard</h1>
              </div>
              <div className="ibox-content table-responsive">
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  <a
                    onClick={() =>
                      navigate(
                        `/agent/users/${
                          params?.id
                        }/receive_cash_from_user_panel`,
                        {
                          state: { client: clientData },
                        }
                      )
                    }
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Recieve Cash
                    </button>
                  </a>
                  <a
                    onClick={() =>
                      navigate(
                        `/agent/users/${params?.id}/pay_cash_to_user_panel`,
                        {
                          state: { client: clientData },
                        }
                      )
                    }
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Pay Cash
                    </button>
                  </a>
                  <a
                    onClick={() =>
                      navigate(`/agent/users/${params?.id}/ledger_show`, {
                        state: { client: clientData },
                      })
                    }
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Ledger
                    </button>
                  </a>
                  <a
                    onClick={() =>
                      navigate(`/agent/users/${params?.id}/cash_ledger`, {
                        state: { client: clientData },
                      })
                    }
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Cash Ledger
                    </button>
                  </a>
                  <a
                    onClick={() =>
                      navigate(`/agent/users/${params?.id}/changelog`, {
                        state: { client: clientData },
                      })
                    }
                    href={`/agent/users/${params?.id}/changelog`}
                  >
                    <button type="button" className="btn btn-primary btn-lg">
                      Coin History
                    </button>
                  </a>
                  {clientData?.userType != "CLIENT" && (
                    <>
                      {" "}
                      <a>
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Match Ledger
                        </button>
                      </a>
                      <a
                        onClick={() =>
                          navigate(`/agent/users?users=agent`, {
                            state: {
                              userName: clientData?.username,
                              showButtons: false,
                            },
                          })
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Direct Agents
                        </button>
                      </a>
                      <a
                        onClick={() =>
                          navigate(`/agent/users?users=client`, {
                            state: {
                              userName: clientData?.username,
                              showButtons: false,
                            },
                          })
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                        >
                          Direct Clients
                        </button>
                      </a>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Coins</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">{clientData?.balance}</h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Ledger Balance</h5>
            </div>
            <div className="ibox-content">
              <h1 className="no-margins">{clientData?.ledgerBalance}</h1>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5 />
            </div>
            <div className="ibox-content">
              <h1 className="no-margins" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
