import React from "react";
import { ChangeClientPasswordAPI } from "../service/UserService";
import { Encrypt } from "../utils/constants";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function ClientChangePassword() {
  const [payload, setPayload] = React.useState({});
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const userType = params?.userType;
  const changePassword = async () => {
    if (payload.newPassword !== payload.confirmPassword)
      return toast.error("Password and Confirm Password must be same");
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z!@#$%^&*]{8,}$/.test(
        payload.newPassword
      )
    )
      return toast.error(
        "Password must be at least 8 characters long and contains 1 alphabet, 1 number and one caps and one small letter"
      );
    const { response, code, status } = await ChangeClientPasswordAPI(
      {
        newPassword: Encrypt(payload.newPassword),
        transactionPassword: payload.transactionPassword,
        resetRequired: true,
      },
      params.id
    );
    if (code == 200) {
      toast.success(status);
      setPayload({});
      navigate(`/agent/users?users=${params?.userType}`);
    } else {
    }
  };
  return (
    <>
      <section id="main" style={{ minHeight: 481 }}>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
            <h2>Change Password</h2>
            <ol className="breadcrumb">
              <li>
                <a href="/agent/dashboard">Dashboard</a>
              </li>
              <li>
                <a href={`/agent/users?users=${params?.userType}`}>User</a>
              </li>
              <li className="active">
                <strong>{params?.userType}</strong>
              </li>
            </ol>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <h5>Change Password</h5>
                </div>
                <div
                  className="ibox-content table-responsive"
                  style={{ padding: "15px 20px 56px" }}
                >
                  <div className="form-horizontal" id="edit_user_4">
                    <div className="hr-line-dashed" />
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        NEW PASSWORD
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          id="new_password"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              newPassword: e.target.value,
                            })
                          }
                          name="new_password"
                          className="form-control login_text_field"
                          placeholder="NEW PASSWORD"
                        />
                      </div>
                    </div>
                    <input
                      id="selected"
                      name="user_id"
                      type="hidden"
                      defaultValue={5363}
                    />
                    <div className="hr-line-dashed" />
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        Confirm Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              confirmPassword: e.target.value,
                            })
                          }
                          type="password"
                          id="password_confirmation"
                          name="password_confirmation"
                          className="form-control login_text_field"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-sm-4 col-sm-offset-2">
                      <a
                        className="btn btn-white"
                        href={`/agent/users?users=${userType?.toUpperCase()}`}
                      >
                        Cancel
                      </a>
                      <button
                        className="btn btn-primary change_password_btn"
                        type="submit"
                        onClick={changePassword}
                        id="submit create_bets"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="pull-right">
            Super Stockist Panel <strong>v1.2.0.0</strong>
          </div>
          <div>
            <strong>SKY7777</strong> | Powered By <strong> NICE Gaming </strong>{" "}
            | Copyright © 2014-2021{" "}
          </div>
        </div>
      </section>
    </>
  );
}
