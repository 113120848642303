import React from "react";

export default function MatkaPosition() {
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Matka Position</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-center">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h5 className="text-bold">Gaziabaad</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="col-md-4">
                    <table
                      align="left"
                      id="sessionsTable"
                      className="table table-striped  table-hover table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>ANDAR</th>
                          <th>PROFIT/LOSS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table
                      align="left"
                      id="sessionsTable"
                      className="table table-striped  table-hover table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>BAHAR</th>
                          <th>PROFIT/LOSS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td style={{ color: "red" }}>0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table
                      align="left"
                      id="sessionsTable"
                      className="table table-striped  table-hover table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>JODI</th>
                          <th>PROFIT/LOSS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>10</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>11</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>12</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>13</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>14</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>15</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>16</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>17</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>18</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>19</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>20</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>21</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>22</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>23</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>24</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>25</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>26</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>27</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>28</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>29</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>30</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>31</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>32</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>33</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>34</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>35</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>36</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>37</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>38</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>39</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>40</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>41</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>42</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>43</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>44</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>45</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>46</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>47</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>48</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>49</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>50</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>51</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>52</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>53</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>54</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>55</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>56</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>57</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>58</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>59</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>60</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>61</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>62</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>63</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>64</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>65</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>66</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>67</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>68</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>69</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>70</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>71</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>72</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>73</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>74</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>75</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>76</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>77</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>78</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>79</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>80</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>81</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>82</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>83</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>84</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>85</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>86</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>87</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>88</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>89</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>90</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>91</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>92</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>93</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>94</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>95</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>96</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>97</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>98</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>99</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>00</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>01</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>02</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>03</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>04</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>05</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>06</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>07</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>08</td>
                          <td style={{ color: "red" }} />
                        </tr>
                        <tr>
                          <td>09</td>
                          <td style={{ color: "red" }} />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
