import React, { useEffect, useState } from "react";
import { getAllMatchesAPI } from "../service/AuthService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function InPlayList({ completeGame }) {
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const userData = useSelector((state) => state.account.userData);
  const getAllMatches = async () => {
    const { response } = await getAllMatchesAPI(currentPage, completeGame);
    setMatches(response.content);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllMatches();
  }, [currentPage, completeGame]);

  return (
    <>
      <div className="content-wrapper" style={{ minHeight: "1263.44px" }}>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Games</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Inplay</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <form action="#" method="post" id="demoForm">
                    <div className="card-header">
                      <h2 className="card-title">Sport Details</h2>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <table
                        id="example1"
                        className="table table-bordered table-striped "
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>SNo</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Date Time</th>
                            <th>Match Type</th>

                            {completeGame && <th>Declared</th>}
                            {completeGame && <th>Won By</th>}
                            {completeGame && <th>Profit Loss</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {matches.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div align="center">
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className={
                                        completeGame
                                          ? "btn btn-sm  btn-primary dropdown-toggle dropdown-hover dropdown-icon"
                                          : "btn  btn-outline-primary dropdown-toggle dropdown-hover dropdown-icon"
                                      }
                                      data-toggle="dropdown"
                                    >
                                      <span className="sr-only">
                                        Toggle Dropdown
                                      </span>
                                    </button>
                                    <div className="dropdown-menu" role="menu">
                                      {!completeGame && (
                                        <a
                                          className="dropdown-item btn"
                                          href={`/game/matchPosition/${
                                            item?.id
                                          }`}
                                        >
                                          Match &amp; Session Position{" "}
                                        </a>
                                      )}
                                      <a
                                        className="dropdown-item btn"
                                        onClick={() => {
                                          navigate(
                                            `/game/sessionPlusMinusSelect/${
                                              item?.id
                                            }`,
                                            {
                                              state: { match: item },
                                            }
                                          );
                                        }}
                                      >
                                        {completeGame
                                          ? "Match and session plus minus"
                                          : "Session Plus Minus"}
                                      </a>
                                      <a
                                        className="dropdown-item btn"
                                        onClick={() => {
                                          navigate(
                                            `/game/displayMatchSession/${
                                              item?.id
                                            }`,
                                            {
                                              state: { match: item },
                                            }
                                          );
                                        }}
                                      >
                                        Display Match &amp; Session Bets{" "}
                                      </a>
                                      <a
                                        className="dropdown-item btn"
                                        onClick={() => {
                                          navigate(
                                            `/game/i/${
                                              item?.id
                                            }/viewMatchReport`,
                                            {
                                              state: { match: item },
                                            }
                                          );
                                        }}
                                      >
                                        Display Match Bets{" "}
                                      </a>
                                      <a
                                        onClick={() => {
                                          navigate(
                                            `/game/i/${
                                              item?.id
                                            }/viewSessionReport`,
                                            {
                                              state: { match: item },
                                            }
                                          );
                                        }}
                                        className="dropdown-item btn"
                                        href={`/game/i/${
                                          item?.id
                                        }/viewSessionReport`}
                                      >
                                        Display Session Bets{" "}
                                      </a>
                                      {userData?.userType == "SUPER_ADMIN" && (
                                        <a
                                          className="dropdown-item btn"
                                          onClick={() => {
                                            navigate(
                                              `/agent/dashboard/matches/${
                                                item?.id
                                              }/current_bets`,
                                              {
                                                state: { item },
                                              }
                                            );
                                          }}
                                        >
                                          View More Bets{" "}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{index + 1}</td>
                              <td>{item?.id}</td>
                              <td>{item?.eventName}</td>
                              <td>
                                <i className="fa fa-clock-o" />
                                &nbsp;{" "}
                                {moment(item?.eventDate).format("DD MMM HH:mm")}
                              </td>

                              <td>{item?.sport}</td>

                              {completeGame && (
                                <td>{item?.declared ? "Yes" : "No"}</td>
                              )}
                              {completeGame && <td>{item?.wonBy}</td>}

                              {completeGame && (
                                <td>
                                  {parseFloat(item?.profitLoss).toFixed(2)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                        <tfoot />
                      </table>
                      <ul className="pagination pagination-sm  mt-3 m-0 float-right">
                        <div>
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={() => {
                                if (currentPage == 1) return;
                                setCurrentPage(currentPage - 1);
                              }}
                            >
                              «
                            </a>
                          </li>
                        </div>
                        <li className="page-item active">
                          <a className="page-link" href="/client/0/?by=#">
                            {currentPage}
                          </a>
                        </li>
                        <div>
                          <li className="page-item">
                            <a
                              className="page-link"
                              onClick={() => {
                                setCurrentPage(currentPage + 1);
                              }}
                            >
                              »
                            </a>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
