import {
  Box,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

export default function CustomTable({
  rows,
  columns,
  showPagination,
  backLay,
}) {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([]);

  React.useEffect(() => {
    if (rows) {
      setRowsPerPageOptions([5, 10, 20, 50, 100]);
      setRowsPerPage(50);
      setTotalPages(Math.ceil(rows.length / 5));
    }
  }, [rows, rowsPerPage]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <TableContainer>
      <Table
        sx={{
          tableLayout: "fixed",
        }}
        variant="simple"
      >
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th>{column.name}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows ? (
            rows
              .slice(
                currentPage * rowsPerPage,
                currentPage * rowsPerPage + rowsPerPage
              )
              .map((row) => (
                <Tr>
                  {columns.map((column) =>
                    column.element ? (
                      column.element(row)
                    ) : (
                      <Td>
                        {typeof row[column.id] == "string" ||
                        typeof row[column.id] == "number" ? (
                          <Text fontSize="sm">{row[column.id]}</Text>
                        ) : typeof row[column.id] == "boolean" ? (
                          <Switch
                            size="sm"
                            defaultChecked={
                              column.id == "isBetLocked"
                                ? !row[column.id]
                                : row[column.id]
                            }
                            isDisabled
                          />
                        ) : null}
                      </Td>
                    )
                  )}
                </Tr>
              ))
          ) : (
            <Tr>
              <Td colSpan={columns.length}>
                <Box textAlign="center">
                  <Text>No data</Text>
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
        {showPagination && (
          <Tfoot>
            <Tr>
              <Th colSpan={columns.length}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Text>Showing Page {currentPage}</Text>
                  <Box>
                    <button
                      style={{
                        margin: 5,
                      }}
                      className="btn"
                    >
                      <Text onClick={prevPage}>Prev</Text>
                    </button>
                    <button className="btn">
                      <Text onClick={nextPage}>Next</Text>
                    </button>
                  </Box>
                </Box>
              </Th>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
}
