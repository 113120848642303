import React, { useEffect, useState } from "react";
import { dashboardBannerAPI } from "../service/AuthService";
import { useDispatch } from "react-redux";
import store from "../redux/store";
import { Link } from "react-router-dom";
import { PERMISSION_MAP } from "../utils/constants";


export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const userData = store.getState().account.userData;
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getBannerImages();
  }, []);

  const userPermissions = PERMISSION_MAP[userData?.userType?.toUpperCase()];
  const renderComponent = (link, backgroundColor, text, number) => (
    <div className="col-lg-6 col-12" key={link}>
      <a href={link}></a>
      <div className={`small-box ${backgroundColor}`}>
        <a href={link}>
          <div className="inner" style={{ padding: 12 }}>
            <h3>{number}</h3>
            <p>{text}</p>
          </div>
          <div className="icon">
            <i className="ion ion-bag" />
          </div>
        </a>
        <a href={link} className="small-box-footer">
          More Info <i className="fas fa-arrow-circle-right" />
        </a>
      </div>
    </div>
  );

  return (

    <div className="content-wrapper" style={{ minHeight: "1263.44px" }}>
      <br />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6" data-toggle="modal" data-target="#modal-default">
              {/* small box */}
              <div className="small-box bg-primary">
                <div className="inner">
                  <h3>1</h3>
                  <p>Admin Details</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <a href="#" className="small-box-footer">
                  More Info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6" data-toggle="modal" data-target="#modal-gamedetail">
              {/* small box */}
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>2</h3>
                  <p>Games Details</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <a href="#" className="small-box-footer">
                  More Info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6" data-toggle="modal" data-target="#modal-cash">
              {/* small box */}
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>3</h3>
                  <p>Cash Transaction</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <a href="#" className="small-box-footer">
                  More Info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6" data-toggle="modal" data-target="#modal-ledger">
              {/* small box */}
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>4</h3>
                  <p>Ledger Details</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <a href="#" className="small-box-footer">
                  More Info <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /.content */}



      <section className="content">

        <div className="modal fade " id="modal-default">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" id="contentModel">

                  <>
                    {userPermissions &&
                      userPermissions.map((permission, index) => {
                        switch (permission) {
                          case "ADMIN":
                            return renderComponent("/admin", "bg-primary", "Admin", index + 1);
                          case "SUB_ADMIN":
                            return renderComponent("/sub_admin", "bg-primary", "Sub Admin", index + 1);
                          case "MASTER":
                            return renderComponent("/master", "bg-primary", "Master", index + 1);
                          case "SUPER_AGENT":
                            return renderComponent("/super_agent", "bg-success", "Super Agent", index + 1);
                          case "AGENT":
                            return renderComponent("/agent", "bg-warning", "Agent", index + 1);
                          case "CLIENT":
                            return renderComponent("/client", "bg-danger", "Client", index + 1);
                          default:
                            return null;
                        }
                      })}
                  </>

                </div>
              </div>
            </div>

          </div>
          {/* /.modal-dialog */}
        </div>

        <div className="modal fade " id="modal-gamedetail">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" id="contentModel">

                  <div className="col-lg-6 col-12" onclick="/master">

                    <div className="small-box bg-primary">
                      <a href="/game/inPlay">
                        <div className="inner" style={{ padding: 12 }}>
                          <h3>1</h3>
                          <p>InPlay</p>
                        </div>
                        <div className="icon">
                          <i className="ion ion-bag" />
                        </div>
                      </a>
                      <a href="/game/inPlay" className="small-box-footer">
                        More Info <i className="fas fa-arrow-circle-right" />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12" onclick="/master">
                    <div className="small-box bg-success">
                      <a href="/game/completeGame">
                        <div className="inner" style={{ padding: 12 }}>
                          <h3>2</h3>
                          <p>Complete</p>
                        </div>
                        <div className="icon">
                          <i className="ion ion-bag" />
                        </div>
                      </a>
                      <a href="/game/completeGame" className="small-box-footer">
                        More Info <i className="fas fa-arrow-circle-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* /.modal-dialog */}
        </div>

        <div className="modal fade " id="modal-cash">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" id="contentModel">
                  <>

                    {userPermissions &&
                      userPermissions.map((permission, index) => {
                        switch (permission) {
                          case "CLIENT":
                            return renderComponent("/ct/client", "bg-danger", "Debit/Credit (C)", index + 1);
                          case "AGENT":
                            return renderComponent("/ct/agent", "bg-warning", "Debit/Credit (A)", index + 1);
                          case "SUPER_AGENT":
                            return renderComponent("/ct/super_agent", "bg-success", "Debit/Credit (SA)", index + 1);
                          case "MASTER":
                            return renderComponent("/ct/master", "bg-primary", "Debit/Credit (MA)", index + 1);
                          case "SUB_ADMIN":
                            return renderComponent("/ct/sub_admin", "orange", "Debit/Credit Entry (SUB)", index + 1);
                          case "ADMIN":
                            return renderComponent("/ct/admin", "violet", "Debit/Credit Entry (AD)", index + 1);
                          default:
                            return null;
                        }
                      })}
                  </>
                </div>
              </div>
            </div>

          </div>
          {/* /.modal-dialog */}
        </div>

        <div className="modal fade " id="modal-ledger">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" id="contentModel">
                  <>
                    <div className="col-lg-6 col-12">
                      <a href="/ledger" />
                      <div className="small-box bg-primary">
                        <a href="/ledger">
                          <div className="inner" style={{ padding: 12 }}>
                            <h3></h3>
                            <p> My Ledger</p>
                          </div>
                          <div className="icon">
                            <i className="ion ion-bag" />
                          </div>
                        </a>
                        <a href="/ledger" className="small-box-footer">
                          More Info <i className="fas fa-arrow-circle-right" aria-hidden="true" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12"></div>

                    {userPermissions &&
                      userPermissions.map((permission, index) => {
                        switch (permission) {
                          case "CLIENT":
                            return renderComponent("/ledger/client", "bg-danger", "All CLIENT Ledger", index + 1);
                          case "AGENT":
                            return renderComponent("/ledger/agent", "bg-warning", "All AGENT Ledger", index + 1);
                          case "SUPER_AGENT":
                            return renderComponent("/ledger/super", "bg-success", "All Super Agent", index + 1);
                          case "MASTER":
                            return renderComponent("/ledger/master", "bg-primary", "All Master Ledger", index + 1);
                          case "SUB_ADMIN":
                            return renderComponent("/ledger/subadmin", "orange", "All Sub Admin Ledger", index + 1);
                          case "ADMIN":
                            return renderComponent("/ledger/admin", "violet", "All Admin Ledger", index + 1);
                          default:
                            return null;
                        }
                      })}
                  </>
                </div>
              </div>
            </div>

          </div>
          {/* /.modal-dialog */}
        </div>



      </section>
    </div>

  );
}
