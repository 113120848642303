import { useToast } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { getCoinHistoryAPI, searchUserAPI } from "../service/AuthService";
import { useLocation, useParams } from "react-router-dom";
export default function AccountStatement() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("ALL");
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const [commissionFilter]=useState(["commission", "loss", "profit", "winner"])
  const [userName, setUserName] = useState(
    location.state?.userName
      ? {
          value: location.state?.userName,
          label: location.state?.userName,
        }
      : null
  );
  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const toast = useToast();
  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(fromDate).getTime(),
      endDate: new Date(toDate).getTime(),
    };

    const { response, message, code } = await getCoinHistoryAPI(
      id,
      payload.startDate,
      payload.endDate
    );
    if (code == 200) {
      setData(
        response.coinHistory.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const filterObjectsByKeywords = (array, keywords) => {
    if (!keywords) return array;
    if (keywords == "ACCOUNT")
      return array.filter((obj) => {
        return !commissionFilter?.some(
          (keyword) =>
            obj.description.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    return array.filter((obj) => {
      return keywords?.some((keyword) =>
        obj.description.toLowerCase().includes(keyword.toLowerCase())
      );
    });
  };

  useEffect(() => {
    getAccountStatement();
  }, [page, limit, type, userName, fromDate, toDate, search]);
  return (
    <section id="content" className="content-wrapper">
      <section className="vbox">
        <section className="scrollable padder">
          <ul className="breadcrumb no-border no-radius b-b b-light pull-in">
            <h2>Statements</h2>
          </ul>
          <div
            className="col-sm-12 col-xs-12"
            style={{
              padding: 0,
            }}
          >
            <section className="panel panel-default">
              <div
                _ngcontent-kbe-c45=""
                className="panel-body"
                style={{
                  padding: 5,
                }}
              >
                <div
                  _ngcontent-kbe-c45=""
                  className="col-sm-2 col-xs-6 form-group"
                  style={{
                    padding: 0,
                    marginBottom: 5,
                  }}
                >
                  <input
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    type="date"
                    id="startDate"
                    className="input-sm input-s form-control ng-untouched ng-pristine ng-valid"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div
                  _ngcontent-kbe-c45=""
                  className="col-sm-2 col-xs-6 form-group"
                  style={{
                    paddingLeft: 0,
                    marginBottom: 5,
                  }}
                >
                  <input
                    _ngcontent-kbe-c45=""
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    className="input-sm input-s form-control ng-untouched ng-pristine ng-valid"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div
                  _ngcontent-kbe-c45=""
                  className="col-sm-5 col-xs-12"
                  style={{
                    padding: 0,
                  }}
                >
                  <button
                    _ngcontent-kbe-c45=""
                    className="btn btn-s-md btn-success"
                    onclick="search()"
                  >
                    Search
                  </button>
                  <button
                    _ngcontent-kbe-c45=""
                    onClick={() => {
                      setFilter(null);
                    }}
                    className="btn btn-s-md btn-danger"
                  >
                    Reset
                  </button>
                  <button
                    _ngcontent-kbe-c45=""
                    onClick={() => setFilter(null)}
                    className="btn btn-s-md btn-primary"
                  >
                    All
                  </button>
                  <button
                    _ngcontent-kbe-c45=""
                    onClick={() => setFilter(commissionFilter)}
                    className="btn btn-s-md btn-success"
                  >
                    P&amp;L
                  </button>
                  <button
                    onclick="loadButtons('PDC')"
                    _ngcontent-kbe-c45=""
                    className="btn btn-s-md btn-danger"
                  >
                    PDC
                  </button>
                  <button
                    onClick={() => {
                      setFilter("ACCOUNT");
                    }}
                    _ngcontent-kbe-c45=""
                    onclick="loadButtons('ACC')"
                    className="btn btn-s-md btn-dark"
                  >
                    Account
                  </button>
                </div>
              </div>
            </section>
          </div>
          <section
            className="col-sm-12 col-xs-12 panel panel-default"
            style={{
              padding: 0,
            }}
          >
            <div className="wrapper wrapper-content animated fadeInRight">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ibox float-e-margins">
                    <div
                      className="ibox-content table-responsive"
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                      >
                        <table
                          className="table table-bordered table-striped dataTables-example dataTable no-footer"
                          id="DataTables_Table_0"
                          role="grid"
                          aria-describedby="DataTables_Table_0_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="sorting_disabled tablelightblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                Date
                              </th>
                              <th
                                className="sorting_disabled tabledarkblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                Description
                              </th>
                              <th
                                className="sorting_disabled tablelightblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                Prev. Bal
                              </th>
                              <th
                                className="sorting_disabled tabledarkblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                CR
                              </th>
                              <th
                                className="sorting_disabled tablelightblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                DR
                              </th>
                              <th
                                className="sorting_disabled tabledarkblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                Comm+
                              </th>
                              <th
                                className="sorting_disabled tablelightblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                Comm-
                              </th>
                              <th
                                className="sorting_disabled tabledarkblue"
                                rowSpan={1}
                                colSpan={1}
                              >
                                Balance
                              </th>
                            </tr>
                          </thead>
                          <tbody id="statements">
                            {filterObjectsByKeywords(data, filter).map(
                              (item, index) => (
                                <tr>
                                  <td>
                                    {moment(item?.createdAt + "Z").format(
                                      "DD MM YYYY HH:mm"
                                    )}
                                  </td>
                                  <td>{item?.description}</td>
                                  <td>
                                    {parseFloat(
                                      item?.previousBalance
                                    ).toFixed(2)}
                                  </td>
                                  <td>{item?.credit}</td>
                                  <td>{item?.debit}</td>
                                  <td>0</td>
                                  <td>0</td>
                                  <td>
                                    {parseFloat(item?.updatedBalance).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="pull-right" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
      <a
        href="#"
        className="hide nav-off-screen-block"
        data-toggle="class:nav-off-screen, open"
        data-target="#nav,html"
      />
    </section>
  );
}
