import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  accountLogAPI,
  getBetsFromAccountLogs,
  getCollectionReportAPI,
} from "../service/AuthService";
import moment from "moment";

export default function Ledger({ isCashLedger, isCollectionReport }) {
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const [isLenaDena, setIsLenaDena] = React.useState(
    location.state?.isLenaDena
  );
  const [payload, setPayload] = React.useState({
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    startDate: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD HH:mm:ss"),
    pageSize: 100,
    search: "",

    userName: location.state?.client?.username,
  });
  const [ledgerPayload, setLedgerPayload] = React.useState({
    pageSize: 50,
    pageNumber: 0,
  });
  const navigate = useNavigate();
  const [
    collectionReportMapping,
    setCollectionReportMapping,
  ] = React.useState({
    lenaHai: [],
    denaHai: [],
  });

  const ledgerQuery = useQuery(
    "ledger",
    () => accountLogAPI(ledgerPayload, location.state?.isOld),
    {
      enabled: !isCollectionReport,
    }
  );
  const getCollectionReport = async () => {
    const { response, code } = await getCollectionReportAPI(
      payload.startDate,
      payload.endDate,
      location.state?.isLenaDena ? "" : params?.userType,
      location.state?.isLenaDena ? params?.userType : ""
    );
    if (code === 200) {
      let data = response?.collectionReport;
      let lenaHai = data?.filter((item) => item?.balance > 0);
      let denaHai = data?.filter((item) => item?.balance < 0);

      setCollectionReportMapping({
        lenaHai,
        denaHai,
      });
    }
  };
  useEffect(() => {
    if (isCollectionReport) {
      getCollectionReport();
    }
  }, [params?.userType, isCollectionReport]);

  const isMobile = window.innerWidth < 768;

  return (
    <div
      className="content-wrapper"
      style={{
        minHeight: "2670.44px",
      }}
    >
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">My Ledger</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        {isCollectionReport ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* /.card */}
                <div className="card">
                  <div action="#" method="post" id="demoForm">
                    <div className="card-header" />
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="form-row">
                        <div className="col-md-6">
                          <table
                            id="example1"
                            className="table table-striped  table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th colSpan={5}>
                                  PAYMENT RECEIVING FROM (LENA HE)
                                </th>
                              </tr>
                              <tr>
                                <th>User Name</th>

                                <th>Balance</th>
                                {location?.state?.isLenaDena && (
                                  <th>Action</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {collectionReportMapping?.lenaHai?.map(
                                (item) => (
                                  <tr>
                                    <td
                                      onClick={() =>
                                        item?.userType == "CLIENT" &&
                                        navigate(`/ct/client/`, {
                                          state: {
                                            userId: item.userId,
                                            userName: item.userName,
                                          },
                                        })
                                      }
                                      className="cursor-pointer"
                                    >
                                      {item.userName}
                                    </td>
                                    <td>
                                      {parseFloat(item.balance).toFixed(2)}
                                    </td>
                                    {item?.userType != "CLIENT" &&
                                      location?.state?.isLenaDena && (
                                        <td>
                                          <button
                                            style={{ marginRight: "10px" }}
                                            className="btn btn-secondary "
                                            onClick={() =>
                                              navigate(
                                                `/ct/${item.userType?.toLocaleLowerCase()}`,
                                                {
                                                  state: {
                                                    userId: item.userId,
                                                    userName: item.userName,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            Settlements
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              navigate(
                                                `/ledger/${
                                                  item.userName.split(" ")[0]
                                                }`,
                                                {
                                                  state: {
                                                    isCollectionReport: true,
                                                    isLenaDena: true,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            Downline
                                          </button>
                                        </td>
                                      )}
                                    {item?.userType == "CLIENT" && (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          navigate(`/ct/client/`, {
                                            state: {
                                              userId: item.userId,
                                              userName: item.userName,
                                            },
                                          })
                                        }
                                      >
                                        Settlements
                                      </button>
                                    )}
                                  </tr>
                                )
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>Total</td>

                                <td>
                                  {parseFloat(
                                    collectionReportMapping.lenaHai.reduce(
                                      (acc, item) => acc + item.balance,
                                      0
                                    )
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="col-md-6">
                          <table
                            id="example12"
                            className="table table-striped  table-hover table-bordered"
                          >
                            <thead>
                              <tr>
                                <th colSpan={5}>PAYMENT PAID TO (DENA HE)</th>
                              </tr>
                              <tr>
                                <th>User Name</th>
                                <th>Balance</th>
                                {location?.state?.isLenaDena && (
                                  <th>Action</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {collectionReportMapping?.denaHai?.map(
                                (item) => (
                                  <tr>
                                    <td
                                      onClick={() =>
                                        item?.userType == "CLIENT" &&
                                        navigate(`/ct/client/`, {
                                          state: {
                                            userId: item.userId,
                                            userName: item.userName,
                                          },
                                        })
                                      }
                                    >
                                      {item.userName}
                                    </td>
                                    <td>
                                      {parseFloat(item.balance).toFixed(2)}
                                    </td>
                                    {item?.userType != "CLIENT" &&
                                      location?.state?.isLenaDena && (
                                        <td>
                                          <button
                                            style={{ marginRight: "10px" }}
                                            className="btn btn-secondary "
                                            onClick={() =>
                                              navigate(
                                                `/ct/${item.userType?.toLocaleLowerCase()}`,
                                                {
                                                  state: {
                                                    userId: item.userId,
                                                    userName: item.userName,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            Settlements
                                          </button>
                                          <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              navigate(
                                                `/ledger/${
                                                  item.userName.split(" ")[0]
                                                }`,
                                                {
                                                  state: {
                                                    isCollectionReport: true,
                                                    isLenaDena: true,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            Downline
                                          </button>
                                        </td>
                                      )}
                                    {item?.userType == "CLIENT" && (
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          navigate(`/ct/client/`, {
                                            state: {
                                              userId: item.userId,
                                              userName: item.userName,
                                            },
                                          })
                                        }
                                      >
                                        Settlements
                                      </button>
                                    )}
                                  </tr>
                                )
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Total</th>
                                <th>
                                  {parseFloat(
                                    collectionReportMapping.denaHai.reduce(
                                      (acc, item) => acc + item.balance,
                                      0
                                    )
                                  ).toFixed(2)}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <form action="#" method="post" id="demoForm">
                    <div className="card-header">
                      <h2 className="card-title">My Ledger</h2>
                    </div>
                    <div className="card-body">
                      <table
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Collection Name</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                            <th>Type</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th
                              colSpan={2}
                              className="text-indigo text-lg text-center"
                            >
                              Total Amount
                            </th>
                            <th />
                            <th className="text-gray-dark text-lg">
                              {parseFloat(
                                ledgerQuery?.data?.response?.reduce(
                                  (acc, item) => acc + item.debitInfo,
                                  0
                                )
                              ).toFixed(2)}
                            </th>
                            <th className="text-gray-dark text-lg">
                              {parseFloat(
                                ledgerQuery?.data?.response?.reduce(
                                  (acc, item) => acc + item.creditInfo,
                                  0
                                )
                              ).toFixed(2)}
                            </th>
                            <th className=" text-red text-lg">
                              {parseFloat(
                                ledgerQuery?.data?.response[0].updatedBalance
                              ).toFixed(2)}
                            </th>

                            <th />
                            <th />
                          </tr>
                          {ledgerQuery?.data?.response
                            ?.sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            ?.map((item, index) => (
                              <tr>
                                <td>{index}</td>
                                <td id="getdate_477155">
                                  {moment(item.createdAt).format("DD MMM YY")}
                                </td>
                                <td>CA1 CASH</td>
                                <td
                                  style={{
                                    color:
                                      item?.debitInfo < 0 ? "red" : "green",
                                  }}
                                >
                                  {" "}
                                  {item?.debitInfo &&
                                    parseFloat(item?.debitInfo).toFixed(
                                      2
                                    )}{" "}
                                </td>
                                <td
                                  style={{
                                    color:
                                      item?.creditInfo < 0 ? "red" : "green",
                                  }}
                                >
                                  {item?.creditInfo &&
                                    parseFloat(item?.creditInfo).toFixed(
                                      2
                                    )}{" "}
                                </td>
                                <td>
                                  {item?.updatedBalance &&
                                    parseFloat(item?.updatedBalance).toFixed(
                                      2
                                    )}
                                </td>
                                <td>
                                  {item?.type == "BALANCE_REPORT"
                                    ? item?.creditInfo
                                      ? "Credit"
                                      : "Debit"
                                    : null}
                                </td>
                                <td>{item?.comment}</td>
                              </tr>
                            ))}
                        </tbody>
                        {/*  <tfoot>
                          <tr>
                            <th colSpan={7}>
                              <div className="pagination">
                                <a href="#">&laquo;</a>
                                <a className="text-blue active">
                                  {ledgerPayload?.pageNumber + 1}
                                </a>
                                <a href="#">
                                  {ledgerPayload?.pageNumber + 2}
                                </a>
                                <a href="#">&raquo;</a>
                              </div>
                            </th>
                          </tr>
                        </tfoot> */}
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
