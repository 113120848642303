import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClientWithdrawAndDepositAPI } from "../service/UserService";
import toast from "react-hot-toast";

export default function ReceiveCash() {
  const { id } = useParams();
  const location = useLocation();
  const [payload, setPayload] = React.useState({
    id: id,
    balance: 0,
    remarks: "",
    transactionType: "DEPOSIT",
  });
  const navigate = useNavigate();

  const submitData = async () => {
    const { response, code } = await ClientWithdrawAndDepositAPI(id, payload);

    if (code == 200) {
      toast.success("Cash Received Successfully");
      navigate(`/agent/users/${id}`, {
        state: { client: location.state?.client },
      });
    }
  };
  return (
    <>
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-10">
          <h2>RECEIVING CASH</h2>
          <ol className="breadcrumb">
            <li>
              <a href="/agent/dashboard">Dashboard</a>
            </li>
            <li>
              <a
                href={`/agent/users?users=${
                  location?.state?.client?.userType
                }`}
              >
                {location.state?.client?.userType}
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  navigate(`/agent/users/${id}`, {
                    state: { client: location.state?.client },
                  })
                }
              >
                {location.state?.client?.username}
              </a>
            </li>

            <li className="active">
              <strong>Recieve Cash</strong>
            </li>
          </ol>
        </div>
        <div className="col-lg-2" />
      </div>
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="col-lg-12">
          <div className="ibox float-e-margins">
            <div className="ibox-title">
              <h5>Receive Cash From User</h5>
            </div>
            <div className="ibox-content table-responsive">
              <div className="form-horizontal" id="payment-form">
                <div style={{ display: "none" }}>
                  <input name="utf8" type="hidden" defaultValue="✓" />
                </div>
                <div className="form-group">
                  <label className="col-lg-2 control-label">User Name:</label>
                  <div className="col-lg-9">
                    <p className="form-control-static">
                      {location.state?.client?.username}
                    </p>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-lg-2 control-label">
                    Ledger Balance:
                  </label>
                  <div className="col-lg-9">
                    <p className="form-control-static available-coin" id="">
                      {location.state?.client?.balance}
                    </p>
                  </div>
                </div>
                {/* <div class="hr-line-dashed"></div> */}
                <div className="form-group">
                  <label className="col-sm-2 control-label">
                    Updates Coins
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      id=""
                      name="coins"
                      onChange={(e) => {
                        setPayload({ ...payload, balance: e.target.value });
                      }}
                      className="form-control added-coin"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-2 control-label">Note</label>
                  <div className="col-sm-9">
                    <textarea
                      id=""
                      name="note"
                      className="form-control added-note"
                      defaultValue={""}
                      onChange={(e) => {
                        setPayload({ ...payload, remarks: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {/* <div class="hr-line-dashed"></div> */}
                <div className="form-group">
                  <div className="col-sm-9 col-sm-offset-3">
                    {/* <button class="btn btn-white" type="submit">Cancel</button> */}
                    <button
                      onClick={submitData}
                      className="btn btn-primary"
                      type="submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
